import { createSelector } from 'reselect'

import { RootState } from '../types'
import { getBannersMap } from './get-banners-map'

export const getBanners = createSelector(
  (state: RootState) => getBannersMap(state),

  bannersMap => {
    return Object.keys(bannersMap)
      .map(id => bannersMap[id])
      .sort((a, b) => a.position - b.position)
  },
)
