import { validateDiscount } from '@keffs/data/src/validators'
import { put, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'
import {
  getAppliedDiscount,
  getClock,
  getCustomer,
  getServiceType,
} from '../../selectors'

/**
 * Unsets applied discount if it becomes expired, finished, etc.
 */
export function* clearInvalidDiscount() {
  // First of all, wait until auth is initialized because we will need
  // to check signed-in user to see whether or not a discount is valid
  yield take(getType(actions.authInitialized))

  // No user signed-in? Clear discount
  const initialCustomer: ReturnType<typeof getCustomer> = yield select(
    getCustomer,
  )
  if (initialCustomer == null) {
    yield put(actions.clearDiscount())
  }

  while (true) {
    // Wait for actions that may invalidate the discount
    yield take([
      getType(actions.tick),
      getType(actions.signOutSuccess),
      getType(actions.setServiceType),
    ])

    // No discount applied? Abort
    const appliedDiscount: ReturnType<typeof getAppliedDiscount> = yield select(getAppliedDiscount) // prettier-ignore
    if (appliedDiscount == null) {
      continue
    }

    // Not signed-in? Clear and abort
    const customer: ReturnType<typeof getCustomer> = yield select(getCustomer)
    if (customer == null) {
      yield put(actions.clearDiscount())
      continue
    }

    // Did discount become invalid?
    const clock: ReturnType<typeof getClock> = yield select(getClock)
    const errorMessage = validateDiscount({ clock, customer, discount: appliedDiscount }) // prettier-ignore
    if (errorMessage) {
      yield put(actions.clearDiscount())
      // Show snackbar explaining why
      yield put(actions.showSnackbar(errorMessage))
    }

    // Selected a service type that's not allowed by the applied discount?
    const serviceType: ReturnType<typeof getServiceType> = yield select(getServiceType) // prettier-ignore
    if (serviceType && !appliedDiscount.serviceTypes.includes(serviceType)) {
      yield put(actions.clearDiscount())
      yield put(actions.showSnackbar('Desconto descartado'))
    }
  }
}
