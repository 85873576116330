import { getType } from 'typesafe-actions'

import { permissionChanged } from '../actions'
import { RootAction } from '../types'

export type State = {
  readonly notifications: PushPermissionState
}

export const initialState: State = {
  notifications: 'denied',
}

export const permissionsReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(permissionChanged): {
      return {
        ...state,
        [action.payload.name]: action.payload.state,
      }
    }

    default: {
      return state
    }
  }
}
