import { subHours } from 'date-fns'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getClock } from '../clock'
import { getAllowAsap } from './get-allow-asap'
import { getAvailableDeliveryTimes } from './get-available-delivery-times'

export const getTakingOrders = createSelector(
  (state: RootState) => getClock(state),
  (state: RootState) => getAvailableDeliveryTimes(state),
  (state: RootState) => getAllowAsap(state),

  (clock, availableDeliveryTimes, allowAsap) => {
    if (allowAsap) {
      return true
    }

    if (availableDeliveryTimes.length > 0) {
      const firstDeliveryTime = availableDeliveryTimes[0]
      const aFewHoursBeforeFirstDeliveryTime = subHours(firstDeliveryTime, 3)
      return clock > aFewHoursBeforeFirstDeliveryTime
    }

    return false
  },
)
