import {
  blueThemeFactory,
  whatsappThemeFactory,
} from '@keffs/core/src/styles/mui-theme'
import { formatPhoneNumber } from '@keffs/formatters'
import { isMobileDevice } from '@keffs/platform'
import { Button, MuiThemeProvider, Typography } from '@material-ui/core'
import { Call as MdCall } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { IoLogoWhatsapp } from 'react-icons/io'
import { useSelector } from 'react-redux'

import { getRestaurantPhoneNumber } from '../redux/selectors'

const useStyles = makeStyles({
  leftIcon: {
    marginRight: 8,
  },
  contactUs: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 8,
    },
    fontSize: 16,
  },
})

export type ContactUsProps = {
  message?: string
}

export const ContactUs = memo(({ message }: ContactUsProps) => {
  const classes = useStyles()

  const phoneNumber = useSelector(getRestaurantPhoneNumber)!

  const openCall = () => {
    // Convert from international to local
    const localPhoneNumber = `0${phoneNumber.slice(3)}`
    window.open(`tel:${localPhoneNumber}`)
  }

  const openWhatsApp = () => {
    // Remove leading "+"
    const phoneNumberWithoutPlusSign = phoneNumber.slice(1)
    window.open(
      `https://wa.me/${phoneNumberWithoutPlusSign}${
        message ? `?text=${encodeURI(message)}` : ''
      }`,
    )
  }

  // Are we running in a mobile device?
  if (isMobileDevice()) {
    return (
      <>
        <MuiThemeProvider theme={blueThemeFactory}>
          <Button color="primary" onClick={openCall}>
            <MdCall className={classes.leftIcon} />
            Ligar
          </Button>
        </MuiThemeProvider>
        <MuiThemeProvider theme={whatsappThemeFactory}>
          <Button color="primary" onClick={openWhatsApp}>
            <IoLogoWhatsapp className={classes.leftIcon} size={24} />
            WhatsApp
          </Button>
        </MuiThemeProvider>
      </>
    )
  }

  return (
    <Typography className={classes.contactUs} variant="body1">
      <IoLogoWhatsapp color="#25d366" size={24} />{' '}
      {formatPhoneNumber(phoneNumber)}
    </Typography>
  )
})
