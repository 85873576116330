import { validateDistrict } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getDistrict } from './get-district'
import { getSelectedLocation } from './get-selected-location'
import { getServiceType } from './get-service-type'

export const getDistrictError = createSelector(
  (state: RootState) => getDistrict(state),
  (state: RootState) => getServiceType(state),
  (state: RootState) => getSelectedLocation(state),

  (district, serviceType, selectedLocation) => {
    if (serviceType === 'delivery' && selectedLocation === 'new') {
      return validateDistrict(district)
    }

    return undefined
  },
)
