export const ROUTE_HOME = 'HOME'
export const ROUTE_AUTH = 'AUTH'
export const ROUTE_PRODUCT = 'PRODUCT'
export const ROUTE_COMBO = 'COMBO'
export const ROUTE_CART = 'CART'
export const ROUTE_CART_ITEM = 'CART_ITEM'
export const ROUTE_CART_PAYMENT_METHOD = 'CART_PAYMENT_METHOD'
export const ROUTE_CART_CHANGE_FOR = 'CART_CHANGE_FOR'
export const ROUTE_CART_AUTH = 'CART_AUTH'
export const ROUTE_CART_DISCARD_DISCOUNT = 'CART_DISCARD_DISCOUNT'
export const ROUTE_ORDER_DETAILS = 'ORDER_DETAILS'
export const ROUTE_APPLY_DISCOUNT = 'APPLY_DISCOUNT'
export const ROUTE_PRIVACY_POLICY = 'PRIVACY_POLICY'
export const ROUTE_NOT_FOUND = 'NOT_FOUND'

export const routesMap = {
  [ROUTE_HOME]: '/',
  [ROUTE_AUTH]: '/autenticacao',
  [ROUTE_PRODUCT]: '/cardapio/:slug',
  [ROUTE_COMBO]: '/combo/:slug',
  [ROUTE_CART]: '/carrinho',
  [ROUTE_CART_ITEM]: '/carrinho/item/:id',
  [ROUTE_CART_PAYMENT_METHOD]: '/carrinho/pagamento',
  [ROUTE_CART_CHANGE_FOR]: '/carrinho/troco',
  [ROUTE_CART_AUTH]: '/carrinho/autenticacao',
  [ROUTE_CART_DISCARD_DISCOUNT]: '/carrinho/descartar-desconto',
  [ROUTE_ORDER_DETAILS]: '/pedidos/:id',
  [ROUTE_APPLY_DISCOUNT]: '/promo/:idOrCode',
  [ROUTE_APPLY_DISCOUNT]: '/promo/:idOrCode',
  [ROUTE_PRIVACY_POLICY]: '/politica-de-privacidade',
}
