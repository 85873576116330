import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
  },
})

export type ErrorScreenProps = {
  children?: React.ReactNode
}

export const ErrorScreen = ({
  children,
}: ErrorScreenProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Oops...
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </div>
  )
}
