import { call, delay, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import { getAllErrors } from '../../selectors'

export function* checkoutReady(analyticsService: AnalyticsService) {
  while (true) {
    // Wait for any action that represents user interaction with the cart
    yield take([
      getType(actions.setServiceType),
      getType(actions.setName),
      getType(actions.setPhoneNumber),
      getType(actions.setCpf),
      getType(actions.addItem),
      getType(actions.updateItem),
      getType(actions.removeItem),
      getType(actions.setDeliveryTime),
      getType(actions.setSelectedLocation),
      getType(actions.setAddress),
      getType(actions.setDistrict),
      getType(actions.setReference),
      getType(actions.setVehicleModel),
      getType(actions.setVehicleColor),
      getType(actions.setVehiclePlate),
      getType(actions.setPaymentMethod),
    ])

    // Cart not completely valid yet? Go back to wait
    const allErrors: ReturnType<typeof getAllErrors> = yield select(
      getAllErrors,
    )
    if (allErrors.length > 0) {
      continue
    }

    // Sometimes checkout is both initiated and ready at the same time
    // (e.g. when the user chooses eat-in). So delay just a bit here to
    // ensure "Checkout Ready" is tracked after "Checkout Initiated".
    yield delay(100)

    // Track
    yield call([analyticsService, analyticsService.checkoutReady])

    // Just wanted to track once, so finish this saga
    return
  }
}
