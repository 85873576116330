// @ts-ignore: Supress missing declaration error
import { deflate } from 'pako'

import { Event, Primitive } from './types'

export const before = <TFn extends (...args: any[]) => any>(
  fn: TFn,
  hook: (...args: Parameters<TFn>) => ReturnType<TFn>,
) => {
  return (...args: Parameters<TFn>): ReturnType<TFn> => {
    hook(...args)
    return fn(...args)
  }
}

export const compressEvents = (events: Event[]): string => {
  return deflate(JSON.stringify(events), { to: 'string' })
}

export const generateId = (): string => {
  const validChars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890'
  const randBytes = crypto.getRandomValues(new Uint8Array(20))
  return Array.from(randBytes)
    .map(b => validChars[b % 63])
    .join('')
}

export const encodeValue = (value: Primitive | Primitive[]): any => {
  if (value === undefined) {
    return undefined
  }

  const objectClass = Object.prototype.toString.call(value)
  let valueType =
    objectClass.substring(8, objectClass.length - 1).toLowerCase() + 'Value'

  switch (valueType) {
    case 'arrayValue':
      // @ts-ignore: object type is not allowed for 'value' in the function
      // signature, so TypeScript won't let us assign an object to it, but we
      // know what we're doing.
      value = { values: value.map(encodeValue) }
      break

    case 'numberValue':
      valueType = Number.isInteger(value) ? 'integerValue' : 'doubleValue'
      value = valueType === 'integerValue' ? String(value) : value
      break
  }

  return { [valueType]: value }
}
