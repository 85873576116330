import { Restaurant } from '@keffs/data/src/types'

import { ApiService } from './api-service'

export class RestaurantService extends ApiService {
  /**
   * For now, we only have one restaurant, so this is a useful helper.
   */
  async fetchTheRestaurant(): Promise<Restaurant> {
    const restaurants = await this.request('GET', '/restaurants')
    return restaurants[0]
  }

  async fetchById(id: string): Promise<Restaurant | null> {
    return this.request('GET', `/restaurants/${id}`)
  }

  async fetchAll(): Promise<Restaurant[]> {
    return this.request('GET', '/restaurants')
  }

  async create(data: {
    address: Restaurant['address']
    district: Restaurant['district']
    city: Restaurant['city']
    coordinates: Restaurant['coordinates']
    phoneNumber: Restaurant['phoneNumber']
    openingHours: Restaurant['openingHours']
    timezoneOffset: Restaurant['timezoneOffset']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/restaurants', data)
    return insertedId
  }

  async updateLocation(
    id: string,
    data: {
      address?: Restaurant['address']
      district?: Restaurant['district']
      city?: Restaurant['city']
      coordinates?: Restaurant['coordinates']
      phoneNumber?: Restaurant['phoneNumber']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/restaurants/${id}/location`, data)
  }

  async updateOpeningHours(
    id: string,
    data: {
      openingHours?: Restaurant['openingHours']
      timezoneOffset?: Restaurant['timezoneOffset']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/restaurants/${id}/opening-hours`, data)
  }

  async updateServiceTypes(
    id: string,
    data: {
      serviceTypes: Restaurant['serviceTypes']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/restaurants/${id}/service-types`, data)
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/restaurants/${id}`)
  }
}
