import { useEffect, useState } from 'react'

export const useClock = (): Date => {
  const [clock, setClock] = useState(new Date())

  useEffect(() => {
    const now = new Date()
    const nextSecond = getNextSecond(new Date())
    const msUntilNextSecond = nextSecond.getTime() - now.getTime()

    let intervalId: ReturnType<typeof setTimeout>

    // First, wait until rounded to next second
    const timeoutId = setTimeout(() => {
      setClock(new Date())

      // Then tick every second
      intervalId = setInterval(() => {
        setClock(new Date())
      }, 1000)
    }, msUntilNextSecond)

    return () => {
      clearTimeout(timeoutId)
      clearInterval(intervalId)
    }
  }, [])

  return clock
}

// =============================================================================
// Utils
// =============================================================================

const getNextSecond = (date: Date): Date => {
  const newDate = new Date(date)
  newDate.setSeconds(date.getSeconds() + 1, 0)
  return newDate
}
