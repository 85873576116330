import {
  Collapse,
  DialogActions,
  DialogContent,
  Grow,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles({
  collapse: {
    overflow: 'visible',
  },
  paper: {
    overflow: 'hidden',
  },
})

export type NonModalDialogProps = {
  actions: React.ReactNode
  className?: string
  show: boolean
  text: React.ReactNode
}

export const NonModalDialog = ({
  actions,
  className,
  show,
  text,
}: NonModalDialogProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <Collapse className={classes.collapse} in={show}>
      <Grow in={show} timeout={1000}>
        <Paper className={classNames(className, classes.paper)} elevation={8}>
          <DialogContent>
            <Typography>{text}</Typography>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Paper>
      </Grow>
    </Collapse>
  )
}
