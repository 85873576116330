import { FacebookButton } from '@keffs/core/src/components/facebook-button'
import { GoogleButton } from '@keffs/core/src/components/google-button'
import { computeDiscountTitle } from '@keffs/data/src/computed'
import { Discount } from '@keffs/data/src/types'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ServicesContext } from '../contexts'
import * as actions from '../redux/actions'
import { ROUTE_HOME } from '../redux/routes'
import { getLoadingAuth, getSignedIn } from '../redux/selectors'

const useStyles = makeStyles({
  content: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  contextText: {
    maxWidth: 250,
    textAlign: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    paddingTop: 12,
    paddingBottom: 12,
  },
  discardButton: {
    width: '100%',
  },
})

export type DiscountGuardProps = {
  open: boolean
  idOrCode: string | undefined
}

export const DiscountGuard = memo(({ open, idOrCode }: DiscountGuardProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const signedIn = useSelector(getSignedIn)
  const loadingAuth = useSelector(getLoadingAuth)

  const { discountService } = useContext(ServicesContext)
  const [discount, setDiscount] = useState<Discount | null>(null)

  useEffect(() => {
    if (idOrCode != null) {
      discountService
        .fetchById(idOrCode)
        .then(discount => {
          setDiscount(discount)
          if (discount == null) {
            dispatch(actions.replace({ name: ROUTE_HOME }))
          }
        })
        .catch(() => {
          dispatch(actions.replace({ name: ROUTE_HOME }))
        })
    }
  }, [discountService, dispatch, idOrCode])

  if (discount == null || signedIn) {
    return null
  }

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleGoogleClick = () => {
    dispatch(actions.signInAttempt({ provider: 'google' }))
  }

  const handleFacebookClick = () => {
    dispatch(actions.signInAttempt({ provider: 'facebook' }))
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      {loadingAuth ? (
        <DialogContent className={classes.spinner}>
          <CircularProgress size={40} />
        </DialogContent>
      ) : (
        <>
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.contextText}>
              Você precisa se identificar para tentar aplicar o desconto{' '}
              <strong>{computeDiscountTitle(discount)}</strong>.
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <GoogleButton
              className={classes.button}
              onClick={handleGoogleClick}
            >
              Identificar com Google
            </GoogleButton>
            <FacebookButton
              className={classes.button}
              onClick={handleFacebookClick}
            >
              Identificar com Facebook
            </FacebookButton>
            <Button
              className={classes.button}
              size="small"
              onClick={handleClose}
            >
              Descartar desconto
            </Button>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
})
