import { call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import {
  getCombosMap,
  getItems,
  getProductCategoriesMap,
  getProductsMap,
} from '../../selectors'
import { RootState } from '../../types'

export function* cartUpdatedItem(analyticsService: AnalyticsService) {
  // Save state whenever another action that may change the items happens
  let prevState: RootState = yield select()
  yield takeLatest(
    [getType(actions.addItem), getType(actions.removeItem)],
    function* () {
      prevState = yield select()
    },
  )

  // On item in cart updated...
  yield takeEvery(getType(actions.updateItem), function* (
    action: ActionType<typeof actions.updateItem>,
  ) {
    const { id } = action.payload
    const items: ReturnType<typeof getItems> = yield select(getItems)
    const prevItems = getItems(prevState)
    const categoriesMap: ReturnType<typeof getProductCategoriesMap> = yield select(
      getProductCategoriesMap,
    )
    const productsMap: ReturnType<typeof getProductsMap> = yield select(
      getProductsMap,
    )
    const combosMap: ReturnType<typeof getCombosMap> = yield select(
      getCombosMap,
    )

    const item = items.find(i => i.id === id)!
    const prevItem = prevItems.find(i => i.id === id)!
    const changeInQuantity = item.quantity - prevItem.quantity

    // Gather all cart items to be used below
    const cart = items.map(item => {
      if ('productId' in item) {
        // Product item
        const product = productsMap[item.productId]
        const category = categoriesMap[product.categoryId]
        return {
          quantity: item.quantity,
          productId: product.id,
          productCode: `P${product.code}`,
          productName: product.name,
          productPrice: product.unitPrice,
          categoryName: category.name,
        }
      } else {
        // Combo item
        const combo = combosMap[item.comboId]
        return {
          quantity: item.quantity,
          productId: combo.id,
          productCode: `C${combo.code}`,
          productName: combo.name,
          productPrice: combo.unitPrice,
          categoryName: 'Combo',
        }
      }
    })

    // Track
    if (changeInQuantity > 0) {
      if ('productId' in item) {
        // Product item
        const product = productsMap[item.productId]
        const category = categoriesMap[product.categoryId]
        yield call([analyticsService, analyticsService.addedToCart], {
          changeInQuantity: Math.abs(changeInQuantity),
          resultingQuantity: item.quantity,
          productId: product.id,
          productCode: `P${product.code}`,
          productName: product.name,
          productPrice: product.unitPrice,
          categoryName: category.name,
          cart,
        })
      } else {
        // Combo item
        const combo = combosMap[item.comboId]
        yield call([analyticsService, analyticsService.addedToCart], {
          changeInQuantity: Math.abs(changeInQuantity),
          resultingQuantity: item.quantity,
          productId: combo.id,
          productCode: `C${combo.code}`,
          productName: combo.name,
          productPrice: combo.unitPrice,
          categoryName: 'Combo',
          cart,
        })
      }
    } else if (changeInQuantity < 0) {
      if ('productId' in item) {
        // Product item
        const product = productsMap[item.productId]
        const category = categoriesMap[product.categoryId]
        yield call([analyticsService, analyticsService.removedFromCart], {
          changeInQuantity: Math.abs(changeInQuantity),
          productId: product.id,
          productCode: `P${product.code}`,
          productName: product.name,
          productPrice: product.unitPrice,
          categoryName: category.name,
        })
      } else {
        // Combo item
        const combo = combosMap[item.comboId]
        yield call([analyticsService, analyticsService.removedFromCart], {
          changeInQuantity: Math.abs(changeInQuantity),
          productId: combo.id,
          productCode: `C${combo.code}`,
          productName: combo.name,
          productPrice: combo.unitPrice,
          categoryName: 'Combo',
        })
      }
    }

    // Save state
    prevState = yield select()
  })
}
