import { Event } from './types'

let buffer: Event[] = []

export const pushEvent = (event: Event): void => {
  buffer.push(event)
}

export const flushEvents = (): Event[] => {
  const eventsToFlush = buffer
  buffer = []
  return eventsToFlush
}
