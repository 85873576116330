import { put, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'
import { getDeliveryTime, getDeliveryTimeError } from '../../selectors'

/**
 * Unsets delivery time if it becomes invalid.
 */
export function* clearInvalidDeliveryTime() {
  // Take any tick as it may cause deliveryTime to become invalid
  while (true) {
    yield take(getType(actions.tick))

    // Empty deliveryTime field? Abort
    const deliveryTime: ReturnType<typeof getDeliveryTime> = yield select(
      getDeliveryTime,
    )
    if (deliveryTime == null) {
      continue
    }

    // Did deliveryTime become invalid?
    const errorMessage: ReturnType<typeof getDeliveryTimeError> = yield select(
      getDeliveryTimeError,
    )
    if (deliveryTime && errorMessage) {
      yield put(actions.clearDeliveryTime())
    }
  }
}
