import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { Logo } from '../logo'

const useStyles = makeStyles({
  root: {
    marginTop: 64,
    textAlign: 'center',
    '& > *': {
      marginBottom: 8,
    },
  },
})

export const IncompatibleBrowser = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Logo />
      <Typography>Navegador incompatível</Typography>
      <Typography>
        Para a melhor experiência, recomendamos a última versão do Google
        Chrome:
      </Typography>
      <Typography>
        <a href="https://www.google.com/chrome/">
          https://www.google.com/chrome/
        </a>
      </Typography>
    </div>
  )
}
