import { roundTime } from '@keffs/data/src/utils'
import { formatCountdown } from '@keffs/formatters'
import { useClock } from '@keffs/hooks'
import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import { getAppliedDiscount } from '../redux/selectors'
import './discount-countdown.css'

const useStyles = makeStyles((theme: Theme) => {
  const now = new Date()
  const animationDelay = roundTime(now, 1000).getTime() - now.getTime()

  return {
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      boxShadow: theme.shadows[8],
      background: '#ff9800',
      padding: 8,
      color: 'white',
    },
    blink: {
      animation: `blink 1s ease-in ${animationDelay}ms infinite`,
    },
    '@keyframes blink': {
      from: { opacity: 1 },
      to: { opacity: 0.1 },
    },
    strong: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  }
})

export const DiscountCountdown = memo(() => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const discount = useSelector(getAppliedDiscount)

  const clock = useClock()

  const remainingSeconds = discount
    ? Math.floor((discount.end.getTime() - clock.getTime()) / 1000)
    : Infinity

  useEffect(() => {
    // Discount expired?
    if (remainingSeconds <= 0) {
      dispatch(actions.tick())
    }
  }, [clock, dispatch, remainingSeconds])

  if (discount == null) {
    document.body.classList.remove('DiscountCountdown--visible')
    return null
  }

  const durationEndsSoon = remainingSeconds < 24 * 60 * 60

  const remainingUses = discount.maxUsesTotal - discount.totalUses
  const fewUsesAvailable = remainingUses <= 20

  const showCountdown = durationEndsSoon || fewUsesAvailable

  // Adds/removes <body> padding-top
  document.body.classList.toggle('DiscountCountdown--visible', showCountdown)

  if (fewUsesAvailable) {
    return (
      <div className={classes.root}>
        <Typography align="center" color="inherit">
          Desconto disponível só para mais{' '}
          <span className={classes.blink}>{remainingUses}</span>{' '}
          {remainingUses === 1 ? 'pessoa' : 'pessoas'}.
        </Typography>
        <Typography align="center" color="inherit">
          Corra antes que acabe!
        </Typography>
      </div>
    )
  }

  if (durationEndsSoon) {
    return (
      <div className={classes.root}>
        <Typography align="center" color="inherit">
          O desconto acaba em{' '}
          <span className={classes.strong}>
            {formatCountdown(remainingSeconds)}
          </span>
        </Typography>
        <Typography align="center" color="inherit">
          Aproveita enquanto dá!
        </Typography>
      </div>
    )
  }

  return null
})
