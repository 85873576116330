import { env } from '@keffs/env'

const hostBackend = env('HOST_BACKEND', 'http://localhost')
const portBackend = env('PORT_BACKEND', null)

export const config = {
  debug: env('DEBUG', null) === 'true',
  version: env('VERSION', 'unknown'),

  api: {
    baseUrl: [hostBackend, portBackend].filter(Boolean).join(':'),
  },

  facebook: {
    pixelId: env('FACEBOOK_PIXEL_ID', null),
  },

  firebase: {
    apiKey: env('FIREBASE_API_KEY'),
    appId: env('FIREBASE_APP_ID_WEBSITE'),
    authDomain: env('FIREBASE_AUTH_DOMAIN'),
    databaseUrl: env('FIREBASE_DATABASE_URL'),
    messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID'),
    projectId: env('FIREBASE_PROJECT_ID'),
    storageBucket: env('FIREBASE_STORAGE_BUCKET'),
    vapidKey: env('FIREBASE_VAPID_KEY'),
  },

  google: {
    analyticsId: env('GOOGLE_ANALYTICS_ID', null),
  },

  pusher: {
    cluster: env('PUSHER_CLUSTER'),
    key: env('PUSHER_KEY'),
  },

  sentry: {
    dsn: env('SENTRY_DSN_WEBSITE', null),
    env: env('SENTRY_ENV', null),
  },
}
