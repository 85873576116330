import { addDays, format, isSameDay } from 'date-fns'

import { AtTheDayOfWeekLiteral } from '../literals'

/**
 * Computes a human readable text indicating when we will open again.
 *
 * @param now The current time.
 * @param nextOpening The Date of the next opening.
 *
 * @returns The human readable text.
 */
export const computeNextOpeningText = (
  now: Date,
  nextOpening: Date | null,
): string => {
  if (nextOpening == null) {
    return 'Estamos fechados'
  }

  const formattedTime = format(nextOpening, 'H:mm')

  // Is next opening today?
  if (isSameDay(nextOpening, now)) {
    return `Abriremos novamente hoje às ${formattedTime}`
  }

  // Is next opening tomorrow?
  if (isSameDay(nextOpening, addDays(now, 1))) {
    return `Abriremos novamente amanhã às ${formattedTime}`
  }

  return `Abriremos novamente ${
    AtTheDayOfWeekLiteral[nextOpening.getDay()]
  } às ${formattedTime}`
}
