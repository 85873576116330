import { Snackbar } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../../redux/actions'
import { getCurrent, getOpen } from '../../redux/selectors'
import { CustomSnackbarContent } from './custom-snackbar-content'

export const AppSnackbar = (): React.ReactElement => {
  const dispatch = useDispatch()
  const open = useSelector(getOpen)
  const current = useSelector(getCurrent)

  const handleClose = () => {
    dispatch(actions.closeSnackbar())
  }

  const handleExited = () => {
    dispatch(actions.processSnackbarQueue())
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={5000}
      onClose={handleClose}
      onExited={handleExited}
    >
      <CustomSnackbarContent
        avatar={current.avatar}
        primary={current.primary}
        secondary={current.secondary}
        variant={current.variant}
      />
    </Snackbar>
  )
}
