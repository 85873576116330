import { FacebookButton } from '@keffs/core/src/components/facebook-button'
import { GoogleButton } from '@keffs/core/src/components/google-button'
import { Logo } from '@keffs/core/src/components/logo'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ActiveOrders } from '../components/active-orders'
import { AppliedDiscount } from '../components/applied-discount'
import { BannerGrid } from '../components/banner-grid'
import { Cart } from '../components/cart'
import { ComboDialog } from '../components/combo-dialog'
import { DiscountCountdown } from '../components/discount-countdown'
import { DiscountGuard } from '../components/discount-guard'
import { OpeningHoursAlert } from '../components/opening-hours-alert'
import { ProductDialog } from '../components/product-dialog'
import { ProductGrid } from '../components/product-grid'
import * as actions from '../redux/actions'
import { history } from '../redux/history'
import {
  ROUTE_APPLY_DISCOUNT,
  ROUTE_AUTH,
  ROUTE_CART,
  ROUTE_CART_AUTH,
  ROUTE_CART_CHANGE_FOR,
  ROUTE_CART_DISCARD_DISCOUNT,
  ROUTE_CART_ITEM,
  ROUTE_CART_PAYMENT_METHOD,
  ROUTE_COMBO,
  ROUTE_HOME,
  ROUTE_PRODUCT,
} from '../redux/routes'
import {
  getAppliedDiscount,
  getCustomerDisplayName,
  getLoadingAuth,
  getRoute,
  getSignedIn,
} from '../redux/selectors'

const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    paddingBottom: 32,
    overflow: 'hidden',
    minWidth: 320,
    maxWidth: 960,
  },
  authGreetingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  authGreetingButton: {
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    paddingTop: 12,
    paddingBottom: 12,
  },
  privacyPolicyContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 200,
  },
  privacyPolicyButton: {
    cursor: 'pointer',
  },
})

export const HomeScreen = memo(() => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const isLoadingAuth = useSelector(getLoadingAuth)
  const route = useSelector(getRoute)
  const isSignedIn = useSelector(getSignedIn)
  const appliedDiscount = useSelector(getAppliedDiscount)
  const customerDisplayName = useSelector(getCustomerDisplayName)

  return (
    <div className={classes.container}>
      <Logo />

      {isLoadingAuth ? (
        // Spinner while loading auth state
        <div className={classes.authGreetingContainer}>
          <CircularProgress size={40} />
        </div>
      ) : isSignedIn ? (
        // Sign-in dialog
        <div className={classes.authGreetingContainer}>
          <Typography align="center" variant="h5">
            Olá, {customerDisplayName}
          </Typography>
          <Button
            onClick={() => {
              if (appliedDiscount) {
                dispatch(actions.push({ name: ROUTE_CART_DISCARD_DISCOUNT }))
                return
              }

              dispatch(actions.signOutAttempt())
            }}
          >
            Sair
          </Button>
        </div>
      ) : (
        // Greetings to our signed-in user
        <div className={classes.authGreetingContainer}>
          <Button onClick={() => history.push('/autenticacao')}>
            Fazer login
          </Button>
          <Dialog
            open={route.name === ROUTE_AUTH}
            onClose={() => dispatch(actions.goBack({ name: ROUTE_HOME }))}
            maxWidth="xs"
            fullWidth
          >
            <DialogContent>
              <GoogleButton
                className={classes.authGreetingButton}
                onClick={() => dispatch(actions.signInAttempt({ provider: 'google' }))} // prettier-ignore
              >
                Continuar com Google
              </GoogleButton>
              <FacebookButton
                className={classes.authGreetingButton}
                onClick={() => dispatch(actions.signInAttempt({ provider: 'facebook' }))} // prettier-ignore
              >
                Continuar com Facebook
              </FacebookButton>
            </DialogContent>
          </Dialog>
        </div>
      )}

      <OpeningHoursAlert />
      <AppliedDiscount />
      <ActiveOrders />
      <BannerGrid />
      <ProductGrid />

      <div className={classes.privacyPolicyContainer}>
        <Typography variant="body2" className={classes.privacyPolicyButton}>
          <Link
            onClick={() => {
              history.push('/politica-de-privacidade')
              window.scrollTo(0, 0)
            }}
          >
            Política de Privacidade
          </Link>
        </Typography>
      </div>

      <DiscountCountdown />

      <DiscountGuard
        open={route.name === ROUTE_APPLY_DISCOUNT}
        idOrCode={route.payload.idOrCode}
      />
      <Cart
        open={[
          ROUTE_CART,
          ROUTE_CART_AUTH,
          ROUTE_CART_CHANGE_FOR,
          ROUTE_CART_DISCARD_DISCOUNT,
          ROUTE_CART_ITEM,
          ROUTE_CART_PAYMENT_METHOD,
        ].includes(route.name)}
      />
      <ProductDialog
        slug={route.name === ROUTE_PRODUCT ? route.payload.slug : null}
      />
      <ComboDialog
        slug={route.name === ROUTE_COMBO ? route.payload.slug : null}
      />

      <Dialog
        open={route.name === ROUTE_CART_DISCARD_DISCOUNT}
        onClose={() => dispatch(actions.goBack({ name: ROUTE_HOME }))}
        maxWidth="xs"
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ao sair da sua conta, o desconto será descartado. Deseja descartar o
            desconto e voltar aos preços normais?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(actions.goBack({ name: ROUTE_HOME }))}
          >
            Não
          </Button>
          <Button
            onClick={() => {
              dispatch(actions.signOutAttempt())
              dispatch(actions.goBack({ name: ROUTE_HOME }))
            }}
          >
            Sim, descartar desconto
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})
