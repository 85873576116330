import { formatCurrency } from '@keffs/formatters'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import {
  getDeliveryFee,
  getItemsError,
  getPackageFee,
  getServiceType,
  getSubtotalPrice,
  getTotalDiscount,
  getTotalPrice,
} from '../redux/selectors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 16,
  },
  totalDiscount: {
    fontWeight: 500,
    color: '#6abf40',
  },
})

export const CartTotals = memo(() => {
  const serviceType = useSelector(getServiceType)
  const itemsError = useSelector(getItemsError)
  const subtotalPrice = useSelector(getSubtotalPrice)
  const packageFee = useSelector(getPackageFee)
  const deliveryFee = useSelector(getDeliveryFee)
  const totalDiscount = useSelector(getTotalDiscount)
  const totalPrice = useSelector(getTotalPrice)

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography color={itemsError ? 'error' : undefined}>
        Subtotal: {formatCurrency(subtotalPrice)}
      </Typography>
      {itemsError && (
        <Typography align="right" color="error" variant="caption">
          {itemsError}
        </Typography>
      )}

      {serviceType ? (
        <Typography>
          {serviceType === 'delivery' ? 'Entrega' : 'Embalagem'}:{' '}
          {formatCurrency(packageFee + deliveryFee)}
        </Typography>
      ) : (
        <Typography>Entrega/embalagem: ?</Typography>
      )}

      {totalDiscount > 0 && (
        <Typography className={classes.totalDiscount}>
          Desconto: {formatCurrency(totalDiscount)}
        </Typography>
      )}

      <Typography variant="body1">
        {serviceType ? `TOTAL: ${formatCurrency(totalPrice)}` : 'TOTAL: ?'}
      </Typography>
    </div>
  )
})
