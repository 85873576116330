import { call, put, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'
import { ROUTE_CART, ROUTE_HOME } from '../../routes'
import { getCartEmpty, getRoute } from '../../selectors'

function* closeCartIfNeeded() {
  const route: ReturnType<typeof getRoute> = yield select(getRoute)

  if (route.name === ROUTE_CART) {
    const isCartEmpty: ReturnType<typeof getCartEmpty> = yield select(
      getCartEmpty,
    )

    if (isCartEmpty) {
      yield put(actions.goBack({ name: ROUTE_HOME }))
    }
  }
}

/**
 * Automatically goes away from cart route if it's empty.
 */
export function* closeEmptyCart() {
  // Close if initialized already in the cart route
  yield call(closeCartIfNeeded)

  while (true) {
    // Wait for any action that may remove items, clear the cart or
    // cause the cart to open
    yield take([
      getType(actions.removeItem),
      getType(actions.submitOrderSuccess),
      getType(actions.routeChanged),
    ])

    yield call(closeCartIfNeeded)
  }
}
