import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { getBanners } from '../redux/selectors'
import { Banner } from './banner'

const useStyles = makeStyles({
  root: {
    padding: '8px 0',
  },
})

export const BannerGrid = memo(() => {
  const classes = useStyles()

  const banners = useSelector(getBanners)

  if (banners.length === 0) {
    return null
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        {banners.map((banner, i) => (
          <Grid key={banner.id} item xs={12}>
            <Banner
              banner={banner}
              imageDesktop={banner.imageWide}
              imageMobile={banner.imageNarrow}
              position={i + 1}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
})
