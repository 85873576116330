import { computeAllowAsap } from '@keffs/data/src/computed'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getClock } from '../clock'
import { getTodaysOpeningHours } from './get-todays-opening-hours'

export const getAllowAsap = createSelector(
  (state: RootState) => getClock(state),
  (state: RootState) => getTodaysOpeningHours(state),

  (clock, todaysOpeningHours) => {
    return computeAllowAsap(clock, todaysOpeningHours)
  },
)
