import debounce from 'lodash/debounce'

export const GA_STEP_CART_OPENED = 1
export const GA_STEP_CHECKOUT_INITIATED = 2

/**
 * Needed to batch multiple product/promo impressions that are tracked
 * at the exact same time into a single event. Because of the `ga` queue
 * design, writing two events at the same time may cause mixed data to
 * be sent to Google servers.
 */
const debouncedGaImpressionEvent = debounce(() => {
  window.ga('send', 'event', 'Ecommerce', 'Impression', {
    nonInteraction: 1,
  })
}, 1000)

/**
 * Service encapsulating interaction with Google Analytics tracker.
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
 */
export class GoogleAnalyticsService {
  private debug: boolean
  private lastPathname: string | null = null

  constructor(options: { debug: boolean }) {
    this.debug = options.debug
  }

  pageView(params: { normalizedPathname: string }): void {
    // Prevent tracking duplicates
    if (params.normalizedPathname === this.lastPathname) {
      return
    }

    this.log('Page View', params)

    window.ga('set', 'page', params.normalizedPathname)
    window.ga('send', 'pageview')

    this.lastPathname = params.normalizedPathname
  }

  promoImpression(params: {
    id: string
    name: string
    position: string
  }): void {
    this.log('Promo Impression', params)

    window.ga('ec:addPromo', {
      id: params.id,
      name: params.name,
      position: params.position,
    })
    debouncedGaImpressionEvent()
  }

  promoClick(params: { id: string; name: string; position: string }): void {
    this.log('Promo Click', params)

    window.ga('ec:addPromo', {
      id: params.id,
      name: params.name,
      position: params.position,
    })
    window.ga('ec:setAction', 'promo_click')
    window.ga('send', 'event', 'Ecommerce', 'Promo Click')
  }

  productImpression(params: {
    id: string
    name: string
    price: number
    category: string
    list: string
    position: number
  }): void {
    this.log('Product Impression', params)

    window.ga('ec:addImpression', {
      id: params.id,
      name: params.name,
      price: params.price.toFixed(2),
      category: params.category,
      list: params.list,
      position: params.position,
    })
    debouncedGaImpressionEvent()
  }

  productClick(params: {
    id: string
    name: string
    price: number
    category: string
    position: number
  }): void {
    this.log('Product Click', params)

    window.ga('ec:addProduct', {
      id: params.id,
      name: params.name,
      price: params.price.toFixed(2),
      category: params.category,
      position: params.position,
    })
    window.ga('ec:setAction', 'click')
    window.ga('send', 'event', 'Ecommerce', 'Product Click')
  }

  productDetails(params: {
    id: string
    name: string
    price: number
    category: string
  }): void {
    this.log('Product Details', params)

    window.ga('ec:addProduct', {
      id: params.id,
      name: params.name,
      price: params.price.toFixed(2),
      category: params.category,
    })
    window.ga('ec:setAction', 'detail')
    window.ga('send', 'event', 'Ecommerce', 'Product View')
  }

  addProduct(params: {
    quantity: number
    id: string
    name: string
    price: number
    category: string
  }): void {
    this.log('Add Product', params)

    window.ga('ec:addProduct', {
      quantity: params.quantity,
      id: params.id,
      name: params.name,
      price: params.price.toFixed(2),
      category: params.category,
    })
    window.ga('ec:setAction', 'add')
    window.ga('send', 'event', 'Ecommerce', 'Add to Cart')
  }

  removeProduct(params: {
    quantity: number
    id: string
    name: string
    price: number
    category: string
  }): void {
    this.log('Remove Product', params)

    window.ga('ec:addProduct', {
      quantity: params.quantity,
      id: params.id,
      name: params.name,
      price: params.price.toFixed(2),
      category: params.category,
    })
    window.ga('ec:setAction', 'remove')
    window.ga('send', 'event', 'Ecommerce', 'Remove from Cart')
  }

  checkoutOption(params: { step: number; option: string }): void {
    this.log('Checkout Option', params)

    window.ga('ec:setAction', 'checkout_option', {
      step: params.step,
      option: params.option,
    })
    window.ga('send', 'event', 'Ecommerce', 'Checkout Option')
  }

  checkoutStep(params: {
    step: number
    cart: Array<{
      quantity: number
      id: string
      name: string
      price: number
      category: string
    }>
  }): void {
    this.log('Checkout Step', params)

    params.cart.forEach(item => {
      window.ga('ec:addProduct', {
        quantity: item.quantity,
        id: item.id,
        name: item.name,
        price: item.price.toFixed(2),
        category: item.category,
      })
    })
    window.ga('ec:setAction', 'checkout', { step: params.step })
    window.ga('send', 'event', 'Ecommerce', 'Checkout Step')
  }

  purchase(params: {
    orderId: string
    revenue: number
    coupon: string | undefined
    cart: Array<{
      quantity: number
      id: string
      name: string
      price: number
      category: string
    }>
  }): void {
    this.log('Purchase', params)

    params.cart.forEach(item => {
      window.ga('ec:addProduct', {
        quantity: item.quantity,
        id: item.id,
        name: item.name,
        price: item.price.toFixed(2),
        category: item.category,
      })
    })
    window.ga('ec:setAction', 'purchase', {
      id: params.orderId,
      revenue: params.revenue.toFixed(2),
      coupon: params.coupon,
    })
    window.ga('send', 'event', 'Ecommerce', 'Purchase')
  }

  private log(eventName: string, params?: { [key: string]: any }) {
    console.log(`[ga] ${eventName}:`, params || 'No params')
  }
}
