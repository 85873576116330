import { CustomerService } from '@keffs/core/src/services'
import { Customer } from '@keffs/data/src/types'
import firebase from 'firebase/app'
import { eventChannel } from 'redux-saga'
import { call, cancel, fork, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../../actions'

const setupFcmTokenEventChannel = () => {
  return eventChannel(emit => {
    try {
      // Get first FCM token
      void firebase
        .messaging()
        .getToken()
        .then(initialToken => {
          emit({ token: initialToken })
        })

      // Keep FCM token up-to-date on refreshes and return unsubscriber
      return firebase.messaging().onTokenRefresh(async () => {
        const refreshedToken = await firebase.messaging().getToken()
        emit({ token: refreshedToken })
      })
    } catch (error) {
      if (error.code === 'messaging/unsupported-browser') {
        console.warn('Supressed error: ', error)
      } else if (error.code === 'messaging/notifications-blocked') {
        console.warn('Supressed error: ', error) // Blocked by the browser
      } else {
        console.error(error)
      }

      // Noop unsubscriber
      return () => null
    }
  })
}

function* watchFcmToken(customerService: CustomerService, customer: Customer) {
  const fcmTokenEventChannel = yield call(setupFcmTokenEventChannel)
  while (true) {
    const { token }: { token: string | null } = yield take(fcmTokenEventChannel)

    if (token && !customer.fcmTokens.includes(token)) {
      // Add token to customer profile
      yield call(
        [customerService, customerService.addFcmToken],
        customer.id,
        token,
      )
    }
  }
}

/**
 * Adds the current FCM token on this device to the customer profile.
 */
export function* linkFcmTokenToCustomer(customerService: CustomerService) {
  // Begin on sign-in
  while (true) {
    const action: ActionType<typeof actions.signInSuccess> = yield take(
      getType(actions.signInSuccess),
    )

    const customer = action.payload

    const task = yield fork(watchFcmToken, customerService, customer)

    // Cancel on sign-out
    yield take(actions.signOutSuccess)
    yield cancel(task)
  }
}
