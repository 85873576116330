import { normalizePhoneNumber } from '@keffs/formatters'

const isValid = (value: string): boolean => {
  const phone = normalizePhoneNumber(value)

  // International format case
  if (phone.startsWith('+')) {
    return phone.length >= 9
  }

  // (__) _____-____ case
  if (phone[2] === '9' && phone.length <= 11) {
    return phone.length >= 11
  }

  // (__) ____-____ case
  // if (phone.length <= 10) {
  //   return phone.length === 10
  // }

  return false
}

export const validatePhoneNumber = (value: string): string | undefined => {
  if (!value) {
    return 'Necessário preencher o celular para contato'
  }

  if (!isValid(value)) {
    return 'Número inválido'
  }

  return undefined
}
