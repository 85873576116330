import { RootState, Route } from '../../types'

export const getRoute = (state: RootState): Route => {
  if (state.router.current == null) {
    throw new Error(
      "Cannot get route because it's null. Router was not initialized.",
    )
  }

  return state.router.current
}
