import { isWithinInterval, subMinutes } from 'date-fns'

/**
 * Computes whether given the current time the option ASAP should be
 * allowed.
 *
 * @param now The current time.
 * @param todaysOpeningHours The object specifying the start and end of
 * the opening hours for today.
 *
 * @returns The resulting boolean.
 */
export const computeAllowAsap = (
  now: Date,
  todaysOpeningHours: { start: Date; end: Date } | null,
): boolean => {
  if (todaysOpeningHours == null) {
    return false
  }

  const asapStart = subMinutes(todaysOpeningHours.start, 10)
  const asapEnd = todaysOpeningHours.end

  return isWithinInterval(now, { start: asapStart, end: asapEnd })
}
