import { ServiceTypeLiteral } from '@keffs/data/src/literals'
import { ServiceType } from '@keffs/data/src/types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import { ROUTE_CART, ROUTE_CART_DISCARD_DISCOUNT } from '../redux/routes'
import {
  getAllowAsap,
  getAppliedDiscount,
  getRoute,
  getServiceType,
  getServiceTypeError,
  getServiceTypeTouched,
} from '../redux/selectors'

export const CartServiceType = memo(() => {
  const dispatch = useDispatch()
  const route = useSelector(getRoute)
  const allowAsap = useSelector(getAllowAsap)
  const appliedDiscount = useSelector(getAppliedDiscount)
  const serviceType = useSelector(getServiceType)
  const serviceTypeError = useSelector(getServiceTypeError)
  const serviceTypeTouched = useSelector(getServiceTypeTouched)

  const handleServiceTypeBlur = () => {
    if (!serviceTypeTouched) {
      dispatch(actions.touchServiceType())
    }
  }

  const handleServiceTypeChange = (
    e: React.ChangeEvent<unknown>,
    value: string,
  ) => {
    if (
      appliedDiscount &&
      !appliedDiscount.serviceTypes.includes(value as ServiceType)
    ) {
      dispatch(
        actions.push({
          name: ROUTE_CART_DISCARD_DISCOUNT,
          state: { serviceType: value },
        }),
      )
      return
    }

    dispatch(actions.setServiceType(value as ServiceType))
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        Modalidade
      </Typography>

      <FormControl error={Boolean(serviceTypeTouched && serviceTypeError)}>
        <RadioGroup
          id="cart-service-type"
          onBlur={handleServiceTypeBlur}
          onChange={handleServiceTypeChange}
          value={serviceType}
        >
          <FormControlLabel
            id="service-type-eat-in"
            control={<Radio color="primary" />}
            disabled={!allowAsap}
            label={
              <span>
                <strong>{ServiceTypeLiteral.eatIn}</strong>
                <div>Será servido na bandeja</div>
              </span>
            }
            value="eatIn"
          />
          <FormControlLabel
            id="service-type-take-out"
            control={<Radio color="primary" />}
            label={
              <span>
                <strong>{ServiceTypeLiteral.takeOut}</strong>
                <div>Será embalado para viagem</div>
              </span>
            }
            value="takeOut"
          />
          <FormControlLabel
            id="service-type-delivery"
            control={<Radio color="primary" />}
            label={
              <span>
                <strong>{ServiceTypeLiteral.delivery}</strong>
                <div>Entregaremos em seu endereço</div>
              </span>
            }
            value="delivery"
          />
          {/* <FormControlLabel
              control={<Radio color="primary" />}
              label={
                <span>
                  <strong>DRIVE-BY</strong>
                  <div>Estacione em frente, levaremos até seu carro</div>
                </span>
              }
              value="driveBy"
            /> */}
        </RadioGroup>
        {serviceTypeTouched && serviceTypeError && (
          <FormHelperText>{serviceTypeError}</FormHelperText>
        )}
      </FormControl>

      <Dialog
        open={route.name === ROUTE_CART_DISCARD_DISCOUNT}
        onClose={() => dispatch(actions.goBack({ name: ROUTE_CART }))}
        maxWidth="xs"
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O desconto aplicado não vale para essa modalidade. Deseja descartar
            o desconto e voltar aos preços normais?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(actions.goBack({ name: ROUTE_CART }))}
          >
            Não
          </Button>
          <Button
            onClick={() => {
              const serviceType = route.state?.serviceType as
                | ServiceType
                | undefined
              if (serviceType) {
                dispatch(actions.setServiceType(serviceType))
                dispatch(actions.goBack({ name: ROUTE_CART }))
              }
            }}
          >
            Sim, descartar desconto
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})
