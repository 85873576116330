import { validateSelectedLocation } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getSelectedLocation } from './get-selected-location'
import { getServiceType } from './get-service-type'

export const getSelectedLocationError = createSelector(
  (state: RootState) => getSelectedLocation(state),
  (state: RootState) => getServiceType(state),

  (selectedLocation, serviceType) => {
    if (serviceType === 'delivery') {
      return validateSelectedLocation(selectedLocation)
    }

    return undefined
  },
)
