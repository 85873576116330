import successSound from '@keffs/core/src/assets/sfx/success.mp3'
import { call, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'

const playAudio = async () => {
  const audio = new Audio(successSound)
  try {
    await audio.play()
  } catch (error) {
    console.log("Couldn't play audio")
  }
}

export function* soundOnOrderSubmitted() {
  while (true) {
    yield take(getType(actions.submitOrderSuccess))

    yield call(playAudio)
  }
}
