import { loadState, saveState } from '@keffs/core/src/redux/persist'
import {
  AuthService,
  BannerService,
  ComboService,
  CustomerService,
  DiscountService,
  OrderService,
  ProductCategoryService,
  ProductService,
  RestaurantService,
} from '@keffs/core/src/services'
import * as Sentry from '@sentry/browser'
import throttle from 'lodash/throttle'
import { Pusher } from 'pusher-js'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import { AnalyticsService } from '../services'
import { rootReducer } from './reducers'
import { rootSaga } from './sagas'
import { getSubmitOnReload } from './selectors'
import { RootState } from './types'

/**
 * Whenever the structure of the persisted state changes,
 * this constant must be incremented.
 */
const CURRENT_VERSION = 0

export const PERSIST_THROTTLE_INTERVAL = 1000

/**
 * Returns the portion of the state that should be persisted.
 */
const getStateToPersist = (state: RootState) => {
  const submitOnReload = getSubmitOnReload(state)
  if (submitOnReload) {
    // If submitOnReload we will need the whole cart
    return {
      cart: state.cart,
    }
  } else {
    // Otherwise persist only the essential
    return {
      cart: {
        serviceType: state.cart.serviceType,
        items: state.cart.items,
        discount: state.cart.discount,
        phoneNumber: state.cart.phoneNumber,
        name: state.cart.name,
        cpf: state.cart.cpf,
        deliveryTime: state.cart.deliveryTime,
        selectedLocation: state.cart.selectedLocation,
        address: state.cart.address,
        district: state.cart.district,
        reference: state.cart.reference,
        vehicleModel: state.cart.vehicleModel,
        vehicleColor: state.cart.vehicleColor,
        vehiclePlate: state.cart.vehiclePlate,
        paymentMethod: state.cart.paymentMethod,
        changeFor: state.cart.changeFor,
      },
    }
  }
}

type Dependencies = {
  analyticsService: AnalyticsService
  authService: AuthService
  bannerService: BannerService
  comboService: ComboService
  customerService: CustomerService
  discountService: DiscountService
  orderService: OrderService
  productCategoryService: ProductCategoryService
  productService: ProductService
  restaurantService: RestaurantService
  pusher: Pusher
}

export const configureStore = (dependencies: Dependencies) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      console.error(error)
      console.error(sagaStack)
      Sentry.captureException(error)
    },
  })

  // Restore persisted
  const persistedState = loadState(CURRENT_VERSION)

  // Create store
  const middlewares = applyMiddleware(sagaMiddleware)
  const enhancers = composeWithDevTools(middlewares)
  const store = createStore(rootReducer, persistedState, enhancers)

  // Keep persisting
  store.subscribe(
    throttle(() => {
      const state = store.getState()
      saveState(getStateToPersist(state), CURRENT_VERSION)
    }, PERSIST_THROTTLE_INTERVAL),
  )

  sagaMiddleware.run(rootSaga, dependencies)

  return store
}
