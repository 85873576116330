/**
 * Formats a phone number.
 *
 * @param value The string to be formatted.
 *
 * @returns The formatted string.
 */
export const formatPhoneNumber = (value: string): string => {
  if (value.startsWith('+55')) {
    return formatPhoneNumber(value.slice(3))
  }

  if (value.length === 11) {
    const areaCode = value.substring(0, 2)
    const firstPart = value.substring(2, 7)
    const lastPart = value.substring(7, 11)
    return `(${areaCode}) ${firstPart}-${lastPart}`
  }

  if (value.length === 10) {
    const areaCode = value.substring(0, 2)
    const firstPart = value.substring(2, 6)
    const lastPart = value.substring(6, 10)
    return `(${areaCode}) ${firstPart}-${lastPart}`
  }

  return value
}
