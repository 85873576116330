import { RootState } from '../types'

export const getLoadingData = (state: RootState): boolean => {
  return (
    state.banners.loading ||
    state.combos.loading ||
    state.productCategories.loading ||
    state.products.loading ||
    state.restaurant.loading
  )
}
