import {
  Button,
  MuiThemeProvider,
  Theme,
  createMuiTheme,
} from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { IoLogoFacebook } from 'react-icons/io'

const useStyles = makeStyles({
  icon: {
    marginRight: 8,
  },
})

const themeFactory = (outer: Theme | null): Theme =>
  createMuiTheme({
    ...outer!,
    palette: {
      ...outer!.palette,
      primary: {
        main: '#4267b2',
      } as any,
    },
  })

export type FacebookButtonProps = ButtonProps

export const FacebookButton = ({
  children,
  ...rest
}: FacebookButtonProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={themeFactory}>
      <Button color="primary" variant="contained" {...rest}>
        <IoLogoFacebook className={classes.icon} size={24} />
        {children}
      </Button>
    </MuiThemeProvider>
  )
}
