import { isIsoDate } from '@keffs/data/src/utils'

const EXPIRATION_TIME_IN_MS = 24 * 60 * 60 * 1000

/**
 * Restores the persisted store state from Local Storage. Discard if
 * `expectVersion` doesn't match persisted `stateVersion` to prevent
 * restoring an old version with a schema our application doesn't
 * support anymore.
 *
 * @param expectedVersion The expected version.
 *
 * @returns The restored state or undefined.
 */
export const loadState = (expectedVersion: number): any => {
  try {
    const serializedState = localStorage.getItem('state')
    const serializedTimestamp = localStorage.getItem('stateTimestamp')
    const serializedVersion = localStorage.getItem('stateVersion')

    // Not found?
    if (
      serializedState == null ||
      serializedTimestamp == null ||
      serializedVersion == null
    ) {
      return undefined
    }

    // Different version?
    const version = JSON.parse(serializedVersion)
    if (version !== expectedVersion) {
      return undefined
    }

    // Expired?
    const timestamp: number = JSON.parse(serializedTimestamp)
    if (Date.now() - timestamp > EXPIRATION_TIME_IN_MS) {
      return undefined
    }

    return JSON.parse(serializedState, (key, value) =>
      isIsoDate(value) ? new Date(value) : value,
    )
  } catch (error) {
    return undefined
  }
}

/**
 * Persists the store state to Local Storage.
 *
 * @param state The state object.
 * @param currentVersion The version number for the current schema.
 */
export const saveState = (state: unknown, currentVersion: number): void => {
  try {
    const serializedState = JSON.stringify(state)
    const serializedTimestamp = JSON.stringify(Date.now())
    const serializedVersion = JSON.stringify(currentVersion)
    localStorage.setItem('state', serializedState)
    localStorage.setItem('stateTimestamp', serializedTimestamp)
    localStorage.setItem('stateVersion', serializedVersion)
  } catch (error) {
    return
  }
}
