import { validatePhoneNumber } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getPhoneNumber } from './get-phone-number'
import { getServiceType } from './get-service-type'

export const getPhoneNumberError = createSelector(
  (state: RootState) => getPhoneNumber(state),
  (state: RootState) => getServiceType(state),

  (phoneNumber, serviceType) => {
    // Optional when eatIn
    if (serviceType === 'eatIn' && !phoneNumber) {
      return undefined
    }

    return validatePhoneNumber(phoneNumber)
  },
)
