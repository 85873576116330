import { call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import {
  getAppliedDiscount,
  getCombosMap,
  getItems,
  getPaymentMethod,
  getProductCategoriesMap,
  getProductsMap,
  getRestaurantCity,
  getRestaurantCode,
  getServiceType,
  getTotalDiscount,
  getTotalPrice,
} from '../../selectors'
import { RootState } from '../../types'

export function* checkoutPurchased(analyticsService: AnalyticsService) {
  // Save state whenever submitOrderAttempt happens as submitOrderSuccess
  // will have cleared the data we need to track the purchase
  let prevState: RootState = yield select()
  yield takeLatest(getType(actions.submitOrderAttempt), function* () {
    prevState = yield select()
  })

  // On order submitted...
  yield takeEvery(getType(actions.submitOrderSuccess), function* (
    action: ActionType<typeof actions.submitOrderSuccess>,
  ) {
    const orderId = action.payload

    const restaurantCode = getRestaurantCode(prevState)
    const restaurantCity = getRestaurantCity(prevState)
    const items = getItems(prevState)
    const combosMap = getCombosMap(prevState)
    const productsMap = getProductsMap(prevState)
    const categoriesMap = getProductCategoriesMap(prevState)
    const serviceType = getServiceType(prevState)
    const discount = getAppliedDiscount(prevState)
    const totalPrice = getTotalPrice(prevState)
    const totalDiscount = getTotalDiscount(prevState)
    const paymentMethod = getPaymentMethod(prevState)

    if (!restaurantCode) {
      throw new Error('`restaurantCode` should not be empty')
    } else if (!restaurantCity) {
      throw new Error('`restaurantCity` should not be empty')
    } else if (!serviceType) {
      throw new Error('`serviceType` should not be empty')
    }

    // Track
    yield call([analyticsService, analyticsService.purchased], {
      restaurantCode,
      restaurantCity,
      orderId,
      attendantCode: undefined,
      attendantName: undefined,
      serviceType,
      channel: 'website',
      discountId: discount ? discount.id : undefined,
      discountType: discount ? discount.type : undefined,
      discountValue: discount ? discount.value : undefined,
      paymentMethod: paymentMethod || undefined,
      cart: items.map(item => {
        if ('productId' in item) {
          const product = productsMap[item.productId]
          const category = categoriesMap[product.categoryId]
          return {
            quantity: item.quantity,
            productId: product.id,
            productCode: `P${product.code}`,
            productName: product.name,
            productPrice: product.unitPrice,
            categoryId: category.id,
            categoryName: category.name,
          }
        } else {
          const combo = combosMap[item.comboId]
          return {
            quantity: item.quantity,
            productId: combo.id,
            productCode: `C${combo.code}`,
            productName: combo.name,
            productPrice: combo.unitPrice,
            categoryId: undefined,
            categoryName: 'Combo',
          }
        }
      }),
      revenue: totalPrice,
      discountGiven: totalDiscount,
    })
  })
}
