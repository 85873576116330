import { addMinutes } from 'date-fns'

import { ServiceType } from '../types'

/**
 * Computes all the available delivery times given the current time
 * and the service type.
 *
 * @param serviceType The service type for which we are computing.
 * @param now The current time.
 * @param allDeliveryTimes The array with all the possible delivery times.
 *
 * @returns An array of Date objects.
 */
export const computeAvailableDeliveryTimes = (
  serviceType: ServiceType,
  now: Date,
  allDeliveryTimes: Array<{ start: Date; end: Date }>,
): Date[] => {
  const x = serviceType === 'delivery' ? 30 : 10
  const nowPlusX = addMinutes(now, x)

  return allDeliveryTimes.reduce(
    (availableDeliveryTimes: Date[], { start }) => {
      if (start >= nowPlusX) {
        // Available: add to array
        return [...availableDeliveryTimes, start]
      }

      // Not available: preserve as is
      return availableDeliveryTimes
    },
    [],
  )
}
