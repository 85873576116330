import { createSelector } from 'reselect'

import { RootState } from '../types'
import { getProductsMap } from './get-products-map'

export const getProducts = createSelector(
  (state: RootState) => getProductsMap(state),

  productsMap => {
    return Object.keys(productsMap)
      .map(id => productsMap[id])
      .sort((a, b) => a.position - b.position)
  },
)
