import { call, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'

export function* userSignedUp(analyticsService: AnalyticsService) {
  // On signed-up...
  while (true) {
    const action: ActionType<typeof actions.signedUp> = yield take(
      getType(actions.signedUp),
    )

    const identityProvider = action.payload.identityProvider

    // Track
    yield call([analyticsService, analyticsService.signedUp], {
      identityProvider,
    })
  }
}
