import { validateDeliveryTime } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getAllowAsap, getAvailableDeliveryTimes } from '../opening-hours'
import { getDeliveryTime } from './get-delivery-time'

export const getDeliveryTimeError = createSelector(
  (state: RootState) => getDeliveryTime(state),
  (state: RootState) => getAllowAsap(state),
  (state: RootState) => getAvailableDeliveryTimes(state),

  (deliveryTime, allowAsap, availableDeliveryTimes) => {
    return validateDeliveryTime(deliveryTime, {
      allowAsap,
      availableDeliveryTimes,
    })
  },
)
