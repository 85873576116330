import { ErrorScreen } from '@keffs/core/src/components/error-screen'
import { LoadingScreen } from '@keffs/core/src/components/loading-screen'
import { ServiceTypeLiteral } from '@keffs/data/src/literals'
import {
  formatCpf,
  formatCurrency,
  formatPhoneNumber,
  formatVehiclePlate,
} from '@keffs/formatters'
import {
  AppBar,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { ArrowBack as MdArrowBack } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { addMinutes, format as formatDate } from 'date-fns'
import React, { memo, useContext } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { CartItem } from '../components/cart-item'
import { ServicesContext } from '../contexts'
import * as actions from '../redux/actions'
import { ROUTE_HOME } from '../redux/routes'
import { getRoute } from '../redux/selectors'
import { CartOrderItem } from '../redux/types'

const useStyles = makeStyles({
  appBarButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBarTitle: {
    fontSize: 21,
  },
  container: {
    margin: '32px auto',
    minWidth: 320,
    maxWidth: 480,
    minHeight: 600,
  },
  items: {
    position: 'relative',
    userSelect: 'none',
  },
  itemImage: {
    flexShrink: 0,
    flexGrow: 0,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 64,
    height: 64,
    filter: 'brightness(105%)',
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 16,
  },
  totalDiscount: {
    fontWeight: 500,
    color: '#6abf40',
  },
  form: {
    paddingRight: 16,
    paddingBottom: 32 + 54, // 54px from bottom button
    paddingLeft: 16,
  },
  section: {
    margin: '32px 16px',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 12,
    width: 36,
    height: 36,
  },
})

export const OrderDetailsScreen = memo(() => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const route = useSelector(getRoute)

  const { orderService } = useContext(ServicesContext)

  const orderQuery = useQuery(['order', route.payload.id], () => {
    return orderService.fetchById(route.payload.id)
  })
  const order = orderQuery.data ?? null

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  if (orderQuery.status === 'loading' || !order) {
    return <LoadingScreen />
  }

  if (orderQuery.status === 'error') {
    return <ErrorScreen />
  }

  return (
    <>
      <AppBar color="default" position="static">
        <Toolbar>
          <IconButton
            className={classes.appBarButton}
            color="inherit"
            onClick={handleClose}
          >
            <MdArrowBack />
          </IconButton>
          <Typography
            className={classes.appBarTitle}
            color="inherit"
            variant="h6"
          >
            Detalhes do pedido
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.container}>
        <div className={classes.items}>
          <List>
            {order.items
              .slice()
              .reverse()
              .map((item, index) => {
                // Convert OrderItem to CartOrderItem for <CartItem />
                let cartItem: CartOrderItem
                if ('productId' in item) {
                  cartItem = {
                    ...item,
                    id: String(index),
                  }
                } else {
                  cartItem = {
                    ...item,
                    id: String(index),
                    choosenItems: item.choosenItems.map(choosenItem => ({
                      ...choosenItem,
                      id: String(index),
                    })),
                  }
                }

                return (
                  <CartItem
                    key={index}
                    isLastInList={index === order.items.length - 1}
                    item={cartItem}
                    readOnly
                  />
                )
              })}
          </List>
        </div>

        <div className={classes.totals}>
          <Typography>
            Subtotal: {formatCurrency(order.subtotalPrice)}
          </Typography>

          <Typography>
            {order.serviceType === 'delivery' ? 'Entrega' : 'Embalagem'}:{' '}
            {formatCurrency(order.deliveryFee + order.packageFee)}
          </Typography>

          {order.totalDiscount > 0 && (
            <Typography className={classes.totalDiscount}>
              Desconto: {formatCurrency(order.totalDiscount)}
            </Typography>
          )}

          <Typography variant="body1">
            TOTAL: {formatCurrency(order.totalPrice)}
          </Typography>
        </div>

        <div className={classes.form}>
          <div className={classes.section}>
            <Typography gutterBottom variant="h6">
              Modalidade
            </Typography>
            <Typography variant="subtitle1">
              {ServiceTypeLiteral[order.serviceType]}
            </Typography>
          </div>

          {(order.phoneNumber || order.cpf) && (
            <div className={classes.section}>
              <Typography gutterBottom variant="h6">
                Dados do cliente
              </Typography>
              {order.phoneNumber && (
                <Typography variant="subtitle1">
                  {formatPhoneNumber(order.phoneNumber || '')}
                </Typography>
              )}

              {order.cpf && (
                <Typography variant="subtitle1">
                  {formatCpf(order.cpf)}
                </Typography>
              )}
            </div>
          )}

          {order.serviceType === 'driveBy' && (
            <div className={classes.section}>
              <Typography gutterBottom variant="h6">
                Veículo
              </Typography>
              <Typography variant="subtitle1">
                {order.vehicleModel} {order.vehicleColor}
              </Typography>
              <Typography variant="body1">
                {formatVehiclePlate(order.vehiclePlate!)}
              </Typography>
            </div>
          )}

          <div className={classes.section}>
            <Typography gutterBottom variant="h6">
              Quando?
            </Typography>
            {order.scheduled ? (
              <Typography variant="subtitle1">
                Agendado para{' '}
                {order.serviceType === 'delivery'
                  ? 'entre ' +
                    formatDate(order.deliveryTime, 'H:mm') +
                    ' e ' +
                    formatDate(addMinutes(order.deliveryTime, 30), 'H:mm')
                  : formatDate(order.deliveryTime, 'H:mm')}{' '}
                do dia {formatDate(order.deliveryTime, 'dd/MM/yyyy')}
              </Typography>
            ) : (
              <Typography variant="subtitle1">
                Às {formatDate(order.deliveryTime, 'H:mm')} do dia{' '}
                {formatDate(order.deliveryTime, 'dd/MM/yyyy')}
              </Typography>
            )}
          </div>

          {order.serviceType === 'delivery' && (
            <div className={classes.section}>
              <Typography gutterBottom variant="h6">
                Onde?
              </Typography>
              <Typography variant="subtitle1">
                {order.address}, {order.district}
              </Typography>
              <Typography color="textSecondary">{order.reference}</Typography>
            </div>
          )}

          {order.serviceType === 'delivery' && (
            <div className={classes.section}>
              <Typography gutterBottom variant="h6">
                Forma de pagamento
              </Typography>
              <Typography variant="subtitle1">
                {order.paymentMethod === 'card'
                  ? 'Trazer máquina (cartão ou Pix)'
                  : 'Em dinheiro'}
              </Typography>
              <Typography color="textSecondary">
                {order.changeFor == null
                  ? 'Sem troco'
                  : 'Troco para ' + formatCurrency(order.changeFor)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  )
})
