import type { BrowserHistoryOptions } from 'history'
import {
  History as OriginalHistory,
  createBrowserHistory as originalCreateBrowserHistory,
} from 'history'

const initialLocationKey = '__INITIAL_LOCATION__'

export interface History extends Omit<OriginalHistory, 'goBack'> {
  goBack: (fallback: string) => void
}

export const createBrowserHistory = (
  options?: BrowserHistoryOptions,
): History => {
  const history = originalCreateBrowserHistory(options) as History

  // Put a flag on the state of the initial location. We'll check for this flag
  // whenever we need to know whether the current location is the initial one in
  // the browser history.
  history.replace(
    {
      pathname: history.location.pathname,
      search: history.location.search,
      hash: history.location.hash,
    },
    {
      [initialLocationKey]: true,
    },
  )

  history.goBack = (fallbackPath: string) => {
    const state = history.location.state as any

    // Are we in the initial location? In case so, 'goBack' would originally
    // make us leave the website. Instead, let's use 'replace' to go to the
    // fallback path.
    if (state && state[initialLocationKey]) {
      history.replace(fallbackPath, state)
      return
    }

    window.history.go(-1)
  }

  return history
}

export type {
  Action,
  Blocker,
  BrowserHistory,
  BrowserHistoryOptions,
  Hash,
  HashHistory,
  HashHistoryOptions,
  // History,
  InitialEntry,
  Key,
  Listener,
  Location,
  MemoryHistory,
  MemoryHistoryOptions,
  PartialLocation,
  PartialPath,
  Path,
  Pathname,
  Search,
  State,
  To,
  Transition,
  Update,
} from 'history'

export {
  // createBrowserHistory,
  createHashHistory,
  createMemoryHistory,
  createPath,
  parsePath,
} from 'history'
