import { call, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'

export function* userSignedIn(analyticsService: AnalyticsService) {
  while (true) {
    const action: ActionType<typeof actions.signInSuccess> = yield take(
      getType(actions.signInSuccess),
    )

    const customer = action.payload

    // Track
    yield call([analyticsService, analyticsService.signedIn], {
      uid: customer.id,
      name: customer.displayName,
      phoneNumber: customer.phoneNumber,
      photoUrl: customer.photoUrl,
      email: customer.email,
    })
  }
}
