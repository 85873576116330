import { computeAvailableDeliveryTimes } from '@keffs/data/src/computed'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from '../cart/get-service-type'
import { getClock } from '../clock'
import { getAllDeliveryTimes } from './get-all-delivery-times'

export const getAvailableDeliveryTimes = createSelector(
  (state: RootState) => getServiceType(state),
  (state: RootState) => getClock(state),
  (state: RootState) => getAllDeliveryTimes(state),

  (serviceType, clock, allDeliveryTimes) => {
    // Even when the restaurant is closed it doesn't mean there are
    // absolutely no available delivery times. So we use a fallback to
    // enforce correct behavior even when the user didn't yet choose a
    // the serviceType.
    const serviceTypeOrFallback = serviceType || 'delivery'
    return computeAvailableDeliveryTimes(
      serviceTypeOrFallback,
      clock,
      allDeliveryTimes,
    )
  },
)
