import { call, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import { ROUTE_CART } from '../../routes'
import {
  getCartEmpty,
  getCombosMap,
  getItems,
  getProductCategoriesMap,
  getProductsMap,
} from '../../selectors'
import { redactCart } from './utils'

export function* checkoutCartOpened(analyticsService: AnalyticsService) {
  while (true) {
    const action: ReturnType<typeof actions.routeChanged> = yield take(
      getType(actions.routeChanged),
    )

    const route = action.payload

    if (route.action === 'PUSH' && route.name === ROUTE_CART) {
      const isCartEmpty: ReturnType<typeof getCartEmpty> = yield select(
        getCartEmpty,
      )

      if (!isCartEmpty) {
        const items: ReturnType<typeof getItems> = yield select(getItems)
        const combosMap: ReturnType<typeof getCombosMap> = yield select(
          getCombosMap,
        )
        const productsMap: ReturnType<typeof getProductsMap> = yield select(
          getProductsMap,
        )
        const categoriesMap: ReturnType<typeof getProductCategoriesMap> = yield select(
          getProductCategoriesMap,
        )

        // Track
        yield call([analyticsService, analyticsService.cartOpened], {
          cart: redactCart(items, categoriesMap, productsMap, combosMap),
        })
      }
    }
  }
}
