import { ServiceType } from '../types'
import { subdivideInterval } from '../utils'

/**
 * Computes all the possible delivery times for the specified service
 * type.
 *
 * @param serviceType The service type for which we are computing
 * delivery times.
 * @param todaysOpeningHours The object specifying the start and end of
 * the opening hours for today.
 *
 * @returns The array of delivery times.
 */
export const computeAllDeliveryTimes = (
  serviceType: ServiceType,
  todaysOpeningHours: { start: Date; end: Date } | null,
): Array<{ start: Date; end: Date }> => {
  if (todaysOpeningHours == null) {
    return []
  }

  const allDeliveryTimes = subdivideInterval(
    todaysOpeningHours.start,
    todaysOpeningHours.end,
    serviceType === 'delivery' ? 30 : 10,
  )

  return allDeliveryTimes
}
