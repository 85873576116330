import { validateReference } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getReference } from './get-reference'
import { getSelectedLocation } from './get-selected-location'
import { getServiceType } from './get-service-type'

export const getReferenceError = createSelector(
  (state: RootState) => getReference(state),
  (state: RootState) => getServiceType(state),
  (state: RootState) => getSelectedLocation(state),

  (reference, serviceType, selectedLocation) => {
    if (serviceType === 'delivery' && selectedLocation === 'new') {
      return validateReference(reference)
    }

    return undefined
  },
)
