import { addCustomEvent, record } from 'rrweb'

import { flushEvents, pushEvent } from './buffer'
import { addUserId, createSession, savePacket, setConfig } from './firebase'
import { before } from './utils'

const INTERVAL_BETWEEN_PACKETS_IN_MS = 3 * 1000

let isInitialized = false

const init = ({
  appVersion,
  projectId,
}: {
  appVersion: string
  projectId: string
}): void => {
  if (isInitialized) {
    throw new Error('Screen recorder is already initialized')
  }

  isInitialized = true
  setConfig({ projectId })

  void createSession({ appVersion })

  setInterval(() => {
    const events = flushEvents()
    if (events.length > 0) {
      void savePacket('time', events)
    }
  }, INTERVAL_BETWEEN_PACKETS_IN_MS)

  let lastHiddenAt = 0
  const hideHandler = (e: any) => {
    if (document.visibilityState !== 'hidden') {
      return
    }

    const now = Date.now()
    if (now - lastHiddenAt < 1000) {
      return
    }

    lastHiddenAt = now

    const events = flushEvents()
    void savePacket('hidden', events)
  }
  document.addEventListener('visibilitychange', hideHandler)
  document.addEventListener('pagehide', hideHandler)

  record({
    emit: event => pushEvent(event),
    sampling: { scroll: 30 },
  })

  // Record what's written to the console
  console.debug = before(console.debug, (...args) => addCustomEvent('debug', args)) // prettier-ignore
  console.error = before(console.error, (...args) => addCustomEvent('error', args)) // prettier-ignore
  console.info = before(console.info, (...args) => addCustomEvent('info', args))
  console.log = before(console.log, (...args) => addCustomEvent('log', args))
  console.trace = before(console.trace, (...args) => addCustomEvent('trace', args)) // prettier-ignore
  console.warn = before(console.warn, (...args) => addCustomEvent('warn', args))
}

const linkUser = (userId: string): void => {
  if (!isInitialized) {
    throw new Error('Screen recorder is not initialized')
  }

  void addUserId(userId)
}

export const ScreenRecorder = {
  init,
  linkUser,
}
