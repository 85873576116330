import { createStandardAction as action } from 'typesafe-actions'

import { Route, RoutesMap, Target } from '../types'

export const initRouter = action('router/INIT')<RoutesMap>()

export const routeChanged = action('router/CHANGED')<Route>()

export const push = action('router/PUSH')<Target | string>()

export const replace = action('router/REPLACE')<Target | string>()

export const goBack = action('router/GO_BACK')<Target | string>()
