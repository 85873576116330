import { validatePaymentMethod } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getPaymentMethod } from './get-payment-method'
import { getServiceType } from './get-service-type'

export const getPaymentMethodError = createSelector(
  (state: RootState) => getPaymentMethod(state),
  (state: RootState) => getServiceType(state),

  (paymentMethod, serviceType) => {
    if (serviceType !== 'delivery') {
      return undefined
    }

    return validatePaymentMethod(paymentMethod)
  },
)
