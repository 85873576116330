export const ANTICIPATION_FOR_SCHEDULED_DELIVERY_OR_DRIVE_BY_IN_MINUTES = 15

export const ANTICIPATION_FOR_SCHEDULED_EAT_IN_OR_TAKE_OUT_IN_MINUTES = 10

export const DELIVERY_FEE = 9

export const MIN_DELIVERY_ORDER = 19.75

export const MIN_ORDER_FOR_FREE_PACKAGE = 12

export const PACKAGE_FEE = 2
