import { getType } from 'typesafe-actions'

import { initRouter, routeChanged } from '../actions'
import { history } from '../history'
import { RootAction, Route, RoutesMap } from '../types'
import { urlToRoute } from '../utils'

export type State = {
  readonly current: Route | null
  readonly routesMap: RoutesMap
}

export const initialState: State = {
  current: null,
  routesMap: {},
}

export const routerReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(initRouter): {
      const url = history.location.pathname + history.location.search
      const routesMap = action.payload
      return {
        ...state,
        current: urlToRoute(url, routesMap),
        routesMap,
      }
    }

    case getType(routeChanged): {
      return { ...state, current: action.payload }
    }

    default: {
      return state
    }
  }
}
