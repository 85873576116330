import { TextField } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import React from 'react'
import MaskedInput from 'react-text-mask'

const CpfInput = ({ inputRef, ...rest }: any) => {
  return (
    <MaskedInput
      {...rest}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
    />
  )
}

export type CpfFieldProps = StandardTextFieldProps

export const CpfField = (props: CpfFieldProps): React.ReactElement => {
  return (
    <TextField
      type="tel"
      InputProps={{
        inputComponent: CpfInput,
        ...(props.InputProps ? props.InputProps : {}),
      }}
      {...props}
    />
  )
}
