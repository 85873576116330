import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getAddressError } from './get-address-error'
import { getCpfError } from './get-cpf-error'
import { getDeliveryTimeError } from './get-delivery-time-error'
import { getDistrictError } from './get-district-error'
import { getItemsError } from './get-items-error'
import { getNameError } from './get-name-error'
import { getPaymentMethodError } from './get-payment-method-error'
import { getPhoneNumberError } from './get-phone-number-error'
import { getReferenceError } from './get-reference-error'
import { getSelectedLocationError } from './get-selected-location-error'
import { getServiceTypeError } from './get-service-type-error'
import { getVehicleColorError } from './get-vehicle-color-error'
import { getVehicleModelError } from './get-vehicle-model-error'
import { getVehiclePlateError } from './get-vehicle-plate-error'

export const getAllErrors = createSelector(
  [
    (state: RootState) => getServiceTypeError(state),
    (state: RootState) => getItemsError(state),
    (state: RootState) => getPhoneNumberError(state),
    (state: RootState) => getNameError(state),
    (state: RootState) => getCpfError(state),
    (state: RootState) => getVehicleModelError(state),
    (state: RootState) => getVehicleColorError(state),
    (state: RootState) => getVehiclePlateError(state),
    (state: RootState) => getDeliveryTimeError(state),
    (state: RootState) => getSelectedLocationError(state),
    (state: RootState) => getAddressError(state),
    (state: RootState) => getDistrictError(state),
    (state: RootState) => getReferenceError(state),
    (state: RootState) => getPaymentMethodError(state),
  ],
  (
    serviceTypeError,
    itemsError,
    phoneError,
    nameError,
    cpfError,
    vehicleModelError,
    vehicleColorError,
    vehiclePlateError,
    deliveryTimeError,
    selectedLocationError,
    addressError,
    districtError,
    referenceError,
    paymentMethodError,
  ) =>
    [
      { field: 'cart-service-type', error: serviceTypeError },
      { field: 'cart-items', error: itemsError },
      { field: 'cart-name', error: nameError },
      { field: 'cart-phone-number', error: phoneError },
      { field: 'cart-cpf', error: cpfError },
      { field: 'cart-vehicle-model', error: vehicleModelError },
      { field: 'cart-vehicle-color', error: vehicleColorError },
      { field: 'cart-vehicle-plate', error: vehiclePlateError },
      { field: 'cart-delivery-time', error: deliveryTimeError },
      { field: 'cart-selected-location', error: selectedLocationError },
      { field: 'cart-address', error: addressError },
      { field: 'cart-district', error: districtError },
      { field: 'cart-reference', error: referenceError },
      { field: 'cart-payment-method', error: paymentMethodError },
    ].filter(({ error }) => error !== undefined) as Array<{
      field: string
      error: string
    }>,
)
