import { ListItemTransition } from '@keffs/core/src/components/list-item-transition'
import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'

import { getItems } from '../redux/selectors'
import { CartItem } from './cart-item'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    userSelect: 'none',
    minHeight: 120,
  },
})

export const CartItems = memo(() => {
  const classes = useStyles()

  const items = useSelector(getItems)

  return (
    <div className={classes.root}>
      <List id="cart-items">
        <TransitionGroup>
          {items
            .slice()
            .reverse()
            .map((item, index) => (
              <ListItemTransition key={item.id} in>
                <CartItem
                  isLastInList={index === items.length - 1}
                  item={item}
                />
              </ListItemTransition>
            ))}
        </TransitionGroup>
      </List>
    </div>
  )
})
