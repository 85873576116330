import * as qss from 'qss'
import { compileParamsToPath } from 'rudy-match-path'

import { Payload, RoutesMap, Target } from '../types'

/**
 * Converts a payload object to a params object. Difference is that
 * the params object has all values serialized.
 *
 * @param payload The original payload object.
 *
 * @returns The resulting params object.
 */
const payloadToParams = (payload: Payload): Record<string, any> => {
  return Object.keys(payload).reduce((acc, key) => {
    const payloadValue = payload[key]

    if (typeof payloadValue === 'number') {
      return {
        ...acc,
        [key]: String(payloadValue),
      }
    }

    return {
      ...acc,
      [key]: payloadValue,
    }
  }, {})
}

/**
 * Serializes a target object into an internal URL.
 *
 * @param target The target object.
 * @param routesMap The object that maps route names to path patterns.
 *
 * @returns The serialized URL.
 */
export const targetToUrl = (target: Target, routesMap: RoutesMap): string => {
  const pathPattern = routesMap[target.name]
  if (pathPattern == null) {
    throw new Error(
      `No registered path pattern found with name: ${target.name}`,
    )
  }

  const params = payloadToParams(target.payload || {})
  const compiledPath = compileParamsToPath(pathPattern, params) || '/'

  if (target.query) {
    return `${compiledPath}?${qss.encode(target.query)}`
  }

  return compiledPath
}
