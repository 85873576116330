import { Fade } from '@material-ui/core'
import React, { useEffect } from 'react'
import ReactSwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import './bottom-sheet.css'

const MAX_WIDTH = 500
const OVERFLOW_HEIGHT = 100

const bodyStyle = {
  borderTopRightRadius: 4,
  borderTopLeftRadius: 4,
  background: '#fafafa',
}

const style = {
  marginRight: 'auto',
  marginLeft: 'auto',
  maxWidth: MAX_WIDTH,
}

const swipeableProps = {
  containerStyle: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
}

export type BottomSheetProps = {
  children?: React.ReactNode
  open: boolean
  visible: boolean
  onChange: (open: boolean) => void
}

export const BottomSheet = ({
  children,
  open,
  visible,
  onChange,
}: BottomSheetProps): React.ReactElement => {
  useEffect(() => {
    // Show/hide <body> scrollbar
    document.body.classList.toggle('BottomSheet--open', visible && open)
  })

  return (
    <Fade in={visible} timeout={400}>
      <div id="bottom-sheet">
        <ReactSwipeableBottomSheet
          bodyStyle={bodyStyle}
          onChange={onChange}
          open={open}
          overflowHeight={visible ? OVERFLOW_HEIGHT : 0}
          style={style}
          swipeableViewsProps={swipeableProps}
        >
          {children}
        </ReactSwipeableBottomSheet>
      </div>
    </Fade>
  )
}
