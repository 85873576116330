import { call, delay, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'

const scrollToTop = () => {
  try {
    window.scroll({ top: 0, behavior: 'smooth' })
  } catch (error) {
    console.log("Couldn't scroll to top")
  }
}

export function* scrollUpOnOrderSubmitted() {
  while (true) {
    yield take(getType(actions.submitOrderSuccess))

    // Delay just a little bit (to wait for bottom sheet to close)
    yield delay(100)

    yield call(scrollToTop)
  }
}
