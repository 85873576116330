import { ItemDialog } from '@keffs/core/src/components/item-dialog'
import { Combo } from '@keffs/data/src/types'
import React, { memo, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'

import { ServicesContext } from '../contexts'
import * as actions from '../redux/actions'
import { ROUTE_HOME } from '../redux/routes'
import { getCombosMapBySlug, getProductsMap } from '../redux/selectors'
import { CartComboItemWithoutPricing } from '../redux/types'

export type ComboDialogProps = {
  slug: string | null
}

export const ComboDialog = memo(({ slug }: ComboDialogProps) => {
  const dispatch = useDispatch()
  const productsMap = useSelector(getProductsMap)
  const combosMapBySlug = useSelector(getCombosMapBySlug)

  const { analyticsService } = useContext(ServicesContext)

  // Keep a copy to have it available even when closing the dialog
  const instanceCombo = useRef<Combo | null>(null)
  const currentCombo = combosMapBySlug[slug!]
  instanceCombo.current = currentCombo || instanceCombo.current
  const combo = instanceCombo.current

  if (combo == null) {
    return null
  }

  // Calculate the initial form values
  const initialValues = {
    choices: combo.items.map(comboItem => {
      const hasMultipleOptions = comboItem.options.length > 1
      return hasMultipleOptions ? '' : comboItem.options[0].productId
    }),
    notes: '',
    quantity: 1,
  }

  const handleEntered = () => {
    // Track
    analyticsService.productDetailsViewed({
      productId: combo.id,
      productCode: `C${combo.code}`,
      productName: combo.name,
      productPrice: combo.unitPrice,
      categoryName: 'Combo',
    })
  }

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleSubmit = ({
    choices,
    notes,
    quantity,
  }: {
    choices?: string[]
    notes: string | null
    quantity: number
  }) => {
    const newItem: CartComboItemWithoutPricing = {
      id: uuid(),
      comboId: combo.id,
      choosenItems: choices!.map((productId, index) => ({
        id: uuid(),
        productId,
        quantity: combo.items[index].quantity,
        ...(notes ? { notes } : {}),
      })),
      quantity,
      ...(notes ? { notes } : {}),
    }

    dispatch(actions.addItem(newItem))
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  return (
    <ItemDialog
      open={Boolean(currentCombo)}
      image={combo.image}
      name={combo.name}
      choices={combo.items.map(item => ({
        id: item.title!,
        title: item.title!,
        options: item.options
          .filter(option => productsMap[option.productId].active)
          .map(option => ({
            id: option.productId,
            title: productsMap[option.productId].name,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
          })),
      }))}
      initialValues={initialValues}
      onEntered={handleEntered}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
})
