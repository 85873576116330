const plateRegex = /\w{3}-\d{4}/

export const validateVehiclePlate = (
  value: string | undefined,
): string | undefined => {
  if (!value) {
    return 'Necessário informar a placa do veículo'
  }

  if (!plateRegex.test(value)) {
    return 'Placa inválida'
  }

  return undefined
}
