import { validateVehicleModel } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from './get-service-type'
import { getVehicleModel } from './get-vehicle-model'

export const getVehicleModelError = createSelector(
  (state: RootState) => getVehicleModel(state),
  (state: RootState) => getServiceType(state),

  (vehicleModel, serviceType) => {
    if (serviceType === 'driveBy') {
      return validateVehicleModel(vehicleModel)
    }

    return undefined
  },
)
