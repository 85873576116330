import { InputAdornment, TextField } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import React from 'react'
import ReactCurrencyInput from 'react-currency-input'
import { Overwrite } from 'utility-types'

const CurrencyInput = ({ inputRef, onChange, value, ...rest }: any) => {
  return (
    <ReactCurrencyInput
      {...rest}
      decimalSeparator=","
      inputType="tel"
      onChangeEvent={(e: any, formatted: string, parsed: number) =>
        onChange(e, formatted, parsed)
      }
      selectAllOnFocus
      thousandSeparator="."
      value={value}
    />
  )
}

export type CurrencyFieldProps = Overwrite<
  StandardTextFieldProps,
  {
    onChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      formatted: string,
      parsed: number,
    ) => void
  }
>

export const CurrencyField = (
  props: CurrencyFieldProps,
): React.ReactElement => {
  return (
    <TextField
      type="tel"
      InputProps={{
        inputComponent: CurrencyInput,
        startAdornment: (
          <InputAdornment position="start">R$&nbsp;</InputAdornment>
        ),
        ...(props.InputProps ? props.InputProps : {}),
      }}
      {...(props as any)}
    />
  )
}
