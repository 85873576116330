import { call, cancel, select, takeLatest } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import {
  getCombosMap,
  getItems,
  getProductCategoriesMap,
  getProductsMap,
  getServiceType,
} from '../../selectors'
import { redactCart } from './utils'

export function* checkoutInitiated(analyticsService: AnalyticsService) {
  const initialServiceType: ReturnType<typeof getServiceType> = yield select(
    getServiceType,
  )

  const task: any = yield takeLatest(
    getType(actions.setServiceType),
    function* () {
      // We only proceed to track if initial serviceType was empty. If it
      // wasn't, then checkout was already initiated before.
      if (initialServiceType) {
        return
      }

      const items: ReturnType<typeof getItems> = yield select(getItems)
      const combosMap: ReturnType<typeof getCombosMap> = yield select(
        getCombosMap,
      )
      const productsMap: ReturnType<typeof getProductsMap> = yield select(
        getProductsMap,
      )
      const categoriesMap: ReturnType<typeof getProductCategoriesMap> = yield select(
        getProductCategoriesMap,
      )

      // Track
      yield call([analyticsService, analyticsService.checkoutInitiated], {
        cart: redactCart(items, categoriesMap, productsMap, combosMap),
      })

      // We wanted to track only once, so stop watching
      yield cancel(task)
    },
  )
}
