import { validateCpf } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getCpf } from './get-cpf'

export const getCpfError = createSelector(
  (state: RootState) => getCpf(state),

  cpf => {
    // CPF is optional
    if (!cpf) {
      return undefined
    }

    return validateCpf(cpf)
  },
)
