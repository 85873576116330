import { CurrencyField } from '@keffs/core/src/components/currency-field'
import { computeMaxChangeFor } from '@keffs/data/src/computed'
import { formatCurrency } from '@keffs/formatters'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { Form, Formik, FormikProps } from 'formik'
import React, { memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import * as actions from '../redux/actions'
import {
  ROUTE_CART,
  ROUTE_CART_CHANGE_FOR,
  ROUTE_CART_PAYMENT_METHOD,
} from '../redux/routes'
import {
  getChangeFor,
  getPaymentMethod,
  getPaymentMethodError,
  getPaymentMethodTouched,
  getRoute,
  getTotalPrice,
} from '../redux/selectors'

type FormValues = {
  changeFor: number
}

export const CartPaymentMethod = memo(() => {
  const dispatch = useDispatch()
  const route = useSelector(getRoute)
  const paymentMethod = useSelector(getPaymentMethod)
  const paymentMethodError = useSelector(getPaymentMethodError)
  const paymentMethodTouched = useSelector(getPaymentMethodTouched)
  const changeFor = useSelector(getChangeFor)
  const totalPrice = useSelector(getTotalPrice)

  const initialValues: FormValues = {
    changeFor: changeFor ?? 0,
  }

  const validationSchema = useMemo(() => {
    return Yup.object({
      changeFor: Yup.mixed()
        .test(
          'gteTotalPrice',
          'Não pode ser menor que o total do pedido',
          value => value >= totalPrice,
        )
        .test(
          'excessiveChange',
          'Troco muito alto',
          value => value <= computeMaxChangeFor(totalPrice),
        ),
    })
  }, [totalPrice])

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_CART }))
  }

  const handleCashClick = () => {
    dispatch(actions.push({ name: ROUTE_CART_PAYMENT_METHOD }))
  }

  const handleCardClick = () => {
    dispatch(actions.setPaymentMethod({ method: 'card' }))
  }

  const handleCashWithoutChangeClick = () => {
    dispatch(actions.setPaymentMethod({ method: 'cash', changeFor: null }))
    dispatch(actions.goBack({ name: ROUTE_CART }))
  }

  const handleCashWithChangeClick = () => {
    dispatch(actions.replace({ name: ROUTE_CART_CHANGE_FOR }))
  }

  const handleCashWithChangeSubmit = ({ changeFor }: FormValues) => {
    dispatch(
      actions.setPaymentMethod({
        method: 'cash',
        changeFor,
      }),
    )
    dispatch(actions.goBack({ name: ROUTE_CART }))
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        Forma de pagamento
      </Typography>

      <FormControl error={Boolean(paymentMethodTouched && paymentMethodError)}>
        <RadioGroup id="cart-payment-method" value={paymentMethod || ''}>
          <FormControlLabel
            control={<Radio color="primary" onClick={handleCashClick} />}
            label={
              'Em dinheiro' +
              (changeFor == null
                ? ''
                : ` (troco para: ${formatCurrency(changeFor)})`)
            }
            value="cash"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Trazer máquina (cartão ou Pix)"
            onClick={handleCardClick}
            value="card"
          />
        </RadioGroup>
        {paymentMethodTouched && paymentMethodError && (
          <FormHelperText>{paymentMethodError}</FormHelperText>
        )}
      </FormControl>

      <Dialog
        open={route.name === ROUTE_CART_PAYMENT_METHOD}
        disableRestoreFocus
        onClose={handleClose}
      >
        <List>
          <ListItem button onClick={handleCashWithoutChangeClick}>
            <ListItemText primary="Sem troco" />
          </ListItem>
          <ListItem button onClick={handleCashWithChangeClick}>
            <ListItemText primary="Trazer troco" />
          </ListItem>
        </List>
      </Dialog>

      <Dialog
        open={route.name === ROUTE_CART_CHANGE_FOR}
        onClose={handleClose}
        maxWidth="sm"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleCashWithChangeSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            setFieldValue,
            touched,
            values,
          }: FormikProps<FormValues>) => (
            <Form>
              <DialogTitle>Total: {formatCurrency(totalPrice)}</DialogTitle>
              <DialogContent>
                <CurrencyField
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  error={Boolean(touched.changeFor && errors.changeFor)}
                  fullWidth
                  helperText={touched.changeFor && errors.changeFor}
                  label="Troco para"
                  margin="dense"
                  name="changeFor"
                  onBlur={handleBlur}
                  onChange={(e, formatted, parsed) => setFieldValue('changeFor', parsed)} // prettier-ignore
                  value={values.changeFor}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit">Ok</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
})
