import { Discount } from '@keffs/data/src/types'

import { ApiService } from './api-service'

export class DiscountService extends ApiService {
  async fetchById(id: string): Promise<Discount | null> {
    return this.request('GET', `/discounts/${id}`)
  }

  async fetchAll(): Promise<Discount[]> {
    return this.request('GET', '/discounts')
  }

  async create(data: {
    channels: Discount['channels']
    name: Discount['name']
    code: Discount['code']
    type: Discount['type']
    value: Discount['value']
    start: Discount['start']
    end: Discount['end']
    entitledProductIds: Discount['entitledProductIds']
    prerequisiteSubtotal: Discount['prerequisiteSubtotal']
    maxUsesByCustomer: Discount['maxUsesByCustomer']
    maxUsesTotal: Discount['maxUsesTotal']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/discounts', data)
    return insertedId
  }

  async update(
    id: string,
    data: {
      channels?: Discount['channels']
      name?: Discount['name']
      code?: Discount['code']
      type?: Discount['type']
      value?: Discount['value']
      start?: Discount['start']
      end?: Discount['end']
      entitledProductIds: Discount['entitledProductIds']
      prerequisiteSubtotal?: Discount['prerequisiteSubtotal']
      maxUsesByCustomer?: Discount['maxUsesByCustomer']
      maxUsesTotal?: Discount['maxUsesTotal']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/discounts/${id}`, data)
  }

  async activate(id: string): Promise<void> {
    await this.request('PATCH', `/discounts/${id}/active`, { active: true })
  }

  async deactivate(id: string): Promise<void> {
    await this.request('PATCH', `/discounts/${id}/active`, { active: false })
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/discounts/${id}`)
  }
}
