import notificationSound from '@keffs/core/src/assets/sfx/notification.mp3'
import {
  AuthService,
  BannerService,
  ComboService,
  CustomerService,
  DiscountService,
  OrderService,
  ProductCategoryService,
  ProductService,
  RestaurantService,
} from '@keffs/core/src/services'
import { createTheme } from '@keffs/core/src/styles/mui-theme'
import { preventInputObscuredByKeyboard } from '@keffs/prevent-input-obscured-by-keyboard'
import { ScreenRecorder } from '@keffs/screen-recorder'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'
import Pusher from 'pusher-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import 'typeface-roboto'

import { App } from './app'
import { AppSnackbar } from './components/app-snackbar'
import { config } from './config'
import { ServicesContext } from './contexts'
import { configureStore } from './redux/configure-store'
import { AnalyticsService } from './services'

console.log(`Environment: ${process.env.NODE_ENV}`)
console.log(`Version: ${config.version}`)

// Setup Sentry as soon as possible
if (config.sentry.env && config.sentry.dsn) {
  Sentry.init({
    debug: config.debug,
    dsn: config.sentry.dsn,
    environment: config.sentry.env,
    release: config.version,
  })

  console.log('Sentry ready')
}

// Setup Google Analytics
if (config.google.analyticsId) {
  window.ga('create', config.google.analyticsId, 'auto')
  window.ga('require', 'ec')

  console.log('Google Analytics ready')
}

// Setup Facebook Pixel
if (config.facebook.pixelId) {
  window.fbq('init', config.facebook.pixelId)
  window.fbq('track', 'PageView')

  console.log('Facebook Pixel ready')
}

// Setup Firebase
firebase.initializeApp({
  apiKey: config.firebase.apiKey,
  appId: config.firebase.appId,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseUrl,
  messagingSenderId: config.firebase.messagingSenderId,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
})

firebase.firestore().settings({
  ignoreUndefinedProperties: true,
})

console.log('Firebase ready')

// Setup screen recorder
ScreenRecorder.init({
  appVersion: config.version,
  projectId: config.firebase.projectId,
})

console.log('Screen recorder ready')

// Setup Firebase Cloud Messaging
try {
  firebase.messaging().usePublicVapidKey(config.firebase.vapidKey)
  firebase.messaging().onMessage(payload => {
    const audio = new Audio(notificationSound)
    audio.play().catch(() => console.log('Audio not played'))
  })

  console.log('Firebase Cloud Messaging ready')
} catch (error) {
  if (error.code === 'messaging/unsupported-browser') {
    console.warn('Supressed error: ', error)
  } else {
    throw error
  }
}

// Setup theme
const theme = createTheme({ narrowerBreakpoints: true })

// Setup services
const analyticsService = new AnalyticsService({ debug: config.debug })
const authService = new AuthService()
const bannerService = new BannerService(config.api.baseUrl)
const comboService = new ComboService(config.api.baseUrl)
const customerService = new CustomerService(config.api.baseUrl)
const discountService = new DiscountService(config.api.baseUrl)
const orderService = new OrderService(config.api.baseUrl)
const productCategoryService = new ProductCategoryService(config.api.baseUrl)
const productService = new ProductService(config.api.baseUrl)
const restaurantService = new RestaurantService(config.api.baseUrl)

// Setup Pusher
const pusher = new Pusher(config.pusher.key, {
  authEndpoint: `${config.api.baseUrl}/pusher/auth`,
  cluster: config.pusher.cluster,
  encrypted: true,
})

// Setup Redux
const store = configureStore({
  analyticsService,
  authService,
  bannerService,
  comboService,
  customerService,
  discountService,
  orderService,
  productCategoryService,
  productService,
  restaurantService,
  pusher,
})

preventInputObscuredByKeyboard()

ReactDOM.render(
  <CssBaseline>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <ServicesContext.Provider
            value={{
              analyticsService,
              authService,
              bannerService,
              comboService,
              customerService,
              discountService,
              orderService,
              productCategoryService,
              productService,
              restaurantService,
              pusher,
            }}
          >
            <App />
          </ServicesContext.Provider>
          <AppSnackbar />
        </ReduxProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </CssBaseline>,
  document.getElementById('root'),
)
