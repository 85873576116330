/**
 * Given the seconds, returns the representation in H:mm:ss.
 *
 * @param totalSeconds The number of seconds.
 *
 * @returns A string representation in m:ss or H:mm:ss.
 *
 * @example
 * formatCountdown(3) // => '0:03'
 * formatCountdown(-63) // => '-1:03'
 */
export const formatCountdown = (totalSeconds: number): string => {
  const totalAbsSeconds = Math.abs(totalSeconds)

  const seconds = Math.floor(totalAbsSeconds % 60)
  const minutes = Math.floor(totalAbsSeconds / 60) % 60
  const hours = Math.floor(totalAbsSeconds / 3600)

  if (hours > 0) {
    return (
      (totalSeconds < 0 ? '-' : '') +
      String(hours) +
      ':' +
      String(minutes).padStart(2, '0') +
      ':' +
      String(seconds).padStart(2, '0')
    )
  }

  return (
    (totalSeconds < 0 ? '-' : '') +
    String(minutes) +
    ':' +
    String(seconds).padStart(2, '0')
  )
}
