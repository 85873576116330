import {
  AuthService,
  BannerService,
  ComboService,
  CustomerService,
  DiscountService,
  OrderService,
  ProductCategoryService,
  ProductService,
  RestaurantService,
} from '@keffs/core/src/services'
import { Pusher } from 'pusher-js'
import { call, fork } from 'redux-saga/effects'

import { AnalyticsService } from '../../services'
import { routesMap } from '../routes'
import { analyticsSaga } from './analytics'
import { authSaga } from './auth'
import { cartSaga } from './cart'
import { clockSaga } from './clock'
import { fetchInitialData } from './fetch-initial-data'
import { routerSaga } from './router'
import { subscribeToCustomerOrders } from './subscribe-to-customer-orders'
import { watchNotificationsPermission } from './watch-notifications-permission'

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

type Dependencies = {
  analyticsService: AnalyticsService
  authService: AuthService
  bannerService: BannerService
  comboService: ComboService
  customerService: CustomerService
  discountService: DiscountService
  orderService: OrderService
  productCategoryService: ProductCategoryService
  productService: ProductService
  restaurantService: RestaurantService
  pusher: Pusher
}

export function* rootSaga({
  analyticsService,
  authService,
  bannerService,
  comboService,
  customerService,
  discountService,
  orderService,
  productCategoryService,
  productService,
  restaurantService,
  pusher,
}: Dependencies) {
  // Initialize router first of all (but fork because it'll run forever)
  yield fork(routerSaga, routesMap)

  // Then block until initial data is ready
  yield call(
    fetchInitialData,
    bannerService,
    comboService,
    productCategoryService,
    productService,
    restaurantService,
  )

  // Then proceed
  yield fork(analyticsSaga, analyticsService)
  yield fork(authSaga, authService, customerService)
  yield fork(cartSaga, discountService, orderService)
  yield fork(clockSaga, FIVE_MINUTES_IN_MS)

  yield fork(subscribeToCustomerOrders, orderService, pusher)
  yield fork(watchNotificationsPermission, analyticsService)
}
