import { ItemDialog } from '@keffs/core/src/components/item-dialog'
import { Product } from '@keffs/data/src/types'
import React, { memo, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'uuid/v4'

import { ServicesContext } from '../contexts'
import * as actions from '../redux/actions'
import { ROUTE_HOME } from '../redux/routes'
import {
  getProductCategoriesMap,
  getProductsMapBySlug,
} from '../redux/selectors'
import { CartProductItemWithoutPricing } from '../redux/types'

export type ProductDialogProps = {
  slug: string | null
}

export const ProductDialog = memo(({ slug }: ProductDialogProps) => {
  const dispatch = useDispatch()
  const productsMapBySlug = useSelector(getProductsMapBySlug)
  const categoriesMap = useSelector(getProductCategoriesMap)

  const { analyticsService } = useContext(ServicesContext)

  // Keep a copy to have it available even when closing the dialog
  const instanceProduct = useRef<Product | null>(null)
  const currentProduct = productsMapBySlug[slug!]
  instanceProduct.current = currentProduct || instanceProduct.current
  const product = instanceProduct.current

  if (product == null) {
    return null
  }

  // Calculate the initial form values
  const initialValues = {
    notes: '',
    quantity: 1,
  }

  const handleEntered = () => {
    // Track
    const category = categoriesMap[product.categoryId]
    analyticsService.productDetailsViewed({
      productId: product.id,
      productCode: `P${product.code}`,
      productName: product.name,
      productPrice: product.unitPrice,
      categoryName: category.name,
    })
  }

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleSubmit = ({
    quantity,
    notes,
  }: {
    quantity: number
    notes: string | null
  }) => {
    const newItem: CartProductItemWithoutPricing = {
      id: uuid(),
      productId: product.id,
      quantity,
      ...(notes ? { notes } : {}),
    }

    dispatch(actions.addItem(newItem))
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  return (
    <ItemDialog
      open={Boolean(currentProduct)}
      image={product.image}
      name={product.name}
      description={product.description || undefined}
      allowNotes={categoriesMap[product.categoryId].allowNotes}
      initialValues={initialValues}
      onEntered={handleEntered}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
})
