import { ItemDialog } from '@keffs/core/src/components/item-dialog'
import React, { memo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import { ROUTE_HOME } from '../redux/routes'
import {
  getItems,
  getProductCategoriesMap,
  getProductsMap,
} from '../redux/selectors'
import { CartProductItemWithoutPricing } from '../redux/types'

export type EditProductDialogProps = {
  id: string | null
}

export const EditProductDialog = memo(({ id }: EditProductDialogProps) => {
  const dispatch = useDispatch()
  const items = useSelector(getItems)
  const productsMap = useSelector(getProductsMap)
  const categoriesMap = useSelector(getProductCategoriesMap)

  // Keep a copy to have it available even when closing the dialog
  const instanceItem = useRef<CartProductItemWithoutPricing | null>(null)
  const currentItem = items.find(i => i.id === id) || {}
  const currentProductItem = 'productId' in currentItem ? currentItem : null
  instanceItem.current = currentProductItem || instanceItem.current
  const item = instanceItem.current

  if (item == null) {
    return null
  }

  // Calculate the initial form values
  const initialValues = {
    notes: item.notes || '',
    quantity: item.quantity,
  }

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleRemove = () => {
    dispatch(actions.removeItem(item.id))
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleSubmit = ({
    quantity,
    notes,
  }: {
    quantity: number
    notes: string | null
  }) => {
    const newItem: CartProductItemWithoutPricing = {
      id: item.id,
      productId: item.productId,
      quantity,
      ...(notes ? { notes } : {}),
    }

    dispatch(actions.updateItem(newItem))
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  return (
    <ItemDialog
      open={Boolean(currentProductItem)}
      editing
      image={productsMap[item.productId].image}
      name={productsMap[item.productId].name}
      allowNotes={
        categoriesMap[productsMap[item.productId].categoryId].allowNotes
      }
      initialValues={initialValues}
      onClose={handleClose}
      onRemove={handleRemove}
      onSubmit={handleSubmit}
    />
  )
})
