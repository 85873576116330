import { isIsoDate } from '@keffs/data/src/utils'
import axios from 'axios'

export abstract class ApiService {
  protected readonly baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  protected async request(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    url: string,
    data?: unknown,
  ): Promise<any> {
    const res = await axios({
      method,
      baseURL: this.baseUrl,
      url,
      data,
      transformResponse: body => {
        if (body === '') {
          return null
        }

        return JSON.parse(body, (key, value) =>
          isIsoDate(value) ? new Date(value) : value,
        )
      },
    })

    return res.data
  }
}
