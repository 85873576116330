import { ScreenRecorder } from '@keffs/screen-recorder'
import { take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../../actions'

export function* screenRecorderUser() {
  while (true) {
    const action: ActionType<typeof actions.signInSuccess> = yield take(
      getType(actions.signInSuccess),
    )

    const uid = action.payload.id
    ScreenRecorder.linkUser(uid)
  }
}
