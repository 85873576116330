import { Avatar, SnackbarContent, Typography } from '@material-ui/core'
import {
  CheckCircle as MdCheckCircle,
  Warning as MdWarning,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React from 'react'

type Variant = 'normal' | 'success' | 'error'

const useStyles = makeStyles({
  avatar: {
    marginTop: -4,
    marginRight: 12,
    marginBottom: -4,
    marginLeft: -12,
    width: 36,
    height: 36,
  },
  icon: {
    marginTop: -4,
    marginRight: 12,
    marginBottom: -4,
    marginLeft: -12,
    width: 32,
    height: 32,
  },
  success: {
    backgroundColor: '#4CAF50',
  },
  error: {
    backgroundColor: '#D32F2F',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

export type CustomSnackbarContentProps = {
  avatar?: string
  primary: React.ReactNode
  secondary: React.ReactNode
  variant?: Variant
}

export const CustomSnackbarContent = ({
  avatar,
  primary,
  secondary,
  variant = 'normal' as const,
}: CustomSnackbarContentProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <SnackbarContent
      className={classNames({
        [classes.success]: variant === 'success',
        [classes.error]: variant === 'error',
      })}
      message={
        <div className={classes.message}>
          {variant === 'success' ? (
            <MdCheckCircle className={classes.icon} />
          ) : variant === 'error' ? (
            <MdWarning className={classes.icon} />
          ) : null}

          {avatar && (
            <Avatar
              alt=""
              className={classes.avatar}
              src={avatar.length > 2 ? avatar : undefined}
            >
              {avatar.length <= 2 ? avatar : undefined}
            </Avatar>
          )}
          <div>
            <Typography color="inherit">{primary}</Typography>
            <Typography color="inherit" variant="caption">
              {secondary}
            </Typography>
          </div>
        </div>
      }
    />
  )
}
