import { Combo } from '@keffs/data/src/types'
import keyBy from 'lodash/keyBy'
import { getType } from 'typesafe-actions'

import { combosLoaded } from '../actions'
import { RootAction } from '../types'

export type State = {
  readonly loading: boolean
  readonly data: { [key: string]: Combo }
}

export const initialState: State = {
  loading: true,
  data: {},
}

export const combosReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(combosLoaded): {
      return {
        ...state,
        loading: false,
        data: keyBy(action.payload, 'id'),
      }
    }

    default: {
      return state
    }
  }
}
