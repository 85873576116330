import { Restaurant } from '@keffs/data/src/types'
import { getType } from 'typesafe-actions'

import { restaurantLoaded } from '../actions'
import { RootAction } from '../types'

export type State = {
  readonly loading: boolean
  readonly data: Restaurant | null
}

export const initialState: State = {
  loading: false,
  data: null,
}

export const restaurantReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(restaurantLoaded): {
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
