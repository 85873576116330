import { call, select, take } from 'redux-saga/effects'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import { getRoute } from '../../selectors'

export function* pageVisited(analyticsService: AnalyticsService) {
  // Track initial location
  const initialRoute: ReturnType<typeof getRoute> = yield select(getRoute)
  yield call([analyticsService, analyticsService.pageVisited], {
    pathname: initialRoute.pathname,
  })

  // Track subsequent changes...
  while (true) {
    const action: ReturnType<typeof actions.routeChanged> = yield take(
      actions.routeChanged,
    )

    const route = action.payload

    yield call([analyticsService, analyticsService.pageVisited], {
      pathname: route.pathname,
    })
  }
}
