import { validateVehiclePlate } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from './get-service-type'
import { getVehiclePlate } from './get-vehicle-plate'

export const getVehiclePlateError = createSelector(
  (state: RootState) => getVehiclePlate(state),
  (state: RootState) => getServiceType(state),

  (vehiclePlate, serviceType) => {
    if (serviceType === 'driveBy') {
      return validateVehiclePlate(vehiclePlate)
    }

    return undefined
  },
)
