import { call, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'

export function* cartDiscountApplied(analyticsService: AnalyticsService) {
  // On discount applied to the cart...
  while (true) {
    const action: ActionType<typeof actions.applyDiscount> = yield take(
      getType(actions.applyDiscount),
    )

    const discount = action.payload

    // Track
    yield call([analyticsService, analyticsService.discountApplied], {
      discountType: discount.type,
      discountValue: discount.value,
    })
  }
}
