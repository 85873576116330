import { eventChannel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'

import { AnalyticsService } from '../../services'
import * as actions from '../actions'

const setupPermissionsEventChannel = async () => {
  const name = 'notifications'

  const permission = await navigator.permissions
    .query({ name })
    .catch(() => null)

  return eventChannel(emit => {
    if (!permission) {
      return () => null
    }

    // Emit initial (don't know why, but without setTimeout, emitted
    // action isn't taken)
    setTimeout(() => {
      emit(actions.permissionChanged({ name, state: permission.state }))
    })

    // Setup handler
    const handler = () => {
      emit(actions.permissionChanged({ name, state: permission.state }))
    }

    // Subscribe to changes
    permission.addEventListener('change', handler)

    // Return unsubscriber
    return () => permission.removeEventListener('change', handler)
  })
}

export function* watchNotificationsPermission(
  analyticsService: AnalyticsService,
) {
  // Permissions API unavaible? Abort
  if (!('permissions' in navigator) || !navigator.permissions) {
    return null
  }

  // Watch and dispatch
  const permissionsEventChannel = yield call(setupPermissionsEventChannel)
  while (true) {
    const action = yield take(permissionsEventChannel)

    yield put(action)
  }
}
