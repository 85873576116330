import { RootState } from '../../types'

export const getOpeningHours = (
  state: RootState,
): [string[], string[], string[], string[], string[], string[], string[]] => {
  if (state.restaurant.data) {
    return state.restaurant.data.openingHours
  }

  // Default: 7 empty days
  return [[], [], [], [], [], [], []]
}
