import { Combo } from '@keffs/data/src/types'

import { ApiService } from './api-service'

export class ComboService extends ApiService {
  async fetchById(id: string): Promise<Combo | null> {
    return this.request('GET', `/combos/${id}`)
  }

  async fetchAll(): Promise<Combo[]> {
    return this.request('GET', '/combos')
  }

  async create(data: {
    channels: Combo['channels']
    name: Combo['name']
    slug: Combo['slug']
    image: Combo['image']
    items: Combo['items']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/combos', data)
    return insertedId
  }

  async update(
    id: string,
    data: {
      channels?: Combo['channels']
      name?: Combo['name']
      slug?: Combo['slug']
      image?: Combo['image']
      items?: Combo['items']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/combos/${id}`, data)
  }

  async activate(id: string): Promise<void> {
    await this.request('PATCH', `/combos/${id}/active`, { active: true })
  }

  async deactivate(id: string): Promise<void> {
    await this.request('PATCH', `/combos/${id}/active`, { active: false })
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/combos/${id}`)
  }
}
