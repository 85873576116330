import { Customer, CustomerLocation } from '@keffs/data/src/types'
import { encode } from 'qss'

import { ApiService } from './api-service'

export class CustomerService extends ApiService {
  async fetchById(id: string): Promise<Customer | null> {
    return this.request('GET', `/customers/${id}`)
  }

  async fetchByFirebaseUid(firebaseUid: string): Promise<Customer | null> {
    const [customer] = await this.request(
      'GET',
      '/customers?' + encode({ 'firebase-uid': firebaseUid }),
    )
    return customer
  }

  async fetchAll(
    query: { limit?: number; offset?: number } = {},
  ): Promise<Customer[]> {
    return this.request('GET', '/customers?' + encode(query))
  }

  async create(data: {
    firebaseUid: Customer['firebaseUid']
    displayName: Customer['displayName']
    email: Customer['email']
    phoneNumber: Customer['phoneNumber']
    photoUrl: Customer['photoUrl']
    cpf: Customer['cpf']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/customers', data)
    return insertedId
  }

  async update(
    id: string,
    data: {
      firebaseUid?: Customer['firebaseUid']
      displayName?: Customer['displayName']
      email?: Customer['email']
      phoneNumber?: Customer['phoneNumber']
      photoUrl?: Customer['photoUrl']
      cpf?: Customer['cpf']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/customers/${id}`, data)
  }

  async block(id: string): Promise<void> {
    await this.request('PATCH', `/customers/${id}/blocked`, { blocked: true })
  }

  async unblock(id: string): Promise<void> {
    await this.request('PATCH', `/customers/${id}/blocked`, { blocked: false })
  }

  async addFcmToken(id: string, token: string): Promise<void> {
    await this.request('POST', `/customers/${id}/fcm-tokens`, { token })
  }

  async removeFcmToken(id: string, token: string): Promise<void> {
    await this.request('DELETE', `/customers/${id}/fcm-tokens/${token}`)
  }

  async addLocation(
    id: string,
    data: {
      address: CustomerLocation['address']
      district: CustomerLocation['district']
      city: CustomerLocation['city']
      reference: CustomerLocation['reference']
      coordinates: CustomerLocation['coordinates']
    },
  ): Promise<void> {
    await this.request('POST', `/customers/${id}/locations`, data)
  }

  async updateLocation(
    id: string,
    locationId: string,
    data: {
      address: CustomerLocation['address']
      district: CustomerLocation['district']
      city: CustomerLocation['city']
      reference: CustomerLocation['reference']
      coordinates: CustomerLocation['coordinates']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request(
      'PATCH',
      `/customers/${id}/locations/${locationId}`,
      data,
    )
  }

  async deleteLocation(id: string, locationId: string): Promise<void> {
    await this.request('DELETE', `/customers/${id}/locations/${locationId}`)
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/customers/${id}`)
  }
}
