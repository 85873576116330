import { Banner, Channel } from '@keffs/data/src/types'
import { encode } from 'qss'

import { ApiService } from './api-service'

export class BannerService extends ApiService {
  async fetchById(id: string): Promise<Banner | null> {
    return this.request('GET', `/banners/${id}`)
  }

  async fetchAll({ channel }: { channel?: Channel } = {}): Promise<Banner[]> {
    return this.request('GET', '/banners?' + encode({ channel }))
  }

  async create(data: {
    channels: Banner['channels']
    name: Banner['name']
    href: Banner['href']
    imageNarrow: Banner['imageNarrow']
    imageMedium: Banner['imageMedium']
    imageWide: Banner['imageWide']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/banners', data)
    return insertedId
  }

  async update(
    id: string,
    data: {
      channels?: Banner['channels']
      name?: Banner['name']
      href?: Banner['href']
      imageNarrow?: Banner['imageNarrow']
      imageMedium?: Banner['imageMedium']
      imageWide?: Banner['imageWide']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/banners/${id}`, data)
  }

  async activate(id: string): Promise<void> {
    await this.request('PATCH', `/banners/${id}/active`, { active: true })
  }

  async deactivate(id: string): Promise<void> {
    await this.request('PATCH', `/banners/${id}/active`, { active: false })
  }

  async setPosition(id: string, position: number): Promise<void> {
    await this.request('PATCH', `/banners/${id}/position`, { position })
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/banners/${id}`)
  }
}
