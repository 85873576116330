import { call, select, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import { getNotificationsPermission } from '../../selectors'

export function* webPushEnabled(analyticsService: AnalyticsService) {
  // Is the permission granted during initialization?
  const initialNotificationsPermission: ReturnType<typeof getNotificationsPermission> = yield select(
    getNotificationsPermission,
  )

  if (initialNotificationsPermission === 'granted') {
    yield call([analyticsService, analyticsService.webPushEnabled])
    return
  }

  // Otherwise watch until permission is granted...
  while (true) {
    const action: ActionType<typeof actions.permissionChanged> = yield take(
      getType(actions.permissionChanged),
    )

    const name = action.payload.name
    const state = action.payload.state

    if (name === 'notifications' && state === 'granted') {
      yield call([analyticsService, analyticsService.webPushEnabled])
      return
    }
  }
}
