import { ItemDialog } from '@keffs/core/src/components/item-dialog'
import React, { memo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import { ROUTE_HOME } from '../redux/routes'
import { getCombosMap, getItems, getProductsMap } from '../redux/selectors'
import { CartComboItemWithoutPricing } from '../redux/types'

export type EditComboDialogProps = {
  id: string | null
}

export const EditComboDialog = memo(({ id }: EditComboDialogProps) => {
  const dispatch = useDispatch()
  const items = useSelector(getItems)
  const productsMap = useSelector(getProductsMap)
  const combosMap = useSelector(getCombosMap)

  // Keep a copy to have it available even when closing the dialog
  const instanceItem = useRef<CartComboItemWithoutPricing | null>(null)
  const currentItem = items.find(i => i.id === id) || {}
  const currentComboItem = 'comboId' in currentItem ? currentItem : null
  instanceItem.current = currentComboItem || instanceItem.current
  const item = instanceItem.current

  if (item == null) {
    return null
  }

  // Calculate the initial form values
  const initialValues = {
    choices: item.choosenItems.map(choosenItem => choosenItem.productId),
    notes: item.notes || '',
    quantity: item.quantity,
  }

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleRemove = () => {
    dispatch(actions.removeItem(item.id))
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleSubmit = ({
    choices,
    notes,
    quantity,
  }: {
    choices?: string[]
    notes: string | null
    quantity: number
  }) => {
    const updatedItem: CartComboItemWithoutPricing = {
      id: item.id,
      comboId: item.comboId,
      choosenItems: choices!.map((productId, index) => {
        const prevChoosenItem = item.choosenItems[index]
        return {
          id: prevChoosenItem.id,
          productId,
          quantity: prevChoosenItem.quantity,
          ...(notes ? { notes } : {}),
        }
      }),
      quantity,
      ...(notes ? { notes } : {}),
    }

    dispatch(actions.updateItem(updatedItem))
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  return (
    <ItemDialog
      open={Boolean(currentComboItem)}
      editing
      image={combosMap[item.comboId].image}
      name={combosMap[item.comboId].name}
      choices={combosMap[item.comboId].items.map(item => ({
        id: item.title!,
        title: item.title!,
        options: item.options
          .filter(option => productsMap[option.productId].active)
          .map(option => ({
            id: option.productId,
            title: productsMap[option.productId].name,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
          })),
      }))}
      initialValues={initialValues}
      onClose={handleClose}
      onRemove={handleRemove}
      onSubmit={handleSubmit}
    />
  )
})
