import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'

import logo from './assets/logo.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 32,
  },
  logo: {
    height: 80,
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
  },
}))

export const Logo = memo(() => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img alt="Logotipo" className={classes.logo} src={logo} />
    </div>
  )
})
