import { Customer, IdentityProvider } from '@keffs/data/src/types'
import { createStandardAction as action } from 'typesafe-actions'

export const authInitialized = action('auth/INITIALIZED')<Customer | null>()

export const signInAttempt = action('auth/SIGN_IN_ATTEMPT')<{
  provider: 'facebook' | 'google'
}>()
export const signInSuccess = action('auth/SIGN_IN_SUCCESS')<Customer>()
export const signInError = action('auth/SIGN_IN_ERROR')<string>()

export const signedUp = action('auth/SIGNED_UP')<{
  identityProvider: IdentityProvider
}>()

export const signOutAttempt = action('auth/SIGN_OUT_ATTEMPT')<void>()
export const signOutSuccess = action('auth/SIGN_OUT_SUCCESS')<void>()
export const signOutError = action('auth/SIGN_OUT_ERROR')<string>()
