import { formatCurrency } from '@keffs/formatters'

import { Discount } from '../types'

/**
 * Computes a title based on the discount properties.
 *
 * @param discount The discount.
 *
 * @returns The generated title.
 */
export const computeDiscountTitle = (discount: Discount): string => {
  if (discount.type === 'fixedValue') {
    return `${formatCurrency(discount.value, { prefix: 'R$' })} DE DESCONTO`
  }

  if (discount.type === 'percentage') {
    return `${discount.value}% DE DESCONTO`
  }

  if (discount.type === 'shipping') {
    return 'FRETE GRÁTIS'
  }

  throw new Error('Invalid discount type')
}
