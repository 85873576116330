import {
  BannerService,
  ComboService,
  ProductCategoryService,
  ProductService,
  RestaurantService,
} from '@keffs/core/src/services'
import { all, call, put } from 'redux-saga/effects'

import * as actions from '../actions'

export function* fetchInitialData(
  bannerService: BannerService,
  comboService: ComboService,
  productCategoryService: ProductCategoryService,
  productService: ProductService,
  restaurantService: RestaurantService,
): any {
  const [banners, combos, productCategories, products, restaurant] = yield all([
    call([bannerService, bannerService.fetchAll], { channel: 'website' }),
    call([comboService, comboService.fetchAll]),
    call([productCategoryService, productCategoryService.fetchAll]),
    call([productService, productService.fetchAll]),
    call([restaurantService, restaurantService.fetchTheRestaurant]),
  ])

  yield all([
    put(actions.bannersLoaded(banners)),
    put(actions.combosLoaded(combos)),
    put(actions.productCategoriesLoaded(productCategories)),
    put(actions.productsLoaded(products)),
    put(actions.restaurantLoaded(restaurant)),
  ])
}
