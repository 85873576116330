import delve from 'dlv'

/**
 * Merges similar items in an array.
 *
 * @param items The array of items to dedupe.
 * @param similarKeys The key (or array of keys) that must contain
 * similar values in two items to qualify them as similar.
 * @param mergeKey The name of the property from the two items that should
 * be added together to generate the merged item.
 *
 * @returns The deduped array.
 */
export const dedupe = <Item extends { [key: string]: unknown }>({
  items,
  similarKey,
  mergeKey,
}: {
  items: Item[]
  similarKey: string | string[]
  mergeKey: keyof Item
}): Item[] => {
  // Wrap in an array
  const similarKeys = Array.isArray(similarKey) ? similarKey : [similarKey]

  return items.reduce((acc: Item[], item: Item): Item[] => {
    const indexOfSimilar = acc.findIndex(otherItem => {
      return similarKeys.every(
        key => delve(otherItem, key) === delve(item, key),
      )
    })

    if (indexOfSimilar === -1) {
      // No similar found, push item to array
      return [...acc, item]
    } else {
      // Merge item to similar one already present in the array
      const mergedItem = {
        ...item,
        [mergeKey]:
          (acc[indexOfSimilar][mergeKey] as number) +
          (item[mergeKey] as number),
      }
      return [
        ...acc.slice(0, indexOfSimilar),
        mergedItem,
        ...acc.slice(indexOfSimilar + 1),
      ]
    }
  }, [])
}
