export const validateVehicleColor = (
  value: string | undefined,
): string | undefined => {
  if (!value) {
    return 'Necessário informar a cor'
  }

  if (value.length < 4) {
    return 'Muito curto'
  }

  return undefined
}
