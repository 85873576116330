import { UAParser } from 'ua-parser-js'

import { Event } from './types'
import { compressEvents, encodeValue, generateId } from './utils'

const SESSION_ID = generateId()
const SESSION_START = Date.now()

let config: { projectId: string } | null = null
let packetCount = 0

export const setConfig = (newConfig: typeof config): void => {
  config = newConfig
}

const getConfig = () => {
  if (!config) {
    throw new Error('Firebase config is missing')
  }

  return config
}

const getDocName = (path: string) => {
  const config = getConfig()
  return `projects/${config.projectId}/databases/(default)/documents/${path}`
}

const getSessionDocName = () => {
  return getDocName(`screenRecording.sessions/${SESSION_ID}`)
}

const getPacketDocName = (packetId: string) => {
  return getDocName(`screenRecording.sessions/${SESSION_ID}/packets/${packetId}`) // prettier-ignore
}

const commit = async ({
  keepalive,
  writes,
}: {
  keepalive?: boolean
  writes: Array<{ [key: string]: any }>
}) => {
  const config = getConfig()
  const url = `https://firestore.googleapis.com/v1/projects/${config.projectId}/databases/(default)/documents:commit`
  const res = await fetch(url, {
    method: 'POST',
    keepalive,
    body: JSON.stringify({
      writes,
    }),
  })

  if (!res.ok) {
    throw new Error(`Failed to commit: ${await res.text()}`)
  }
}

export const createSession = async ({
  appVersion,
}: {
  appVersion: string
}): Promise<void> => {
  const name = getSessionDocName()
  const { browser, device, os, ua } = new UAParser(navigator.userAgent).getResult() // prettier-ignore

  await commit({
    writes: [
      {
        update: {
          name,
          fields: {
            startedAt: encodeValue(SESSION_START),
            appVersion: encodeValue(appVersion),
            userIds: encodeValue([]),
            language: encodeValue(navigator.language),
            referrer: encodeValue(document.referrer),
            ua: encodeValue(ua),
            deviceVendor: encodeValue(device.vendor),
            deviceModel: encodeValue(device.model),
            deviceType: encodeValue(device.type),
            osName: encodeValue(os.name),
            osVersion: encodeValue(os.version),
            browserName: encodeValue(browser.name),
            browserVersion: encodeValue(browser.version),
            viewportWidth: encodeValue(window.innerWidth),
            viewportHeight: encodeValue(window.innerHeight),
          },
        },
      },
    ],
  })
}

export const addUserId = async (userId: string): Promise<void> => {
  const name = getSessionDocName()

  await commit({
    keepalive: true,
    writes: [
      {
        transform: {
          document: name,
          fieldTransforms: [
            {
              fieldPath: 'userIds',
              appendMissingElements: {
                values: [encodeValue(userId)],
              },
            },
          ],
        },
      },
    ],
  })
}

export const savePacket = async (
  trigger: 'time' | 'hidden',
  events: Event[],
): Promise<void> => {
  const packetId = String(packetCount++)
  const name = getPacketDocName(packetId)
  const compressedEvents = compressEvents(events)

  await commit({
    keepalive: trigger === 'hidden',
    writes: [
      {
        update: {
          name,
          fields: {
            trigger: encodeValue(trigger),
            timestamp: encodeValue(Date.now()),
            compressedEvents: encodeValue(compressedEvents),
          },
        },
      },
    ],
  })
}
