import { Order } from '@keffs/data/src/types'
import keyBy from 'lodash/keyBy'
import { getType } from 'typesafe-actions'

import {
  orderCreated,
  orderUpdated,
  ordersLoaded,
  signInSuccess,
  signOutSuccess,
} from '../actions'
import { RootAction } from '../types'

export type State = {
  readonly subscribed: boolean
  readonly data: { [key: string]: Order }
}

const initialState: State = {
  subscribed: false,
  data: {},
}

export const ordersReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(signInSuccess): {
      return { ...state, subscribed: true }
    }
    case getType(signOutSuccess): {
      return initialState
    }

    case getType(ordersLoaded): {
      return {
        ...state,
        data: keyBy(action.payload, 'id'),
      }
    }

    case getType(orderCreated):
    case getType(orderUpdated): {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}
