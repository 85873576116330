import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles({
  emoji: {
    width: 24,
    height: 24,
    verticalAlign: 'middle',
  },
})

export type EmojiProps = {
  src: string
}

export const Emoji = ({ src }: EmojiProps): React.ReactElement => {
  const classes = useStyles()

  return <img alt={`emoji ${src}`} className={classes.emoji} src={src} />
}
