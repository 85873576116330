export const env = <TDefault = string>(
  name: string,
  defaultValue?: TDefault,
): string | TDefault => {
  const value = process.env[name]

  if (value) {
    return value
  }

  if (defaultValue !== undefined) {
    return defaultValue
  }

  throw new Error(
    `Missing '${name}' environment variable. Check your '.env' file.`,
  )
}
