const PRECISION = 4

/**
 * Ensures a decimal number is safe from innacurate rounding caused by
 * the nature of binary systems which cannot always represent decimals
 * with full accuracy.
 *
 * @param value The number that may have innacurate rounding.
 *
 * @returns The safe decimal number.
 */
export const safeDecimal = (value: number): number => {
  return Number(value.toFixed(PRECISION))
}
