import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getItems } from './get-items'

export const getSubtotalPrice = createSelector(
  (state: RootState) => getItems(state),

  items => {
    return items.reduce((subtotalPrice, item) => {
      return subtotalPrice + item.quantity * item.unitPrice
    }, 0)
  },
)
