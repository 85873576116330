import {
  AuthService,
  BannerService,
  ComboService,
  CustomerService,
  DiscountService,
  OrderService,
  ProductCategoryService,
  ProductService,
  RestaurantService,
} from '@keffs/core/src/services'
import { Pusher } from 'pusher-js'
import React from 'react'

import { AnalyticsService } from '../services'

type Services = {
  analyticsService: AnalyticsService
  authService: AuthService
  bannerService: BannerService
  comboService: ComboService
  customerService: CustomerService
  discountService: DiscountService
  orderService: OrderService
  productCategoryService: ProductCategoryService
  productService: ProductService
  restaurantService: RestaurantService
  pusher: Pusher
}

export const ServicesContext = React.createContext<Services>(null as any)
