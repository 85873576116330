import { ProductCategory } from '@keffs/data/src/types'

import { ApiService } from './api-service'

export class ProductCategoryService extends ApiService {
  async fetchById(id: string): Promise<ProductCategory | null> {
    return this.request('GET', `/product-categories/${id}`)
  }

  async fetchAll(): Promise<ProductCategory[]> {
    return this.request('GET', '/product-categories')
  }

  async create(data: {
    name: ProductCategory['name']
    ncm: ProductCategory['ncm']
    cest: ProductCategory['cest']
    cfop: ProductCategory['cfop']
    csosn: ProductCategory['csosn']
    allowNotes: ProductCategory['allowNotes']
    madeInHouse: ProductCategory['madeInHouse']
    municipalTax: ProductCategory['municipalTax']
    stateTax: ProductCategory['stateTax']
    federalTax: ProductCategory['federalTax']
  }): Promise<string> {
    const { insertedId } = await this.request(
      'POST',
      '/product-categories',
      data,
    )
    return insertedId
  }

  async update(
    id: string,
    data: {
      name?: ProductCategory['name']
      ncm?: ProductCategory['ncm']
      cest?: ProductCategory['cest']
      cfop?: ProductCategory['cfop']
      csosn?: ProductCategory['csosn']
      allowNotes?: ProductCategory['allowNotes']
      madeInHouse?: ProductCategory['madeInHouse']
      municipalTax?: ProductCategory['municipalTax']
      stateTax?: ProductCategory['stateTax']
      federalTax?: ProductCategory['federalTax']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/product-categories/${id}`, data)
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/product-categories/${id}`)
  }
}
