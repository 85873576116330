import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getAppliedDiscount } from './get-applied-discount'
import { getDeliveryFee } from './get-delivery-fee'
import { getItems } from './get-items'

export const getTotalDiscount = createSelector(
  (state: RootState) => getAppliedDiscount(state),
  (state: RootState) => getItems(state),
  (state: RootState) => getDeliveryFee(state),

  (discount, items, deliveryFee) => {
    if (discount == null) {
      return 0
    }

    const itemsDiscount = items.reduce((acc, item) => {
      if ('productId' in item) {
        return acc + (item.totalDiscount || 0)
      }

      return acc
    }, 0)

    const deliveryFeeDiscount = discount.type === 'shipping' ? deliveryFee : 0

    return itemsDiscount + deliveryFeeDiscount
  },
)
