import { TextField } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import React from 'react'
import MaskedInput from 'react-text-mask'

const VehiclePlateInput = ({ inputRef, ...rest }: any) => {
  return (
    <MaskedInput
      {...rest}
      mask={[/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

export type VehiclePlateFieldProps = StandardTextFieldProps

export const VehiclePlateField = (
  props: VehiclePlateFieldProps,
): React.ReactElement => {
  return (
    <TextField
      InputProps={{
        inputComponent: VehiclePlateInput,
        ...(props.InputProps ? props.InputProps : {}),
      }}
      {...props}
    />
  )
}
