import { addMinutes, min } from 'date-fns'

/**
 * Subdivides a period into its constituting periods with a determined
 * length.
 *
 * @param start The start of the period.
 * @param end The end of the period.
 * @param interval The length of the intervals wanted.
 *
 * @returns The array of objects specifying start and end.
 */
export const subdivideInterval = (
  start: Date,
  end: Date,
  interval: number,
): Array<{ start: Date; end: Date }> => {
  if (!Number.isInteger(interval)) {
    throw new Error('`interval` must be an integer representing minutes')
  }

  const times = []

  for (
    let current = start;
    current < end;
    current = addMinutes(current, interval)
  ) {
    times.push({
      start: current,
      end: min([addMinutes(current, interval), end]),
    })
  }

  return times
}
