let timeoutId: ReturnType<typeof setTimeout>

export const preventInputObscuredByKeyboard = (): void => {
  if (navigator.appVersion.includes('Android')) {
    // Whenever the soft keyboard appears, a 'resize' event happens
    window.addEventListener('resize', () => {
      clearTimeout(timeoutId)

      // It seems the event will only happen AFTER the soft keyboard is already
      // open, but we'll wait just a few milliseconds to be on the safe side
      // before proceeding to scroll the input to the center of the screen.
      timeoutId = setTimeout(() => {
        const el = document.activeElement
        if (!el || !el.scrollIntoView) {
          return
        }

        const isInputLike = ['INPUT', 'TEXTAREA'].includes(el.tagName)
        if (!isInputLike) {
          return
        }

        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }, 30)
    })
  }
}
