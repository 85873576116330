import { FacebookButton } from '@keffs/core/src/components/facebook-button'
import { GoogleButton } from '@keffs/core/src/components/google-button'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import { PERSIST_THROTTLE_INTERVAL } from '../redux/configure-store'
import { ROUTE_HOME } from '../redux/routes'
import { getSubmitOnReload } from '../redux/selectors'

const useStyles = makeStyles({
  content: {
    justifyContent: 'center',
  },
  contextText: {
    maxWidth: 250,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    paddingTop: 12,
    paddingBottom: 12,
  },
})

export type AuthGuardProps = {
  open: boolean
}

export const AuthGuard = memo(({ open }: AuthGuardProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const submitOnReload = useSelector(getSubmitOnReload)

  const handleClose = () => {
    dispatch(actions.goBack({ name: ROUTE_HOME }))
  }

  const handleSignIn = (provider: 'google' | 'facebook') => () => {
    // Set a flag that will trigger order submission when coming back
    // from redirect.
    dispatch(actions.setSubmitOnReload(new Date()))

    // Make sure the current state was persisted before proceeding with
    // the sign-in flow to prevent losing yet unpersisted cart data.
    setTimeout(() => {
      dispatch(actions.signInAttempt({ provider }))
    }, PERSIST_THROTTLE_INTERVAL)
  }

  return (
    <Dialog
      disableBackdropClick={Boolean(submitOnReload)}
      open={open}
      onClose={handleClose}
      maxWidth="xs"
    >
      {submitOnReload ? (
        <DialogContent className={classes.spinner}>
          <CircularProgress size={40} />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>Identificação</DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.contextText}>
              Agora é só escolher uma das formas de identificação. É uma medida
              de segurança para proteger nossos entregadores. :)
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <GoogleButton
              className={classes.button}
              onClick={handleSignIn('google')}
            >
              Concluir com Google
            </GoogleButton>
            <FacebookButton
              className={classes.button}
              onClick={handleSignIn('facebook')}
            >
              Concluir com Facebook
            </FacebookButton>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
})
