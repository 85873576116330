import { parseTodaysOpeningHours } from '@keffs/data/src/parsers'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getClock } from '../clock'
import { getOpeningHours } from './get-opening-hours'

export const getTodaysOpeningHours = createSelector(
  (state: RootState) => getClock(state),
  (state: RootState) => getOpeningHours(state),

  (clock, openingHours) => {
    return parseTodaysOpeningHours(clock, openingHours)
  },
)
