import { Order, OrderStatus } from '@keffs/data/src/types'
import { lastXOClock } from '@keffs/data/src/utils'
import { encode } from 'qss'

import { ApiService } from './api-service'

export class OrderService extends ApiService {
  async fetchById(id: string): Promise<Order | null> {
    return this.request('GET', `/orders/${id}`)
  }

  async fetchAll({
    limit,
    offset,
    start,
    end,
    onlyActive,
  }: {
    limit?: number
    offset?: number
    start?: Date
    end?: Date
    onlyActive?: boolean
  } = {}): Promise<Order[]> {
    return this.request(
      'GET',
      '/orders?' +
        encode({ limit, offset, start, end, 'only-active': onlyActive }),
    )
  }

  async fetchActiveByCustomer(customerId: string): Promise<Order[]> {
    return this.request(
      'GET',
      '/orders?' + encode({ customer: customerId, 'only-active': true }),
    )
  }

  async fetchForToday(): Promise<Order[]> {
    const start = lastXOClock(new Date(), 3)
    return this.request('GET', '/orders?' + encode({ limit: 9999, start }))
  }

  async create(data: {
    serviceType: Order['serviceType']
    channel: Order['channel']
    destination: Order['destination']
    discountId: Order['discountId']
    items: Order['items']
    subtotalPrice: Order['subtotalPrice']
    packageFee: Order['packageFee']
    deliveryFee: Order['deliveryFee']
    totalDiscount: Order['totalDiscount']
    totalPrice: Order['totalPrice']
    customerId: Order['customerId']
    phoneNumber: Order['phoneNumber']
    name: Order['name']
    cpf: Order['cpf']
    deliveryTime: Order['deliveryTime']
    scheduled: Order['scheduled']
    address?: Order['address']
    district?: Order['district']
    city?: Order['city']
    reference?: Order['reference']
    coordinates?: Order['coordinates']
    vehicleModel?: Order['vehicleModel']
    vehicleColor?: Order['vehicleColor']
    vehiclePlate?: Order['vehiclePlate']
    paymentMethod?: Order['paymentMethod']
    changeFor?: Order['changeFor']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/orders', data)
    return insertedId
  }

  async update(
    id: string,
    data: {
      phoneNumber?: Order['phoneNumber']
      name?: Order['name']
      deliveryTime?: Order['deliveryTime']
      scheduled?: Order['scheduled']
      address?: Order['address']
      district?: Order['district']
      city?: Order['city']
      reference?: Order['reference']
      coordinates?: Order['coordinates']
      vehicleModel?: Order['vehicleModel']
      vehicleColor?: Order['vehicleColor']
      vehiclePlate?: Order['vehiclePlate']
      paymentMethod?: Order['paymentMethod']
      changeFor?: Order['changeFor']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/orders/${id}`, data)
  }

  async setDeliveryman(id: string, deliverymanId: string): Promise<void> {
    await this.request('PATCH', `/orders/${id}/deliveryman`, { deliverymanId })
  }

  async setInvoice(id: string, invoiceId: string): Promise<void> {
    await this.request('PATCH', `/orders/${id}/invoice`, { invoiceId })
  }

  async setPackageQuantity(id: string, packageQuantity: string): Promise<void> {
    await this.request('PATCH', `/orders/${id}/package-quantity`, {
      packageQuantity,
    })
  }

  async setStatus(
    id: string,
    status: OrderStatus,
    statusJustification?: string,
  ): Promise<void> {
    if (status === 'canceled' && !statusJustification) {
      throw new Error('Expected a status justification')
    }

    await this.request('PATCH', `/orders/${id}/status`, {
      status,
      statusJustification,
    })
  }
}
