import { IncompatibleBrowser } from '@keffs/core/src/components/incompatible-browser'
import { LoadingScreen } from '@keffs/core/src/components/loading-screen'
import { isIe } from '@keffs/platform'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  ROUTE_APPLY_DISCOUNT,
  ROUTE_AUTH,
  ROUTE_CART,
  ROUTE_CART_AUTH,
  ROUTE_CART_CHANGE_FOR,
  ROUTE_CART_DISCARD_DISCOUNT,
  ROUTE_CART_ITEM,
  ROUTE_CART_PAYMENT_METHOD,
  ROUTE_COMBO,
  ROUTE_HOME,
  ROUTE_NOT_FOUND,
  ROUTE_ORDER_DETAILS,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PRODUCT,
} from './redux/routes'
import { getLoadingData, getRoute } from './redux/selectors'
import { HomeScreen } from './screens/home'
import { NotFoundScreen } from './screens/not-found'
import { OrderDetailsScreen } from './screens/order-details'
import { PrivacyPolicyScreen } from './screens/privacy-policy'
import './styles.css'

const screens: { [key: string]: React.FC | string } = {
  [ROUTE_HOME]: HomeScreen,
  [ROUTE_AUTH]: HomeScreen,
  [ROUTE_PRODUCT]: HomeScreen,
  [ROUTE_COMBO]: HomeScreen,
  [ROUTE_CART]: HomeScreen,
  [ROUTE_CART_ITEM]: HomeScreen,
  [ROUTE_CART_PAYMENT_METHOD]: HomeScreen,
  [ROUTE_CART_CHANGE_FOR]: HomeScreen,
  [ROUTE_CART_AUTH]: HomeScreen,
  [ROUTE_CART_DISCARD_DISCOUNT]: HomeScreen,
  [ROUTE_APPLY_DISCOUNT]: HomeScreen,
  [ROUTE_ORDER_DETAILS]: OrderDetailsScreen,
  [ROUTE_PRIVACY_POLICY]: PrivacyPolicyScreen,
  [ROUTE_NOT_FOUND]: NotFoundScreen,
}

export const App = (): React.ReactElement => {
  const loading = useSelector(getLoadingData)
  const route = useSelector(getRoute)

  if (isIe()) {
    return <IncompatibleBrowser />
  }

  if (loading) {
    return <LoadingScreen />
  }

  // if (errorMessage) {
  //   return <ErrorScreen>{errorMessage}</ErrorScreen>
  // }

  const Component = screens[route.name]
  return <Component />
}
