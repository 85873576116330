const iso8601Regex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z|(-(2[0-3]|[01][0-9]):([0-5][0-9])))$/

/**
 * Checks whether a value is a string containing a date in the ISO-8601.
 *
 * @param value The value.
 *
 * @returns The resulting boolean.
 */
export const isIsoDate = (value: unknown): boolean => {
  return typeof value === 'string' && iso8601Regex.test(value)
}
