import { validateVehicleColor } from '@keffs/data/src/validators'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from './get-service-type'
import { getVehicleColor } from './get-vehicle-color'

export const getVehicleColorError = createSelector(
  (state: RootState) => getVehicleColor(state),
  (state: RootState) => getServiceType(state),

  (vehicleColor, serviceType) => {
    if (serviceType === 'driveBy') {
      return validateVehicleColor(vehicleColor)
    }

    return undefined
  },
)
