import { TextField } from '@material-ui/core'
import { StandardTextFieldProps } from '@material-ui/core/TextField'
import React from 'react'
import MaskedInput from 'react-text-mask'

const PhoneInput = ({ inputRef, ...rest }: any) => {
  return (
    <MaskedInput
      {...rest}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  )
}

export type PhoneFieldProps = StandardTextFieldProps

export const PhoneField = (props: PhoneFieldProps): React.ReactElement => {
  return (
    <TextField
      type="tel"
      InputProps={{
        inputComponent: PhoneInput,
        ...(props.InputProps ? props.InputProps : {}),
      }}
      {...props}
    />
  )
}
