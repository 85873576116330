import { roundTime } from '@keffs/data/src/utils'
import { delay, put } from 'redux-saga/effects'

import * as actions from '../actions'

/**
 * Returns how many milliseconds we need to wait so that the clock
 * rounds to the next multiple of `intervalInMs`.
 *
 * @example
 * // Given it's 12:03:00
 * getMsToSync(5 * 60 * 1000) // => 120000 (2 minutes)
 */
const getMsToSync = (intervalInMs: number) => {
  const initialTime = new Date()
  const firstSyncedTime = roundTime(initialTime, intervalInMs)
  return firstSyncedTime.getTime() - initialTime.getTime()
}

export function* clockSaga(intervalInMs: number) {
  yield delay(getMsToSync(intervalInMs))

  // Start ticking
  while (true) {
    yield put(actions.tick())
    yield delay(intervalInMs)
  }
}
