import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles({
  container: {
    margin: '64px auto',
    minWidth: 320,
    maxWidth: 720,
    minHeight: 600,
  },
})

export const PrivacyPolicyScreen = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography gutterBottom variant="h3" component="h1">
        POLÍTICA DE PRIVACIDADE
      </Typography>
      <Typography paragraph>
        Esta Política de Privacidade tem por objetivo explicar como a KEFFS pode
        coletar, guardar, processar, compartilhar, transferir e tratar os Dados
        Pessoais do Usuário durante a utilização da Plataforma. Esta Política de
        Privacidade aplica-se aos Dados Pessoais do Usuário quando visita,
        navega, utiliza ou adquire qualquer coisa na Plataforma e não se aplica
        a sites, plataformas ou serviços que não sejam de titularidade ou
        controlados pela KEFFS, incluindo sites e serviços de parceiros.
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        1. COLETA E USO DE INFORMAÇÕES PESSOAIS
      </Typography>
      <Typography paragraph>
        A KEFFS coleta todas as informações inseridas pelos Usuários na
        Plataforma, tais como dados cadastrais, dados de pedidos, respostas em
        pesquisas e enquetes, etc. Em síntese, são coletadas pela KEFFS todas as
        informações ativamente disponibilizadas pelos Usuários na utilização da
        Plataforma. O Usuário está ciente de que fornece essas informações de
        forma consciente e voluntária.
      </Typography>
      <Typography paragraph>
        A KEFFS também coleta algumas informações automaticamente quando o
        Usuário acessa e utiliza a Plataforma, tais como características do
        dispositivo e do navegador, dados de acesso como IP, data e hora,
        localização aproximada, informações sobre cliques, páginas acessadas,
        termos de busca digitados na Plataforma, etc. A KEFFS também poderá
        utilizar tecnologias para coleta de informações sobre o Usuário tais
        como cookies, pixel tags, beacons, etc.
      </Typography>
      <Typography paragraph>
        A KEFFS poderá utilizar todas as informações obtidas pela Plataforma a
        qualquer tempo e a seu exclusivo critério, seja para estudos de caso,
        análise de dados, estatísticas, divulgação de índices de consumo,
        parametrização de mercado consumidor ou para qualquer outra finalidade
        necessária à realização dos objetivos de negócio da KEFFS, sendo
        expressamente autorizada pelos Usuários a veiculação desses resultados,
        estudos, análises, estatísticas e pesquisas a terceiros.
      </Typography>
      <Typography paragraph>
        Os Usuários entendem e concordam que a Plataforma é acessível através de
        serviços de busca, podendo haver indexação de páginas com nomes de
        usuários e conteúdos por estes publicados, de maneira que a remoção de
        dados pessoais e outras informações do Usuário devem ser solicitadas
        diretamente aos referidos serviços de busca, não sendo de
        responsabilidade da KEFFS quaisquer providências nesse sentido.
      </Typography>
      <Typography paragraph>
        As informações coletadas por meio da Plataforma são devidamente
        autorizadas pelos Usuários, os quais manifestam, desde já, o seu
        consentimento livre, expresso e informado com relação à coleta de tais
        informações, para fins do disposto no artigo 7º, inciso IX, da Lei
        12.965/2014 (Marco Civil da Internet).
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        2. UTILIZAÇÃO E TRATAMENTO DAS INFORMAÇÕES
      </Typography>
      <Typography paragraph>
        Todas as informações cadastradas e coletadas na Plataforma são
        utilizadas para a execução das suas atividades, para melhorar a
        experiência de navegação dos Usuários na Plataforma e para fins
        publicitários.
      </Typography>
      <Typography paragraph>
        Importante atentar que a Plataforma pode conter links para outras
        páginas, inclusive de parceiros, que possuem políticas de privacidade
        diversas do disposto nesta Política de Privacidade da KEFFS. A KEFFS não
        se responsabiliza pela coleta, utilização, compartilhamento e
        armazenamento de seus dados pelos responsáveis por tais páginas fora de
        sua plataforma.
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        3. COMPARTILHAMENTO DE INFORMAÇÕES
      </Typography>
      <Typography paragraph>
        A KEFFS poderá compartilhar os dados coletados por meio da Plataforma:
        (i) Com os seus clientes e parceiros quando necessário ou apropriado à
        prestação de serviços relacionados; (ii) quando necessário às atividades
        comerciais da KEFFS; (iii) para proteção dos interesses da KEFFS em
        qualquer tipo de conflito, incluindo ações judiciais; (iv) no caso de
        transações e alterações societárias envolvendo a KEFFS, hipótese em que
        a transferência das informações será necessária para a continuidade da
        empresa; (v) por ordem judicial ou pelo requerimento de autoridades
        administrativas que detenham competência legal para sua requisição.
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        4. ARMAZENAMENTO DAS INFORMAÇÕES
      </Typography>
      <Typography paragraph>
        As informações fornecidas pelos Usuários na Plataforma serão armazenadas
        pela KEFFS em servidores próprios ou por ela contratados nacional ou
        internacionalmente.
      </Typography>
      <Typography paragraph>
        A KEFFS emprega todos os esforços razoáveis de mercado para garantir a
        segurança de seus sistemas na guarda de referidos dados, tais como: (i)
        utilização de métodos padrões e de mercado para criptografar e
        anonimizar os dados coletados; (ii) utilização de software de proteção
        contra acesso não autorizado aos sistemas da Plataforma; (iii)
        autorização de acesso somente a pessoas estabelecidas nos locais onde
        são armazenadas as informações; (iv) políticas internas para a
        manutenção da segurança da informação; (v) celebração de contratos com
        os colaboradores que têm acesso às informações dos Usuários, visando
        estabelecer a obrigação de manutenção do sigilo absoluto e
        confidencialidade dos dados acessados, sob pena de responsabilidade
        civil e penal nos moldes da legislação brasileira. No entanto, em razão
        da própria natureza da Internet, não é possível garantir que terceiros
        mal-intencionados não tenham sucesso em acessar indevidamente as
        informações dos Usuários.
      </Typography>
      <Typography paragraph>
        A KEFFS e seus fornecedores utilizam vários procedimentos de segurança
        para proteger a confidencialidade, segurança e integridade de seus Dados
        Pessoais, prevenindo a ocorrência de eventuais danos em virtude do
        tratamento desses dados.
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        5. EXCLUSÃO DAS INFORMAÇÕES
      </Typography>
      <Typography paragraph>
        O Usuário poderá solicitar à KEFFS que as informações referidas nesta
        Política de Privacidade sejam excluídas enviando uma solicitação para o
        e-mail contato@keffs.com.br com o assunto "Exclusão das minhas
        informações". A KEFFS empreenderá os melhores esforços para atender a
        todos os pedidos de exclusão no menor tempo possível. Tal exclusão
        impossibilitará novos acessos pelo Usuário. A KEFFS respeitará o prazo
        de armazenamento mínimo de determinadas informações, conforme
        determinado pela legislação brasileira, ainda que o Usuário solicite a
        exclusão de tais informações.
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        6. COOKIES
      </Typography>
      <Typography paragraph>
        Um cookie é um pequeno arquivo de texto que é armazenado e lido no
        navegador do usuário pelos websites que visita. Quase todos os websites
        usam tecnologia de cookies para otimizarem suas funcionalidades. Os
        cookies também tornam as suas interações com websites mais seguras e
        rápidas, visto que conseguem lembrar-se das suas preferências (por
        exemplo, início de sessão e idioma), enviando as informações que contêm
        de volta para o servidor originário (cookie de primeira parte) ou para
        outro servidor (cookie de terceiros), quando o Usuário revisita o
        respectivo website com o mesmo dispositivo.
      </Typography>
      <Typography paragraph>
        O Usuário pode bloquear o uso de cookies por meio das configurações de
        seu navegador, ciente de que esta ação poderá impedir o correto
        funcionamento da Plataforma.
      </Typography>
      <Typography gutterBottom variant="h5" component="h2">
        7. ACEITAÇÃO
      </Typography>
      <Typography paragraph>
        A aceitação desta Política de Privacidade pelo Usuário se dá
        automaticamente conforme utiliza a Plataforma.
      </Typography>
      <Typography paragraph>
        Caso o Usuário não concorde com estes termos, não deverá prosseguir com
        o uso da Plataforma.
      </Typography>
    </div>
  )
}
