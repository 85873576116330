import { Order } from '../types'

/**
 * Computes a boolean indicating whether the given order is considered
 * active by its current status.
 *
 * @param order The order.
 *
 * @returns The resulting boolean.
 */
export const computeIsOrderActive = (order: Order): boolean => {
  return order.status !== 'delivered' && order.status !== 'canceled'
}
