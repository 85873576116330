import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery'
import { Close as MdClose, Delete as MdDelete } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import classNames from 'classnames'
import {
  ErrorMessage,
  FastField,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  getIn,
} from 'formik'
import React, { memo } from 'react'

import { QuantityField } from '../../components/quantity-field'
import { greenThemeFactory, redThemeFactory } from '../../styles/mui-theme'

type FormValues = {
  choices?: string[]
  notes: string
  quantity: number
}

export type ItemDialogProps = {
  editing?: boolean
  image: string
  name: string
  description?: string
  choices?: Array<{
    id: string
    title: string
    options: Array<{
      id: string
      title: string
      quantity: number
      unitPrice: number
    }>
  }>
  allowNotes?: boolean
  initialValues: FormValues
  onRemove?: () => void
  onSubmit: (values: FormValues) => void
} & Omit<DialogProps, 'classes' | 'onSubmit'>

export const ItemDialog = memo(
  ({
    open,
    editing,
    image,
    name,
    description,
    choices,
    allowNotes = true,
    initialValues,
    onRemove,
    onSubmit,
    ...rest
  }: ItemDialogProps) => {
    const theme = useTheme<Theme>()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()

    const validate = (values: FormValues) => {
      const errors: { [key: string]: any } = {}

      for (const [i, choice] of values.choices?.entries() ?? []) {
        if (!choice) {
          // First ensure the array exists
          errors.choices = errors.choices ?? []
          errors.choices[i] = 'Escolha uma opção'
        }
      }

      return errors
    }

    const handleSubmit = (values: FormValues) => {
      onSubmit({
        choices: values.choices,
        notes: values.notes.trim(),
        quantity: values.quantity,
      })
    }

    return (
      <Dialog
        open={open}
        classes={{ paper: classes.dialogPaper }}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        {...rest}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            isSubmitting,
            setFieldValue,
            touched,
            values,
          }: FormikProps<FormValues>) => (
            <Form style={{ overflowY: 'auto' }}>
              <div className={classes.content}>
                <div className={classes.header}>
                  <IconButton color="primary" onClick={rest.onClose}>
                    <MdClose />
                  </IconButton>
                </div>

                <img
                  alt=""
                  className={classes.image}
                  draggable={false}
                  src={image}
                />

                <Typography align="center" gutterBottom variant="h6">
                  {name}
                </Typography>

                {description && (
                  <Typography align="center" gutterBottom>
                    {description}
                  </Typography>
                )}

                {choices && (
                  <div className={classes.choices}>
                    {choices.map((choice, index) => {
                      if (choice.options.length === 1) {
                        const theOnlyOption = choice.options[0]
                        return (
                          <FormControl key={index} fullWidth margin="none">
                            <Typography color="textSecondary" variant="caption">
                              {choice.title}
                            </Typography>
                            <Typography>
                              {theOnlyOption.quantity.toLocaleString('pt-BR')}{' '}
                              un. {theOnlyOption.title}
                            </Typography>
                          </FormControl>
                        )
                      }

                      return (
                        <FastField key={index} name={`choices[${index}]`}>
                          {({ field }: FieldProps) => (
                            <FormControl
                              key={index}
                              error={Boolean(getIn(touched, field.name) && getIn(errors, field.name))} // prettier-ignore
                              fullWidth
                              margin="normal"
                            >
                              <InputLabel shrink>{choice.title}</InputLabel>
                              <Select displayEmpty {...field}>
                                {choice.options.map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.quantity.toLocaleString('pt-BR')}{' '}
                                    un. {option.title}
                                  </MenuItem>
                                ))}
                              </Select>
                              {getIn(touched, field.name) &&
                                getIn(errors, field.name) && (
                                  <FormHelperText>
                                    <ErrorMessage name={field.name} />
                                  </FormHelperText>
                                )}
                            </FormControl>
                          )}
                        </FastField>
                      )
                    })}
                  </div>
                )}

                {allowNotes && (
                  <FastField name="notes">
                    {({ field }: FieldProps) => (
                      <TextField
                        data-testid="notes-field"
                        fullWidth
                        margin="normal"
                        placeholder="Alguma observação?"
                        {...field}
                      />
                    )}
                  </FastField>
                )}

                {!choices && (
                  <FastField name="quantity">
                    {({ field }: FieldProps) => (
                      <QuantityField
                        min={1}
                        onChange={value => setFieldValue('quantity', value)}
                        value={field.value}
                        variant="circle"
                      />
                    )}
                  </FastField>
                )}

                {onRemove && (
                  <MuiThemeProvider theme={redThemeFactory}>
                    <Button
                      data-testid="remove-from-cart"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      onClick={() => onRemove()}
                    >
                      <MdDelete /> Remover do carrinho
                    </Button>
                  </MuiThemeProvider>
                )}
              </div>

              <MuiThemeProvider theme={greenThemeFactory}>
                <Button
                  data-testid="add-to-cart"
                  className={classNames({
                    [classes.button]: true,
                    [classes.buttonFullScreen]: fullScreen,
                  })}
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {editing
                    ? 'Atualizar pedido'
                    : // prettier-ignore
                      `Adicionar ${values.quantity.toLocaleString('pt-BR')} un.`}
                </Button>
              </MuiThemeProvider>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  },
)

const useStyles = makeStyles({
  dialogPaper: {
    overflowY: 'hidden',
  },
  content: {
    padding: 16,
    paddingBottom: 'calc(16px + 54px)', // 54px to compensate for the button
    width: '100%',
    overflowY: 'auto',
  },
  header: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 8,
  },
  image: {
    display: 'block',
    margin: '24px auto 24px',
    width: '60%',
    maxWidth: 216,
    objectFit: 'cover',
  },
  choices: {
    marginTop: 16,
  },
  button: {
    position: 'absolute',
    bottom: 0,
    opacity: 0.87,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  buttonFullScreen: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})
