import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from './get-service-type'

export const getServiceTypeError = createSelector(
  (state: RootState) => getServiceType(state),

  serviceType => {
    if (!serviceType) {
      return 'Necessário escolher uma modalidade'
    }

    return undefined
  },
)
