import { formatCurrency } from '@keffs/formatters'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getMinOrder } from './get-min-order'
import { getServiceType } from './get-service-type'
import { getSubtotalPrice } from './get-subtotal-price'

export const getItemsError = createSelector(
  (state: RootState) => getServiceType(state),
  (state: RootState) => getSubtotalPrice(state),
  (state: RootState) => getMinOrder(state),

  (serviceType, subtotalPrice, minOrder) => {
    if (serviceType === 'eatIn') {
      return undefined
    }

    return subtotalPrice >= minOrder
      ? undefined
      : `Coloque mais itens para dar o pedido mínimo (faltando ${formatCurrency(
          minOrder - subtotalPrice,
        )})`
  },
)
