export const validateVehicleModel = (
  value: string | undefined,
): string | undefined => {
  if (!value) {
    return 'Necessário informar o modelo do veículo'
  }

  if (value.length < 3) {
    return 'Muito curto'
  }

  return undefined
}
