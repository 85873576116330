import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { addMinutes, format as formatDate } from 'date-fns'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import {
  getAllowAsap,
  getAvailableDeliveryTimes,
  getDeliveryTime,
  getDeliveryTimeError,
  getDeliveryTimeTouched,
  getServiceType,
} from '../redux/selectors'

export const CartDeliveryTime = memo(() => {
  const dispatch = useDispatch()
  const allowAsap = useSelector(getAllowAsap)
  const availableDeliveryTimes = useSelector(getAvailableDeliveryTimes)
  const serviceType = useSelector(getServiceType)
  const deliveryTime = useSelector(getDeliveryTime)
  const deliveryTimeError = useSelector(getDeliveryTimeError)
  const deliveryTimeTouched = useSelector(getDeliveryTimeTouched)

  const handleDeliveryTimeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    dispatch(
      actions.setDeliveryTime(
        e.target.value === 'asap' ? 'asap' : new Date(e.target.value),
      ),
    )
    dispatch(actions.touchDeliveryTime())
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        Quando?
      </Typography>

      <FormControl error={Boolean(deliveryTimeTouched && deliveryTimeError)}>
        <Select
          SelectDisplayProps={{ id: 'cart-delivery-time' }}
          displayEmpty
          onChange={handleDeliveryTimeChange}
          value={
            deliveryTime == null
              ? ''
              : deliveryTime === 'asap'
              ? 'asap'
              : deliveryTime.toISOString()
          }
        >
          <MenuItem disabled value="">
            Escolher...
          </MenuItem>
          <MenuItem disabled={!allowAsap} value="asap">
            {serviceType === 'delivery'
              ? 'Trazer o quanto antes'
              : 'Vou buscar agora'}
          </MenuItem>
          {availableDeliveryTimes.map(availableDeliveryTime => {
            const isoDeliveryTime = availableDeliveryTime.toISOString()

            return (
              <MenuItem key={isoDeliveryTime} value={isoDeliveryTime}>
                {serviceType === 'delivery'
                  ? `Entre ${formatDate(
                      availableDeliveryTime,
                      'H:mm',
                    )} e ${formatDate(
                      addMinutes(availableDeliveryTime, 30),
                      'H:mm',
                    )}`
                  : `Vou buscar às ${formatDate(
                      availableDeliveryTime,
                      'H:mm',
                    )}`}
              </MenuItem>
            )
          })}
        </Select>
        {deliveryTimeTouched && deliveryTimeError ? (
          <FormHelperText>{deliveryTimeError}</FormHelperText>
        ) : (
          !allowAsap &&
          availableDeliveryTimes.length > 0 &&
          !deliveryTime && (
            <FormHelperText>
              As entregas começam às{' '}
              {formatDate(availableDeliveryTimes[0], 'H:mm')}
            </FormHelperText>
          )
        )}
      </FormControl>
    </>
  )
})
