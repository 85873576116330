import { OrderService } from '@keffs/core/src/services'
import { formatPhoneNumber, normalizePhoneNumber } from '@keffs/formatters'
import { call, put, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'
import { ROUTE_CART_AUTH } from '../../routes'
import {
  getAddress,
  getAllErrors,
  getAppliedDiscount,
  getChangeFor,
  getCity,
  getCoordinates,
  getCpf,
  getCustomer,
  getDeliveryFee,
  getDeliveryTime,
  getDistrict,
  getItems,
  getName,
  getPackageFee,
  getPaymentMethod,
  getPhoneNumber,
  getReference,
  getRestaurantPhoneNumber,
  getServiceType,
  getServiceTypeTouched,
  getSignedIn,
  getSubtotalPrice,
  getTotalDiscount,
  getTotalPrice,
  getVehicleColor,
  getVehicleModel,
  getVehiclePlate,
} from '../../selectors'

let alreadyTouchedAll = false

/**
 * Handles all the logic of submission of the order.
 */
export function* processSubmitOrder(orderService: OrderService) {
  while (true) {
    yield take(getType(actions.submitOrderAttempt))

    const state = yield select()
    const isSignedIn = getSignedIn(state)
    const allErrors = getAllErrors(state)
    const serviceType = getServiceType(state)
    const serviceTypeTouched = getServiceTypeTouched(state)
    const customer = getCustomer(state)
    const items = getItems(state)
    const discount = getAppliedDiscount(state)
    const subtotalPrice = getSubtotalPrice(state)
    const packageFee = getPackageFee(state)
    const deliveryFee = getDeliveryFee(state)
    const totalDiscount = getTotalDiscount(state)
    const totalPrice = getTotalPrice(state)
    const phoneNumber = getPhoneNumber(state)
    const name = getName(state)
    const cpf = getCpf(state)
    const deliveryTime = getDeliveryTime(state)
    const address = getAddress(state)
    const district = getDistrict(state)
    const city = getCity(state)
    const reference = getReference(state)
    const coordinates = getCoordinates(state)
    const vehicleModel = getVehicleModel(state)
    const vehicleColor = getVehicleColor(state)
    const vehiclePlate = getVehiclePlate(state)
    const paymentMethod = getPaymentMethod(state)
    const changeFor = getChangeFor(state)

    // Any errors?
    if (allErrors.length > 0) {
      // Touch fields
      if (serviceType && !alreadyTouchedAll) {
        alreadyTouchedAll = true
        yield put(actions.touchAll())
      } else if (!serviceTypeTouched) {
        yield put(actions.touchServiceType())
      }

      // Scroll to errored field
      const firstError = allErrors[0]
      const el = document.getElementById(firstError.field)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      } else {
        throw new Error(`No element with ID "${firstError.field}" found`)
      }

      yield put(actions.submitOrderError('Há um campo inválido no formulário'))
      continue
    }

    // Not signed-in?
    if (!isSignedIn) {
      yield put(actions.push({ name: ROUTE_CART_AUTH }))
      yield put(actions.submitOrderError('Não está autenticado'))
      continue
    }

    if (serviceType === '') {
      throw new Error('`serviceType` should not be empty')
    } else if (customer == null) {
      throw new Error('`customer` should not be empty')
    }

    // OK, now really submit the order
    try {
      const insertedId = yield call([orderService, orderService.create], {
        serviceType,
        channel: 'website',
        destination: 'customer',
        discountId: discount ? discount.id : null,
        items: items.map(item => {
          if ('productId' in item) {
            return {
              productId: item.productId,
              quantity: item.quantity,
              ...(item.notes ? { notes: item.notes.trim() } : {}),
              unitPrice: item.unitPrice,
              ...(item.totalDiscount
                ? { totalDiscount: item.totalDiscount }
                : {}),
            }
          } else {
            return {
              comboId: item.comboId,
              choosenItems: item.choosenItems.map(({ id, ...rest }) => rest),
              quantity: item.quantity,
              ...(item.notes ? { notes: item.notes.trim() } : {}),
              unitPrice: item.unitPrice,
            }
          }
        }),
        subtotalPrice,
        packageFee,
        deliveryFee,
        totalDiscount,
        totalPrice,
        customerId: customer ? customer.id : null,
        phoneNumber: phoneNumber
          ? normalizePhoneNumber(phoneNumber, { international: true })
          : null,
        name: name ? name.trim() : null,
        cpf: cpf ? cpf.replace(/\D/g, '') : null,
        deliveryTime: deliveryTime instanceof Date ? deliveryTime : new Date(),
        scheduled: deliveryTime instanceof Date,
        ...(serviceType === 'delivery' ? { address: address ? address.trim() : null } : {}), // prettier-ignore
        ...(serviceType === 'delivery' ? { district: district ? district.trim() : null } : {}), // prettier-ignore
        ...(serviceType === 'delivery' ? { city: city ? city.trim() : null } : {}), // prettier-ignore
        ...(serviceType === 'delivery' ? { reference: reference ? reference.trim() : null } : {}), // prettier-ignore
        ...(serviceType === 'delivery' ? { coordinates } : {}),
        ...(serviceType === 'driveBy' ? { vehicleModel: vehicleModel ? vehicleModel.trim() : null } : {}), // prettier-ignore
        ...(serviceType === 'driveBy' ? { vehicleColor: vehicleColor ? vehicleColor.trim() : null } : {}), // prettier-ignore
        ...(serviceType === 'driveBy' ? { vehiclePlate: vehiclePlate ? vehiclePlate.trim() : null } : {}), // prettier-ignore
        ...(paymentMethod ? { paymentMethod } : {}),
        ...(changeFor ? { changeFor } : {}),
      })

      yield put(actions.submitOrderSuccess(insertedId))
    } catch (error) {
      const restaurantPhoneNumber = getRestaurantPhoneNumber(state)
      yield put(actions.submitOrderError('Houve um erro ao enviar o pedido'))
      yield put(
        actions.showSnackbar({
          primary: 'Erro ao enviar o pedido',
          secondary: restaurantPhoneNumber
            ? `Entre em contato: ${formatPhoneNumber(restaurantPhoneNumber)}`
            : undefined,
          variant: 'error',
        }),
      )
      console.error(error)
    }
  }
}
