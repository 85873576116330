import { call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import {
  getCombosMap,
  getItems,
  getProductCategoriesMap,
  getProductsMap,
} from '../../selectors'
import { RootState } from '../../types'

export function* cartRemovedItem(analyticsService: AnalyticsService) {
  // Save state whenever another action that may change the items happens
  let prevState: RootState = yield select()
  yield takeLatest(
    [getType(actions.addItem), getType(actions.updateItem)],
    function* () {
      prevState = yield select()
    },
  )

  // On product removed from cart...
  yield takeEvery(getType(actions.removeItem), function* (
    action: ActionType<typeof actions.removeItem>,
  ) {
    const id = action.payload
    const prevItems = getItems(prevState)
    const categoriesMap: ReturnType<typeof getProductCategoriesMap> = yield select(
      getProductCategoriesMap,
    )
    const productsMap: ReturnType<typeof getProductsMap> = yield select(
      getProductsMap,
    )
    const combosMap: ReturnType<typeof getCombosMap> = yield select(
      getCombosMap,
    )

    const item = prevItems.find(i => i.id === id)!

    // Track
    if ('productId' in item) {
      // Product item
      const product = productsMap[item.productId]
      const category = categoriesMap[product.categoryId]
      yield call([analyticsService, analyticsService.removedFromCart], {
        changeInQuantity: item.quantity,
        productId: product.id,
        productCode: `P${product.code}`,
        productName: product.name,
        productPrice: product.unitPrice,
        categoryName: category.name,
      })
    } else {
      // Combo item
      const combo = combosMap[item.comboId]
      yield call([analyticsService, analyticsService.removedFromCart], {
        changeInQuantity: item.quantity,
        productId: combo.id,
        productCode: `C${combo.code}`,
        productName: combo.name,
        productPrice: combo.unitPrice,
        categoryName: 'Combo',
      })
    }

    // Save state
    prevState = yield select()
  })
}
