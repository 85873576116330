import { getType } from 'typesafe-actions'

import { tick } from '../actions'
import { RootAction } from '../types'

export type State = Date

export const initialState: State = new Date()

export const clockReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(tick): {
      return new Date()
    }

    default: {
      return state
    }
  }
}
