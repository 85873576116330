import { combineReducers } from 'redux'

import { authReducer } from './auth'
import { bannersReducer } from './banners'
import { cartReducer } from './cart'
import { clockReducer } from './clock'
import { combosReducer } from './combos'
import { ordersReducer } from './orders'
import { permissionsReducer } from './permissions'
import { productCategoriesReducer } from './product-categories'
import { productsReducer } from './products'
import { restaurantReducer } from './restaurant'
import { routerReducer } from './router'
import { snackbarReducer } from './snackbar'

export const rootReducer = combineReducers({
  auth: authReducer,
  banners: bannersReducer,
  cart: cartReducer,
  clock: clockReducer,
  combos: combosReducer,
  orders: ordersReducer,
  permissions: permissionsReducer,
  productCategories: productCategoriesReducer,
  products: productsReducer,
  restaurant: restaurantReducer,
  router: routerReducer,
  snackbar: snackbarReducer,
})
