/**
 * Removes anything that's not a number and then re-add the '+' prefix
 * if it was there.
 * Normalizes a phone number to become only numbers preceded by "+55"
 * if international option is `true`.
 *
 * @param value The phone number to be normalized.
 * @param options.international If `true`, a '+' prefix will be added
 * to the returned string.
 *
 * @returns The normalized phone number.
 */
export const normalizePhoneNumber = (
  value: string,
  options: {
    international: boolean
  } = {
    international: false,
  },
): string => {
  const numbersOnly = value.replace(/\D/g, '')

  if (options.international) {
    return value.startsWith('+') ? `+${numbersOnly}` : `+55${numbersOnly}`
  } else {
    return value.startsWith('+') ? `+${numbersOnly}` : numbersOnly
  }
}
