/**
 * Omits parameters from the pathname.
 *
 * @param pathname The original pathname.
 *
 * @returns The clean pathname for tracking purposes.
 *
 * @example
 * normalizePath("/carrinho/item/123") // "/carrinho/item"
 */
export const normalizePathname = (pathname: string): string => {
  if (pathname.startsWith('/pedidos')) {
    return '/pedidos'
  } else if (pathname.startsWith('/carrinho/item')) {
    return '/carrinho/item'
  } else {
    return pathname
  }
}
