import { safeDecimal } from '@keffs/data/src/utils'

/**
 * Service encapsulating interaction with Facebook Pixel tracker.
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
 */
export class FacebookPixelService {
  private debug: boolean

  constructor(options: { debug: boolean }) {
    this.debug = options.debug
  }

  completeRegistration(): void {
    this.log('CompleteRegistration')

    window.fbq('track', 'CompleteRegistration')
  }

  viewContent(params: { id: string }): void {
    this.log('ViewContent', params)

    window.fbq('track', 'ViewContent', {
      content_ids: params.id,
      content_type: 'product',
    })
  }

  addToCart(params: {
    cart: Array<{
      quantity: number
      id: string
      price: number
    }>
  }): void {
    this.log('AddToCart', params)

    window.fbq('track', 'AddToCart', {
      value: params.cart.reduce(
        (acc, item) => safeDecimal(acc + item.quantity * item.price),
        0,
      ),
      currency: 'BRL',
      content_type: 'product',
      contents: params.cart.map(item => ({
        quantity: item.quantity,
        id: item.id,
        item_price: item.price,
      })),
    })
  }

  initiateCheckout(): void {
    this.log('InitiateCheckout')

    window.fbq('track', 'InitiateCheckout')
  }

  purchase(params: {
    cart: Array<{
      quantity: number
      id: string
      price: number
    }>
  }): void {
    this.log('Purchase', params)

    window.fbq('track', 'Purchase', {
      value: params.cart.reduce(
        (acc, item) => safeDecimal(acc + item.quantity * item.price),
        0,
      ),
      currency: 'BRL',
      content_type: 'product',
      contents: params.cart.map(item => ({
        quantity: item.quantity,
        id: item.id,
        item_price: item.price,
      })),
    })
  }

  private log(eventName: string, params?: { [key: string]: any }) {
    console.log(`[fbq] ${eventName}:`, params || 'No params')
  }
}
