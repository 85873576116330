import { Grid } from '@material-ui/core'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { getProducts } from '../redux/selectors'
import { ProductCard } from './product-card'

export const ProductGrid = memo(() => {
  const products = useSelector(getProducts)

  return (
    <Grid container spacing={8}>
      {products
        .filter(product => product.active)
        .filter(product => !product.hidden)
        .filter(product => product.channels.includes('website'))
        .map(product => (
          <Grid key={product.id} item xs={6} sm={4} md={3}>
            <ProductCard product={product} />
          </Grid>
        ))}
    </Grid>
  )
})
