import { put, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'
import { getSubmitOnReload } from '../../selectors'

const AUTO_SUBMIT_TIME_LIMIT_IN_MS = 3 * 60 * 1000

/**
 * In case the app is initializing after authentication, we may
 * have an order in the store pending submission.
 */
export function* submitPendingOrder() {
  while (true) {
    yield take(getType(actions.signInSuccess))

    const submitOnReload: ReturnType<typeof getSubmitOnReload> = yield select(
      getSubmitOnReload,
    )
    if (!submitOnReload) {
      continue
    }

    if (!(submitOnReload instanceof Date)) {
      yield put(actions.setSubmitOnReload(false))
      continue
    }

    // The reason for the submitOnReload flag is to auto-submit the
    // order in case the user had to perform the signInWithRedirect
    // flow before being able to submit. In this case, the user left
    // the page by clicking the submit button, so when he comes back
    // we submit the order automatically as that's the user's intent.
    // But for some reason the flag may be set days ago and an error
    // may have ocurred that left the order persisted and the flag set.
    // If that's the case, the user for sure doesn't expect to enter
    // the application and have a stale order automatically submitted.
    const msSinceLastSubmitAttempt = Date.now() - submitOnReload.getTime()
    if (msSinceLastSubmitAttempt > AUTO_SUBMIT_TIME_LIMIT_IN_MS) {
      yield put(actions.setSubmitOnReload(false))
      continue
    }

    // Perform automatic order submission
    yield put(actions.submitOrderAttempt())
  }
}
