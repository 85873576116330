/**
 * Formats a number into a decimal representation with two decimal digits.
 *
 * @param value The number to be formatted.
 *
 * @returns The formatted string.
 */
export const formatDecimal = (value: number): string => {
  const [integer, fraction] = value.toFixed(2).split('.')
  const formattedInteger = integer.replace(
    /[0-9](?=(?:[0-9]{3})+(?![0-9]))/g,
    '$&.',
  )
  return `${formattedInteger},${fraction}`
}
