import { OrderStatus } from '@keffs/data/src/types'
import { Theme, createMuiTheme } from '@material-ui/core'

import { shadows } from './shadows'

declare module '@material-ui/core/styles' {
  interface Theme {
    statuses: {
      [key in OrderStatus]: {
        color: string
        contrastText: string
        opacity: number
      }
    }
  }
}

export const createTheme = (
  {
    narrowerBreakpoints = false,
  }: {
    narrowerBreakpoints: boolean
  } = {
    narrowerBreakpoints: false,
  },
): Theme => {
  const theme: Theme = {
    ...createMuiTheme({
      palette: {
        primary: {
          light: '#484848',
          main: '#212121',
          dark: '#000',
          contrastText: '#fff',
        },
      },

      typography: {
        useNextVariants: true,
        h6: {
          fontFamily: 'Cantarell, Roboto, Helvetica, Arial, sans-serif',
          fontSize: 28,
          fontWeight: 700,
        },
        subtitle1: {
          fontFamily: 'Cantarell, Roboto, Helvetica, Arial, sans-serif',
        },
        button: {
          fontFamily: 'Cantarell, Roboto, Helvetica, Arial, sans-serif',
          fontWeight: 800,
        },
      },

      breakpoints: narrowerBreakpoints
        ? {
            keys: ['xs', 'sm', 'md', 'lg', 'xl'],
            values: {
              xs: 0,
              sm: 480,
              md: 600,
              lg: 1280,
              xl: 1920,
            },
          }
        : undefined,

      shadows,

      overrides: {
        MuiBackdrop: {
          root: {
            backgroundColor: 'rgba(33, 33, 33, 0.7)',
          },
        },
        MuiButton: {
          sizeLarge: {
            minHeight: 54,
          },
        },
        MuiSelect: {
          root: {
            minWidth: 180,
          },
        },
      },
    }),

    /**
     * Custom
     */
    statuses: {
      new: {
        color: '#00b300',
        contrastText: '#fff',
        opacity: 1,
      },
      inProduction: {
        color: '#fbc02d',
        contrastText: '#fff',
        opacity: 1,
      },
      ready: {
        color: '#0000ff',
        contrastText: '#fff',
        opacity: 1,
      },
      onTheWay: {
        color: '#0000ff',
        contrastText: '#fff',
        opacity: 0.3,
      },
      atTheDoor: {
        color: '#0000ff',
        contrastText: '#fff',
        opacity: 0.3,
      },
      delivered: {
        color: '#ffffff',
        contrastText: 'rgba(0, 0, 0, 0.87)',
        opacity: 0.3,
      },
      canceled: {
        color: '#f44336',
        contrastText: 'rgba(0, 0, 0, 0.87)',
        opacity: 0.3,
      },
    },
  }

  return theme
}

export const blueThemeFactory = (outer: Theme | null): Theme =>
  createMuiTheme({
    ...outer,
    palette: {
      ...(outer ? outer.palette : {}),
      primary: {
        main: '#2196F3',
        contrastText: '#fff',
      } as any,
    },
  })

export const greenThemeFactory = (outer: Theme | null): Theme =>
  createMuiTheme({
    ...outer,
    palette: {
      ...(outer ? outer.palette : {}),
      primary: {
        main: '#6abf40', // hsla(99, 46%, 66%)
        contrastText: '#fff',
      },
    },
  })

export const redThemeFactory = (outer: Theme | null): Theme =>
  createMuiTheme({
    ...outer,
    palette: {
      ...(outer ? outer.palette : {}),
      primary: {
        main: '#f43734', // hsla(1, 90%, 58%)
        contrastText: '#fff',
      },
    },
  })

export const whatsappThemeFactory = (outer: Theme | null): Theme =>
  createMuiTheme({
    ...outer,
    palette: {
      ...(outer ? outer.palette : {}),
      primary: {
        main: '#25d366',
        contrastText: '#fff',
      } as any,
    },
  })

export const theme = {
  // ------
  // Colors
  // ------
  PRIMARY: '#212121',
  SUCCESS: '#6abf40',
  ERROR: '#f43734',

  BACKGROUND: '#fafafa',
  DIVIDER: 'rgba(0, 0, 0, 0.12)',

  TEXT_PRIMARY: 'rgba(0, 0, 0, 0.87)',
  TEXT_SECONDARY: 'rgba(0, 0, 0, 0.54)',
  TEXT_DISABLED: 'rgba(0, 0, 0, 0.38)',
  TEXT_HINT: 'rgba(0, 0, 0, 0.38)',

  // ----------
  // Typography
  // ----------
  FONT_FAMILY: 'Cantarell, Roboto, Helvetica, Arial, sans-serif',

  TEXT_SMALL: 8,
  TEXT_MEDIUM: 12,
  TEXT_LARGE: 18,

  // -------
  // Shadows
  // -------
  SHADOW_LIGHT: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 3,
  },

  // -----
  // Sizes
  // -----
  BORDER_RADIUS: 4,

  // ------
  // Status
  // ------
  STATUS_NEW: '#00b300',
  STATUS_NEW_CONTRAST: '#fff',
  STATUS_NEW_OPACITY: 1,

  STATUS_IN_PRODUCTION: '#fbc02d',
  STATUS_IN_PRODUCTION_CONTRAST: '#fff',
  STATUS_IN_PRODUCTION_OPACITY: 1,

  STATUS_READY: '#0000ff',
  STATUS_READY_CONTRAST: '#fff',
  STATUS_READY_OPACITY: 1,

  STATUS_ON_THE_WAY: '#0000ff',
  STATUS_ON_THE_WAY_CONTRAST: '#fff',
  STATUS_ON_THE_WAY_OPACITY: 0.3,

  STATUS_AT_THE_DOOR: '#0000ff',
  STATUS_AT_THE_DOOR_CONTRAST: '#fff',
  STATUS_AT_THE_DOOR_OPACITY: 0.3,

  STATUS_DELIVERED: '#fff',
  STATUS_DELIVERED_CONTRAST: 'rgba(0, 0, 0, 0.87)',
  STATUS_DELIVERED_OPACITY: 0.3,

  STATUS_CANCELED: '#f44336',
  STATUS_CANCELED_CONTRAST: 'rgba(0, 0, 0, 0.87)',
  STATUS_CANCELED_OPACITY: 0.3,
}
