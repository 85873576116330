import { computeNextOpeningText } from '@keffs/data/src/computed'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getClock } from '../clock'
import { getNextOpening } from './get-next-opening'

export const getNextOpeningText = createSelector(
  (state: RootState) => getClock(state),
  (state: RootState) => getNextOpening(state),

  (clock, nextOpening) => {
    return computeNextOpeningText(clock, nextOpening)
  },
)
