import { formatPhoneNumber } from '@keffs/formatters'
import { put, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../../actions'

export function* showSnackbarOnSignIn() {
  while (true) {
    const action: ActionType<typeof actions.signInSuccess> = yield take(
      getType(actions.signInSuccess),
    )

    const customer = action.payload

    // Show a different version of the snackbar depending on the
    // available data in the customer profile
    if (customer.displayName) {
      yield put(
        actions.showSnackbar({
          avatar: customer.photoUrl || customer.displayName[0],
          primary: customer.displayName,
          secondary: customer.phoneNumber
            ? formatPhoneNumber(customer.phoneNumber)
            : customer.email || undefined,
        }),
      )
    } else if (customer.phoneNumber) {
      yield put(
        actions.showSnackbar({
          avatar: customer.photoUrl || undefined,
          primary: formatPhoneNumber(customer.phoneNumber),
          secondary: customer.email || undefined,
        }),
      )
    } else if (customer.email) {
      yield put(
        actions.showSnackbar({
          avatar: customer.photoUrl || undefined,
          primary: customer.email,
        }),
      )
    }
  }
}
