import { AuthService } from '@keffs/core/src/services'
import { call, put } from 'redux-saga/effects'

import * as actions from '../../actions'
import { ROUTE_HOME } from '../../routes'

const PENDING_CRENDENTIAL_KEY = 'pendingCredential'

/**
 * Handles Firebase's `signInWithRedirect` flow.
 */
export function* handleRedirectResult(authService: AuthService) {
  try {
    const result = yield call([authService, authService.getRedirectResult])
    console.log('getRedirectResult:', result)
    const user = result.user

    if (user) {
      // Go back to home screen
      yield put(actions.replace({ name: ROUTE_HOME }))

      // Check if there's a pending credential persisted. If that's the
      // case, then we are continuing the flow described below in the
      // `catch` block, so we want to link the persisted credential to
      // the user account so that in the future the user may also sign-in
      // with this new identity provider.
      const pendingCredentialJson = sessionStorage.getItem(
        PENDING_CRENDENTIAL_KEY,
      )
      if (pendingCredentialJson) {
        const { accessToken, providerId } = JSON.parse(pendingCredentialJson)
        const Provider = AuthService.getProviderByProviderId(providerId)
        const pendingCredential = Provider.credential(accessToken)
        try {
          yield call(user.linkAndRetrieveDataWithCredential, pendingCredential)
        } finally {
          sessionStorage.removeItem(PENDING_CRENDENTIAL_KEY)
        }
      }
    }
  } catch (error) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      // The user tried to sign-in with an identity provider different
      // from the identity provider that was already linked to the account,
      // so we persist the credentials obtained but redirect the user
      // again to sign-in with the provider that was already linked.
      // Code above in the `try` block will continue from this.
      sessionStorage.setItem(
        PENDING_CRENDENTIAL_KEY,
        JSON.stringify(error.credential),
      )
      yield call(
        [authService, authService.signInWithExistentCredentialForEmail],
        error.email,
      )
    } else {
      console.error(error)
    }
  }
}
