import {
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add as MdAdd } from '@material-ui/icons'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import {
  getAddress,
  getAddressError,
  getAddressTouched,
  getCity,
  getCustomerLocations,
  getDistrict,
  getDistrictError,
  getDistrictTouched,
  getReference,
  getReferenceError,
  getReferenceTouched,
  getSelectedLocation,
  getSelectedLocationError,
  getSelectedLocationTouched,
} from '../redux/selectors'

export const CartDeliveryLocation = memo(() => {
  const dispatch = useDispatch()
  const customerLocations = useSelector(getCustomerLocations)
  const selectedLocation = useSelector(getSelectedLocation)
  const selectedLocationError = useSelector(getSelectedLocationError)
  const selectedLocationTouched = useSelector(getSelectedLocationTouched)
  const address = useSelector(getAddress)
  const addressError = useSelector(getAddressError)
  const addressTouched = useSelector(getAddressTouched)
  const district = useSelector(getDistrict)
  const districtError = useSelector(getDistrictError)
  const districtTouched = useSelector(getDistrictTouched)
  const city = useSelector(getCity)
  const reference = useSelector(getReference)
  const referenceError = useSelector(getReferenceError)
  const referenceTouched = useSelector(getReferenceTouched)

  const handleSelectedLocationChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    dispatch(actions.setSelectedLocation(e.target.value))
  }

  const handleAddressBlur = () => {
    if (!addressTouched) {
      dispatch(actions.touchAddress())
    }
  }

  const handleDistrictBlur = () => {
    if (!districtTouched) {
      dispatch(actions.touchDistrict())
    }
  }

  const handleReferenceBlur = () => {
    if (!referenceTouched) {
      dispatch(actions.touchReference())
    }
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setAddress(e.target.value))
  }

  const handleDistrictChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setDistrict(e.target.value))
  }

  const handleReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setReference(e.target.value))
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        Onde?
      </Typography>

      {customerLocations.length > 0 && (
        <FormControl
          error={Boolean(selectedLocationTouched && selectedLocationError)}
        >
          <Select
            SelectDisplayProps={{ id: 'cart-selected-location' }}
            displayEmpty
            onChange={handleSelectedLocationChange}
            value={selectedLocation}
          >
            <MenuItem disabled value="">
              Escolher...
            </MenuItem>
            {customerLocations.map(({ id, address, district, reference }) => (
              <MenuItem key={id} value={id}>
                <ListItemText
                  primary={`${address}, ${district}`}
                  secondary={reference}
                />
              </MenuItem>
            ))}
            <MenuItem value="new">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ListItemIcon style={{ marginRight: 0 }}>
                  <MdAdd />
                </ListItemIcon>
                <ListItemText>Usar um novo endereço</ListItemText>
              </div>
            </MenuItem>
          </Select>
          {selectedLocationTouched && selectedLocationError && (
            <FormHelperText>{selectedLocationError}</FormHelperText>
          )}
        </FormControl>
      )}
      {selectedLocation === 'new' && (
        <>
          <TextField
            autoComplete="nope"
            error={Boolean(addressTouched && addressError)}
            fullWidth
            helperText={addressTouched && addressError}
            id="cart-address"
            margin="normal"
            onBlur={handleAddressBlur}
            onChange={handleAddressChange}
            placeholder="Qual rua, quadra e lote?"
            value={address}
          />
          <TextField
            autoComplete="nope"
            error={Boolean(districtTouched && districtError)}
            fullWidth
            helperText={districtTouched && districtError}
            id="cart-district"
            margin="normal"
            onBlur={handleDistrictBlur}
            onChange={handleDistrictChange}
            placeholder="Qual bairro?"
            value={district}
          />
          <TextField
            autoComplete="nope"
            error={Boolean(referenceTouched && referenceError)}
            fullWidth
            helperText={referenceTouched && referenceError}
            id="cart-reference"
            margin="normal"
            onBlur={handleReferenceBlur}
            onChange={handleReferenceChange}
            placeholder="Algum ponto de referência, cor do portão?"
            value={reference}
          />
          <TextField
            autoComplete="nope"
            disabled
            fullWidth
            id="cart-city"
            margin="normal"
            value={city}
          />
        </>
      )}
    </>
  )
})
