import { safeDecimal } from '@keffs/data/src/utils'
import { createSelector } from 'reselect'

import { CartComboItem, CartProductItem, RootState } from '../../types'
import { getCombosMap } from '../get-combos-map'
import { getProductsMap } from '../get-products-map'

export const getItems = createSelector(
  (state: RootState) => state.cart.items,
  (state: RootState) => getProductsMap(state),
  (state: RootState) => getCombosMap(state),

  (items, productsMap, combosMap) => {
    return items.reduce(
      (
        acc: Array<CartProductItem | CartComboItem>,
        item,
      ): Array<CartProductItem | CartComboItem> => {
        if ('productId' in item) {
          // Product item
          const product = productsMap[item.productId]

          // Prevent users from ordering products that were available
          // when they last visited the site but are not available now
          if (!product) {
            return acc
          }

          const productItem: CartProductItem = {
            ...item,
            unitPrice: product.unitPrice,
            totalDiscount: safeDecimal(
              (product.unitPrice - product.unitPriceAfterDiscount) *
                item.quantity,
            ),
          }

          return [...acc, productItem]
        } else {
          // Combo item
          const combo = combosMap[item.comboId]

          // Prevent users from ordering combos that were available
          // when they last visited the site but are not available now
          if (!combo) {
            return acc
          }

          const comboItem: CartComboItem = {
            ...item,
            choosenItems: item.choosenItems.map((choosenItem, index) => ({
              ...choosenItem,
              unitPrice: combo.items[index].unitPrice,
            })),
            unitPrice: combo.unitPrice,
          }

          return [...acc, comboItem]
        }
      },
      [],
    )
  },
)
