import { DiscountService } from '@keffs/core/src/services'
import { validateDiscount } from '@keffs/data/src/validators'
import { call, put, select, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../../actions'
import { ROUTE_APPLY_DISCOUNT, ROUTE_HOME } from '../../routes'
import {
  getAppliedDiscount,
  getClock,
  getCustomer,
  getRoute,
} from '../../selectors'

/**
 * Fetches and applies the given discount if valid.
 */
function* tryToApplyDiscount(
  discountService: DiscountService,
  discountIdOrCode: string,
) {
  // Fetch discount
  const discount = yield call(
    [discountService, discountService.fetchById],
    discountIdOrCode,
  )

  // Discount not found?
  if (discount == null) {
    yield put(actions.showSnackbar('Desconto não encontrado'))
    yield put(actions.clearDiscount())
    yield put(actions.replace({ name: ROUTE_HOME }))
    return
  }

  // User not signed-in?
  const customer: ReturnType<typeof getCustomer> = yield select(getCustomer)
  if (customer == null) {
    const route: ReturnType<typeof getRoute> = yield select(getRoute)

    if (route.name === ROUTE_APPLY_DISCOUNT) {
      // In promo route: a dialog will be showed by the UI asking to
      // sign-in. After signing-in, as the URL is preserved, the user
      // will be back to the promo URL and this saga will run again.
      return
    } else {
      // NOT in the promo route: so this is running so that a persisted
      // discount's validity is checked. Clear discount as there's no
      // user signed-in.
      yield put(actions.clearDiscount())
      return
    }
  }

  // Is the discount expired, finished, etc.?
  const clock: ReturnType<typeof getClock> = yield select(getClock)
  const errorMessage = validateDiscount({ clock, customer, discount })
  if (errorMessage) {
    // Show snackbar explaining why
    yield put(actions.showSnackbar(errorMessage))
    yield put(actions.clearDiscount())
    yield put(actions.replace({ name: ROUTE_HOME }))
    return
  }

  // Valid! Apply to the cart
  yield put(actions.applyDiscount(discount))
  yield put(actions.clearServiceType())
  yield put(actions.replace({ name: ROUTE_HOME }))
}

export function* applyDiscount(discountService: DiscountService) {
  const route: ReturnType<typeof getRoute> = yield select(getRoute)

  // First of all, wait until auth is initialized because we will need
  // to check signed-in user to see whether or not a discount is valid
  yield take(getType(actions.authInitialized))

  // Initialized in a promo route? Try to apply discount
  if (route.name === ROUTE_APPLY_DISCOUNT) {
    const discountIdOrCode = route.payload.idOrCode

    yield call(tryToApplyDiscount, discountService, discountIdOrCode)
    return
  }

  // Is there a persisted discount in the cart? Try to re-apply so that
  // it's removed in case it's not valid anymore or updated if it has
  // been changed in the database since last visit
  const discount: ReturnType<typeof getAppliedDiscount> = yield select(
    getAppliedDiscount,
  )
  if (discount != null) {
    yield call(tryToApplyDiscount, discountService, discount.id)
  }

  // Finally, keep watching for promo route
  while (true) {
    const action: ActionType<typeof actions.routeChanged> = yield take(
      getType(actions.routeChanged),
    )

    const route = action.payload

    if (route.name === ROUTE_APPLY_DISCOUNT) {
      const discountIdOrCode = route.payload.idOrCode

      yield call(tryToApplyDiscount, discountService, discountIdOrCode)
    }
  }
}
