import {
  Channel,
  EmployeeRole,
  InvoiceStatus,
  OrderDestination,
  ServiceType,
} from '../types'

export const AtTheDayOfWeekLiteral: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] = [
  'no domingo',
  'na segunda-feira',
  'na terça-feira',
  'na quarta-feira',
  'na quinta-feira',
  'na sexta-feira',
  'no sábado',
]

export const ChannelLiteral: { [key in Channel]: string } = {
  chatAndPhone: 'Chat e telefone',
  counter: 'Balcão',
  website: 'Site',
}

export const DayOfWeekLiteral: { [key: number]: string } = {
  0: 'Dom',
  1: 'Seg',
  2: 'Ter',
  3: 'Qua',
  4: 'Qui',
  5: 'Sex',
  6: 'Sáb',
}

export const EmployeeRoleLiteral: { [key in EmployeeRole]: string } = {
  admin: 'Administrador',
  manager: 'Gerente',
  attendant: 'Atendente',
  deliveryman: 'Entregador',
}

export const InvoiceStatusLiteral: { [key in InvoiceStatus]: string } = {
  approved: 'Aprovada',
  canceled: 'Cancelada',
  disabled: 'Inutilizada',
  pending: 'Pendente',
  rejected: 'Rejeitada',
}

export const OrderDestinationLiteral: { [key in OrderDestination]: string } = {
  customer: 'Cliente',
  employee: 'Colaborador',
}

export const ServiceTypeLiteral: { [key in ServiceType]: string } = {
  eatIn: 'Comer na loja',
  takeOut: 'Buscar para viagem',
  driveBy: 'Drive-by',
  delivery: 'Delivery',
}
