import { computeAllDeliveryTimes } from '@keffs/data/src/computed'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from '../cart/get-service-type'
import { getTodaysOpeningHours } from './get-todays-opening-hours'

export const getAllDeliveryTimes = createSelector(
  (state: RootState) => getServiceType(state),
  (state: RootState) => getTodaysOpeningHours(state),

  (serviceType, todaysOpeningHours) => {
    // Even when the restaurant is closed it doesn't mean there are
    // absolutely no available delivery times. So we use a fallback to
    // enforce correct behavior even when the user didn't yet choose a
    // the serviceType.
    const serviceTypeOrFallback = serviceType || 'delivery'
    return computeAllDeliveryTimes(serviceTypeOrFallback, todaysOpeningHours)
  },
)
