import { call, select, takeEvery } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { AnalyticsService } from '../../../services'
import * as actions from '../../actions'
import {
  getCombosMap,
  getItems,
  getProductCategoriesMap,
  getProductsMap,
} from '../../selectors'

export function* cartAddedItem(analyticsService: AnalyticsService) {
  // On new item added to the cart...
  yield takeEvery(getType(actions.addItem), function* (
    action: ActionType<typeof actions.addItem>,
  ) {
    const { id } = action.payload
    const items: ReturnType<typeof getItems> = yield select(getItems)
    const categoriesMap: ReturnType<typeof getProductCategoriesMap> = yield select(
      getProductCategoriesMap,
    )
    const productsMap: ReturnType<typeof getProductsMap> = yield select(
      getProductsMap,
    )
    const combosMap: ReturnType<typeof getCombosMap> = yield select(
      getCombosMap,
    )

    const item = items.find(i => i.id === id)!

    // Gather all cart items to be used below
    const cart = items.map(item => {
      if ('productId' in item) {
        // Product item
        const product = productsMap[item.productId]
        const category = categoriesMap[product.categoryId]
        return {
          quantity: item.quantity,
          productId: product.id,
          productCode: `P${product.code}`,
          productName: product.name,
          productPrice: product.unitPrice,
          categoryName: category.name,
        }
      } else {
        // Combo item
        const combo = combosMap[item.comboId]
        return {
          quantity: item.quantity,
          productId: combo.id,
          productCode: `C${combo.code}`,
          productName: combo.name,
          productPrice: combo.unitPrice,
          categoryName: 'Combo',
        }
      }
    })

    // Track
    if ('productId' in item) {
      // Product item
      const product = productsMap[item.productId]
      const category = categoriesMap[product.categoryId]
      yield call([analyticsService, analyticsService.addedToCart], {
        changeInQuantity: item.quantity,
        resultingQuantity: item.quantity,
        productId: product.id,
        productCode: `P${product.code}`,
        productName: product.name,
        productPrice: product.unitPrice,
        categoryName: category.name,
        cart,
      })
    } else {
      // Combo item
      const combo = combosMap[item.comboId]
      yield call([analyticsService, analyticsService.addedToCart], {
        changeInQuantity: item.quantity,
        resultingQuantity: item.quantity,
        productId: combo.id,
        productCode: `C${combo.code}`,
        productName: combo.name,
        productPrice: combo.unitPrice,
        categoryName: 'Combo',
        cart,
      })
    }
  })
}
