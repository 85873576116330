import {
  MIN_ORDER_FOR_FREE_PACKAGE,
  PACKAGE_FEE,
} from '@keffs/data/src/constants'
import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getServiceType } from './get-service-type'
import { getSubtotalPrice } from './get-subtotal-price'

export const getPackageFee = createSelector(
  (state: RootState) => getServiceType(state),
  (state: RootState) => getSubtotalPrice(state),

  (serviceType, subtotalPrice) => {
    if (serviceType === 'takeOut' || serviceType === 'driveBy') {
      return subtotalPrice >= MIN_ORDER_FOR_FREE_PACKAGE ? 0 : PACKAGE_FEE
    }

    return 0
  },
)
