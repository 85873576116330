import { Restaurant } from '../types'

const parseDateFromTimeString = (today: Date, timeString: string): Date => {
  const [h, m] = timeString.split(':').map(Number)
  return new Date(new Date(today).setHours(h, m, 0, 0))
}

/**
 * Given the information on opening hours of the restaurant, returns
 * an object specifying Date objects for the start and end of today.
 *
 * @param now The current time.
 * @param openingHours The restaurant's opening hours.
 *
 * @returns The object containing start and end Dates or null of closed.
 */
export const parseTodaysOpeningHours = (
  now: Date,
  openingHours: Restaurant['openingHours'],
): { start: Date; end: Date } | null => {
  const today = openingHours[now.getDay()]

  if (today.length === 0) {
    return null
  }

  if (today.length % 2 !== 0) {
    throw new Error('Opening hours should be in pairs')
  }

  if (today.length > 2) {
    throw new Error('Cannot handle more than one shift')
  }

  return {
    start: parseDateFromTimeString(now, today[0]),
    end: parseDateFromTimeString(now, today[1]),
  }
}
