import * as qss from 'qss'
import { compilePath } from 'rudy-match-path'

import { ROUTE_NOT_FOUND } from '../routes'
import { Route, RoutesMap } from '../types'

const isNumericString = (value: string) => {
  return /^\d+$/.test(value)
}

/**
 * Finds which registered path pattern matches the given pathname.
 *
 * @param registeredPathPatterns A list of registered path patterns.
 * @param pathname The pathname we are trying to match.
 *
 * @returns A bag of useful data on the matched path pattern.
 */
const findRegisteredPathPattern = (
  registeredPathPatterns: string[],
  pathname: string,
) => {
  return registeredPathPatterns.reduce(
    (
      acc: {
        match: RegExpExecArray
        keys: Array<{ name: string }>
        index: number
      } | null,
      registeredPathPattern,
      index,
    ) => {
      // Already matched?
      if (acc) {
        return acc
      }

      const { re, keys: k } = compilePath(registeredPathPattern, {
        strict: true,
      })

      const match = re.exec(pathname)

      if (match == null) {
        return null
      }

      return {
        match,
        keys: k,
        index,
      }
    },
    null,
  )
}

/**
 * Parses a URL into a route object.
 *
 * @param url The URL to be parsed.
 * @param routesMap The object that maps route names to path patterns.
 *
 * @returns The parsed route.
 */
export const urlToRoute = (url: string, routesMap: RoutesMap): Route => {
  const [pathname, search = ''] = url.split('?')
  const registeredPathPatterns = Object.values(routesMap)

  const result = findRegisteredPathPattern(registeredPathPatterns, pathname)

  // No match? (e.g. user manually typed an invalid URL)
  if (result == null) {
    return {
      pathname,
      name: ROUTE_NOT_FOUND,
      payload: {},
      query: {},
      search,
    }
  }

  // Collect payload (also coerce params)
  const payload = result.keys.reduce((acc, key, index) => {
    // Item at index 0 is the overall match, whereas those after
    // correspond to the key's index
    const paramValue = result.match[index + 1]

    if (typeof paramValue === 'string' && isNumericString(paramValue)) {
      return {
        ...acc,
        [key.name]: Number(paramValue),
      }
    }

    return {
      ...acc,
      [key.name]: paramValue,
    }
  }, {})

  return {
    pathname,
    name: Object.keys(routesMap)[result.index],
    payload,
    query: search ? qss.decode(search) : {},
    search,
  }
}
