/**
 * Formats a CPF.
 *
 * @param value The string to be formatted.
 *
 * @returns The formatted string.
 */
export const formatCpf = (value: string): string => {
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}
