const ONE_HOUR_IN_MS = 60 * 60 * 1000

/**
 * Rounds a Date object to the next nth minute (given as the `coefficient`).
 *
 * @param date The Date object to be rounded.
 * @param coefficient The number of milliseconds.
 *
 * @returns The rounded Date object.
 */
export const roundTime = (date: Date, coefficientMs: number): Date => {
  // Couldn't make this implementation pass a test with coefficient
  // of 2 hours. Maybe something to do with timezones, not sure.
  if (coefficientMs > ONE_HOUR_IN_MS) {
    throw new Error(
      "Current implementation doesn't support coefficients greater than 1 hour",
    )
  }

  const ms = date.getTime()
  const ceiledMs = Math.ceil(ms / coefficientMs) * coefficientMs
  const rounded = new Date(ceiledMs)

  return rounded
}
