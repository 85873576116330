import { Typography } from '@material-ui/core'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { getNextOpeningText, getTakingOrders } from '../redux/selectors'

export const OpeningHoursAlert = memo(() => {
  const isTakingOrders = useSelector(getTakingOrders)
  const nextOpeningText = useSelector(getNextOpeningText)

  if (isTakingOrders || nextOpeningText == null) {
    return null
  }

  return (
    <Typography align="center" gutterBottom variant="h6">
      FECHADO
      <br />
      {nextOpeningText}
      <br />
      {';)'}
    </Typography>
  )
})
