import { DiscountService, OrderService } from '@keffs/core/src/services'
import { fork } from 'redux-saga/effects'

import { applyDiscount } from './apply-discount'
import { clearInvalidChangeFor } from './clear-invalid-change-for'
import { clearInvalidDeliveryTime } from './clear-invalid-delivery-time'
import { clearInvalidDiscount } from './clear-invalid-discount'
import { closeEmptyCart } from './close-empty-cart'
import { processSubmitOrder } from './process-submit-order'
import { scrollDownOnServiceTypeChoosen } from './scroll-down-on-service-type-choosen'
import { scrollUpOnOrderSubmitted } from './scroll-up-on-order-submitted'
import { soundOnOrderSubmitted } from './sound-on-order-submitted'
import { submitPendingOrder } from './submit-pending-order'

export function* cartSaga(
  discountService: DiscountService,
  orderService: OrderService,
) {
  yield fork(applyDiscount, discountService)
  yield fork(clearInvalidChangeFor)
  yield fork(clearInvalidDeliveryTime)
  yield fork(clearInvalidDiscount)
  yield fork(closeEmptyCart)
  yield fork(processSubmitOrder, orderService)
  yield fork(scrollDownOnServiceTypeChoosen)
  yield fork(scrollUpOnOrderSubmitted)
  yield fork(soundOnOrderSubmitted)
  yield fork(submitPendingOrder)
}
