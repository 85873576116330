import { computeIsOrderActive } from '@keffs/data/src/computed'
import { Grow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'

import { getNotificationsPermission, getOrders } from '../redux/selectors'
import { ActiveOrder } from './active-order'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  activeOrder: {
    margin: 16,
  },
})

export const ActiveOrders = memo(() => {
  const classes = useStyles()

  const orders = useSelector(getOrders)
  const notificationsPermission = useSelector(getNotificationsPermission)

  if (orders.length === 0) {
    return null
  }

  return (
    <TransitionGroup className={classes.root}>
      {orders
        .slice()
        .reverse()
        .filter(computeIsOrderActive)
        .map(order => (
          <Grow key={order.id} in>
            <div className={classes.activeOrder}>
              <ActiveOrder
                order={order}
                showNotificationsPrompt={notificationsPermission === 'prompt'}
              />
            </div>
          </Grow>
        ))}
    </TransitionGroup>
  )
})
