import { Theme } from '@material-ui/core'

export const shadows = [...Array(25)].map((e, i) => {
  if (i === 0) {
    return 'none'
  }

  const hOffset1 = 0
  const vOffset1 = i / 2
  const blur1 = 3 + i / 1.5
  const spread1 = -i / 3
  const color1 = `rgba(0, 0, 0, ${0.04 + i / 75})`
  const shadow1 = `${hOffset1}px ${vOffset1}px ${blur1}px ${spread1}px ${color1}`

  const hOffset2 = 0
  const vOffset2 = i
  const blur2 = i * 2
  const spread2 = i / 8
  const color2 = `rgba(0, 0, 0, ${i / 200})`
  const shadow2 = `${hOffset2}px ${vOffset2}px ${blur2}px ${spread2}px ${color2}`

  const hOffset3 = 0
  const vOffset3 = i / 2
  const blur3 = i * 2
  const spread3 = i / 3
  const color3 = `rgba(0, 0, 0, ${i / 200})`
  const shadow3 = `${hOffset3}px ${vOffset3}px ${blur3}px ${spread3}px ${color3}`

  const shadow = `${shadow1}, ${shadow2}, ${shadow3}`

  return shadow
}) as Theme['shadows']
