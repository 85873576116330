import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getDeliveryFee } from './get-delivery-fee'
import { getPackageFee } from './get-package-fee'
import { getSubtotalPrice } from './get-subtotal-price'
import { getTotalDiscount } from './get-total-discount'

export const getTotalPrice = createSelector(
  (state: RootState) => getPackageFee(state),
  (state: RootState) => getDeliveryFee(state),
  (state: RootState) => getSubtotalPrice(state),
  (state: RootState) => getTotalDiscount(state),

  (packageFee, deliveryFee, subtotalPrice, totalDiscount) => {
    return packageFee + deliveryFee + subtotalPrice - totalDiscount
  },
)
