import { BottomSheet } from '@keffs/core/src/components/bottom-sheet'
import { greenThemeFactory } from '@keffs/core/src/styles/mui-theme'
import { formatCurrency } from '@keffs/formatters'
import { Button, MuiThemeProvider, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AuthGuard } from '../components/auth-guard'
import { EditComboDialog } from '../components/edit-combo-dialog'
import { EditProductDialog } from '../components/edit-product-dialog'
import * as actions from '../redux/actions'
import {
  ROUTE_CART,
  ROUTE_CART_AUTH,
  ROUTE_CART_ITEM,
  ROUTE_HOME,
} from '../redux/routes'
import {
  getCartEmpty,
  getCustomer,
  getRoute,
  getServiceType,
  getSubmitting,
  getTakingOrders,
  getTotalPrice,
} from '../redux/selectors'
import { CartCustomer } from './cart-customer'
import { CartDeliveryLocation } from './cart-delivery-location'
import { CartDeliveryTime } from './cart-delivery-time'
import { CartItems } from './cart-items'
import { CartPaymentMethod } from './cart-payment-method'
import { CartServiceType } from './cart-service-type'
import { CartTotals } from './cart-totals'
import { CartVehicle } from './cart-vehicle'
import { ContactUs } from './contact-us'

const useStyles = makeStyles((theme: Theme) => ({
  bottomSheetButton: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    minHeight: 54,
  },
  content: {
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  blocked: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 200,
  },
  form: {
    paddingRight: 16,
    paddingBottom: 32 + 54, // 54px from bottom button
    paddingLeft: 16,
  },
  formClosed: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  section: {
    marginTop: 32,
  },
  submitButton: {
    position: 'fixed',
    right: 0,
    bottom: -100,
    left: 0,
    opacity: 0.87,
    marginTop: 32,
    borderRadius: 0,
  },
}))

export type CartProps = {
  open: boolean
}

export const Cart = memo(({ open }: CartProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const route = useSelector(getRoute)
  const isTakingOrders = useSelector(getTakingOrders)
  const isSubmitting = useSelector(getSubmitting)
  const customer = useSelector(getCustomer)
  const serviceType = useSelector(getServiceType)
  const isCartEmpty = useSelector(getCartEmpty)
  const totalPrice = useSelector(getTotalPrice)

  // Is the user blocked?
  if (customer && customer.blocked) {
    const message =
      'Quero fazer um pedido, ' +
      `mas minha conta está desativada. (ID: ${customer.id})`

    return (
      <div className={classes.blocked}>
        <Typography align="center" gutterBottom>
          Sua conta está desativada.
          <br />
          Entre em contato:
        </Typography>
        <ContactUs message={message} />
      </div>
    )
  }

  const handleChange = (open: boolean) => {
    if (open) {
      dispatch(actions.push({ name: ROUTE_CART }))
    } else {
      dispatch(actions.goBack({ name: ROUTE_HOME }))
    }
  }

  const handleToggle = () => {
    handleChange(!open)
  }

  const handleContentClick = (e: React.MouseEvent<any>) => {
    if (!open) {
      dispatch(actions.push({ name: ROUTE_CART }))
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const handleSubmit = () => {
    dispatch(actions.submitOrderAttempt())
  }

  return (
    <>
      <BottomSheet onChange={handleChange} open={open} visible={!isCartEmpty}>
        <MuiThemeProvider theme={greenThemeFactory}>
          <Button
            id="toggle-cart"
            className={classes.bottomSheetButton}
            color={open ? 'default' : 'primary'}
            fullWidth
            onClick={handleToggle}
            size={open ? 'medium' : 'large'}
            variant={open ? 'text' : 'contained'}
          >
            {open ? 'Voltar ao cardápio' : 'Ver carrinho'}
          </Button>
        </MuiThemeProvider>

        <div className={classes.content} onClickCapture={handleContentClick}>
          <CartItems />
          <CartTotals />
          <div
            className={classNames({
              [classes.form]: true,
              [classes.formClosed]: !isTakingOrders,
            })}
          >
            <div className={classes.section}>
              <CartServiceType />
            </div>

            {serviceType && (
              <div className={classes.section}>
                <CartCustomer />
              </div>
            )}

            {serviceType === 'driveBy' && (
              <div className={classes.section}>
                <CartVehicle />
              </div>
            )}

            {serviceType && serviceType !== 'eatIn' && (
              <div className={classes.section}>
                <CartDeliveryTime />
              </div>
            )}

            {serviceType === 'delivery' && (
              <div className={classes.section}>
                <CartDeliveryLocation />
              </div>
            )}

            {serviceType === 'delivery' && (
              <div className={classes.section}>
                <CartPaymentMethod />
              </div>
            )}

            <MuiThemeProvider theme={greenThemeFactory}>
              <Button
                id="submit-order"
                className={classes.submitButton}
                color="primary"
                disabled={isSubmitting || isCartEmpty}
                fullWidth
                onClick={handleSubmit}
                size="large"
                variant="contained"
              >
                Enviar pedido
                {serviceType ? ` (${formatCurrency(totalPrice)})` : ''}
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      </BottomSheet>

      <EditProductDialog
        id={route.name === ROUTE_CART_ITEM ? route.payload.id : null}
      />
      <EditComboDialog
        id={route.name === ROUTE_CART_ITEM ? route.payload.id : null}
      />
      <AuthGuard open={route.name === ROUTE_CART_AUTH} />
    </>
  )
})
