import { formatCurrency, formatDecimal } from '@keffs/formatters'
import { Paper, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { memo, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

import { ServicesContext } from '../contexts'
import { history } from '../redux/history'
import { getProductCategoriesMap } from '../redux/selectors'
import { ProductWithDiscount } from '../redux/types'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: 16,
    height: '100%',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
  percentage: {
    paddingRight: 4,
    paddingLeft: 4,
    background: '#6abf40',
    fontSize: 12,
    color: 'white',
  },
  priceLineThrough: {
    textDecoration: 'line-through',
    fontSize: 12,
  },
  priceAfterDiscount: {
    color: '#6abf40',
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

export type ProductCardProps = {
  product: ProductWithDiscount
}

export const ProductCard = memo(({ product }: ProductCardProps) => {
  const classes = useStyles()

  const categoriesMap = useSelector(getProductCategoriesMap)

  const { analyticsService } = useContext(ServicesContext)

  const alreadySentImpression = useRef(false)

  const handleClick = () => {
    history.push(`/cardapio/${product.slug}`)

    // Track
    const category = categoriesMap[product.categoryId]
    analyticsService.productClicked({
      productId: product.id,
      productCode: `P${product.code}`,
      productName: product.name,
      productPrice: product.unitPrice,
      categoryName: category.name,
      position: product.position,
    })
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible && !alreadySentImpression.current) {
      alreadySentImpression.current = true

      // Track
      const category = categoriesMap[product.categoryId]
      analyticsService.productViewed({
        productId: product.id,
        productCode: `P${product.code}`,
        productName: product.name,
        productPrice: product.unitPrice,
        categoryName: category.name,
        list: 'Home',
        position: product.position,
      })
    }
  }

  return (
    <VisibilitySensor delayedCall onChange={handleVisibilityChange}>
      <Paper
        data-testid={product.slug}
        className={classes.root}
        onClick={handleClick}
      >
        <div
          style={{
            width: '100%',
            paddingBottom: '100%',
            backgroundImage: `url(${product.image})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            filter: 'brightness(105%)',
          }}
        />

        <div className={classes.info}>
          <Typography align="center">{product.name}</Typography>
          <Typography
            align="center"
            className={classNames({
              [classes.priceLineThrough]: product.discountPercentage > 0,
            })}
            color="textSecondary"
          >
            {formatCurrency(product.unitPrice)}
          </Typography>
          {product.discountPercentage > 0 && (
            <>
              <Typography align="center" className={classes.percentage}>
                -{formatDecimal(product.discountPercentage)}%
              </Typography>
              <Typography align="center" className={classes.priceAfterDiscount}>
                {formatCurrency(product.unitPriceAfterDiscount)}
              </Typography>
            </>
          )}
        </div>
      </Paper>
    </VisibilitySensor>
  )
})
