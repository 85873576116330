import { Product } from '@keffs/data/src/types'

import { ApiService } from './api-service'

export class ProductService extends ApiService {
  async fetchById(id: string): Promise<Product | null> {
    return this.request('GET', `/products/${id}`)
  }

  async fetchAll(): Promise<Product[]> {
    return this.request('GET', '/products')
  }

  async create(data: {
    channels: Product['channels']
    categoryId: Product['categoryId']
    name: Product['name']
    shortName: Product['shortName']
    slug: Product['slug']
    image: Product['image']
    description: Product['description']
    gtin: Product['gtin']
    unitPrice: Product['unitPrice']
    unitPriceForEmployees: Product['unitPriceForEmployees']
  }): Promise<string> {
    const { insertedId } = await this.request('POST', '/products', data)
    return insertedId
  }

  async update(
    id: string,
    data: {
      channels?: Product['channels']
      categoryId?: Product['categoryId']
      name?: Product['name']
      shortName?: Product['shortName']
      slug?: Product['slug']
      image?: Product['image']
      description?: Product['description']
      gtin?: Product['gtin']
      unitPrice?: Product['unitPrice']
      unitPriceForEmployees?: Product['unitPriceForEmployees']
    },
  ): Promise<void> {
    if (Object.keys(data).length === 0) {
      return
    }

    await this.request('PATCH', `/products/${id}`, data)
  }

  async activate(id: string): Promise<void> {
    await this.request('PATCH', `/products/${id}/active`, { active: true })
  }

  async deactivate(id: string): Promise<void> {
    await this.request('PATCH', `/products/${id}/active`, { active: false })
  }

  async setPosition(id: string, position: number): Promise<void> {
    await this.request('PATCH', `/products/${id}/position`, { position })
  }

  async delete(id: string): Promise<void> {
    await this.request('DELETE', `/products/${id}`)
  }
}
