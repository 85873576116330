type Options = {
  allowAsap: boolean
  availableDeliveryTimes: Date[]
}

export const validateDeliveryTime = (
  value: Date | 'asap' | null | undefined,
  { allowAsap, availableDeliveryTimes }: Options,
): string | undefined => {
  if (value == null) {
    return 'Necessário escolher um horário de entrega'
  }

  if (value === 'asap') {
    return allowAsap ? undefined : 'Somente agendamentos por agora'
  }

  return availableDeliveryTimes
    .map(deliveryTime => deliveryTime.toISOString())
    .includes(value.toISOString())
    ? undefined
    : 'Agendamento inválido, escolha outro horário'
}
