import { Customer } from '@keffs/data/src/types'
import { getType } from 'typesafe-actions'

import {
  authInitialized,
  signInAttempt,
  signInError,
  signInSuccess,
  signOutAttempt,
  signOutError,
  signOutSuccess,
} from '../actions'
import { RootAction } from '../types'

export type State = {
  readonly loading: boolean
  readonly customer: Customer | null
  readonly errorMessage: string | null
}

export const initialState: State = {
  loading: true,
  customer: null,
  errorMessage: null,
}

export const authReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(authInitialized): {
      return { ...state, loading: false, customer: action.payload }
    }

    case getType(signInAttempt): {
      return { ...state, loading: true, errorMessage: null }
    }
    case getType(signInSuccess): {
      return { ...state, loading: false, customer: action.payload }
    }
    case getType(signInError): {
      return { ...state, loading: false, errorMessage: action.payload }
    }

    case getType(signOutAttempt): {
      return { ...state, loading: true, errorMessage: null }
    }
    case getType(signOutSuccess): {
      return { ...state, loading: false, customer: null }
    }
    case getType(signOutError): {
      return { ...state, loading: false, errorMessage: action.payload }
    }

    default: {
      return state
    }
  }
}
