import { CpfField } from '@keffs/core/src/components/cpf-field'
import { PhoneField } from '@keffs/core/src/components/phone-field'
import { formatCpf, formatPhoneNumber } from '@keffs/formatters'
import { Avatar, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import {
  getCpf,
  getCpfError,
  getCpfTouched,
  getCustomer,
  getName,
  getPhoneNumber,
  getPhoneNumberError,
  getPhoneNumberTouched,
  getServiceType,
} from '../redux/selectors'

const useStyles = makeStyles({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 12,
    width: 36,
    height: 36,
  },
  cpfButton: {
    display: 'block',
    marginLeft: 'auto',
  },
})

export const CartCustomer = memo(() => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const serviceType = useSelector(getServiceType)
  const customer = useSelector(getCustomer)
  const phoneNumber = useSelector(getPhoneNumber)
  const phoneNumberError = useSelector(getPhoneNumberError)
  const phoneNumberTouched = useSelector(getPhoneNumberTouched)
  const name = useSelector(getName)
  const cpf = useSelector(getCpf)
  const cpfError = useSelector(getCpfError)
  const cpfTouched = useSelector(getCpfTouched)

  const [preferFromCustomer, setPreferFromCustomer] = useState(false)
  const [showCpfField, setShowCpfField] = useState(false)

  useEffect(() => {
    if (customer) {
      // Customer loaded
      setPreferFromCustomer(true)
    } else {
      // No customer
      setPreferFromCustomer(false)
    }
  }, [customer])

  const handleEditClick = () => {
    setPreferFromCustomer(false)
    setShowCpfField(true)
  }

  const handleEditCpfClick = () => {
    setShowCpfField(true)
  }

  const handlePhoneNumberBlur = () => {
    if (!phoneNumberTouched) {
      dispatch(actions.touchPhoneNumber())
    }
  }

  const handleCpfBlur = () => {
    if (!cpfTouched) {
      dispatch(actions.touchCpf())
    }
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setPhoneNumber(e.target.value))
  }

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setCpf(e.target.value.replace(/\W/g, '')))
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        Contato
      </Typography>

      {customer && customer.displayName && (
        <div className={classes.horizontal}>
          <Avatar
            alt=""
            className={classes.avatar}
            src={customer.photoUrl || undefined}
          >
            {!customer.photoUrl || customer.displayName[0]}
          </Avatar>

          <div>
            <Typography variant="subtitle1">{name}</Typography>

            {preferFromCustomer && (
              <>
                {customer.phoneNumber && (
                  <Typography variant="body2">
                    {formatPhoneNumber(customer.phoneNumber)}
                  </Typography>
                )}
                {customer.cpf && (
                  <Typography variant="body2">
                    {formatCpf(customer.cpf)}
                  </Typography>
                )}
              </>
            )}
          </div>

          {preferFromCustomer && (
            <Button
              className={classes.cpfButton}
              onClick={handleEditClick}
              size="small"
            >
              Editar
            </Button>
          )}
        </div>
      )}

      {(!preferFromCustomer ||
        customer == null ||
        customer.phoneNumber == null) && (
        <PhoneField
          autoComplete="nope"
          error={Boolean(phoneNumberTouched && phoneNumberError)}
          fullWidth
          helperText={phoneNumberTouched && phoneNumberError}
          id="cart-phone-number"
          margin="normal"
          onBlur={handlePhoneNumberBlur}
          onChange={handlePhoneNumberChange}
          placeholder={
            '(XX) 9XXXX-XXXX' + (serviceType === 'eatIn' ? ' (opcional)' : '')
          }
          value={phoneNumber}
        />
      )}

      {(!preferFromCustomer || customer == null || customer.cpf == null) &&
        (showCpfField ? (
          <CpfField
            autoComplete="nope"
            error={Boolean(cpfTouched && cpfError)}
            fullWidth
            helperText={cpfTouched && cpfError}
            id="cart-cpf"
            margin="normal"
            onBlur={handleCpfBlur}
            onChange={handleCpfChange}
            placeholder="Seu CPF (opcional)"
            value={cpf}
          />
        ) : (
          <Button
            id="show-cpf-field"
            className={classes.cpfButton}
            onClick={handleEditCpfClick}
            size="small"
          >
            CPF na nota?
          </Button>
        ))}
    </>
  )
})
