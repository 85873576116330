import { AuthService, CustomerService } from '@keffs/core/src/services'
import { IdentityProvider } from '@keffs/data/src/types'
import { eventChannel } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../../actions'

const setupAuthStateEventChannel = (
  authService: AuthService,
  customerService: CustomerService,
) => {
  return eventChannel(emit => {
    let authed = false

    // Detect sign-up
    void authService.getRedirectResult().then(result => {
      if (
        result &&
        result.user &&
        result.additionalUserInfo &&
        result.additionalUserInfo.isNewUser
      ) {
        emit(
          actions.signedUp({
            identityProvider: result.user.providerId as IdentityProvider,
          }),
        )
      }
    })

    // Watch auth state
    return authService.onAuthStateChanged(
      async user => {
        if (user == null) {
          emit(actions.authInitialized(null))

          // If the user was previously authenticated, then this is a
          // sign-out, otherwise it's just Firebase telling us that the
          // auth state is `null` (neither signed-in nor signed-out).
          if (authed) {
            authed = false
            emit(actions.signOutSuccess())
          }

          return
        }

        authed = true

        try {
          // If the customer profile already exists, emit sign-in action
          const customer = await customerService.fetchByFirebaseUid(user.uid)
          if (customer) {
            emit(actions.authInitialized(customer))
            emit(actions.signInSuccess(customer))
            return
          }

          // Customer profile NOT found, so we need to create it
          await customerService.create({
            firebaseUid: user.uid,
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoUrl: user.photoURL,
            cpf: null,
          })

          // Refetch now that we have created it
          const createdCustomer = await customerService.fetchByFirebaseUid(
            user.uid,
          )
          if (!createdCustomer) {
            emit(
              actions.signInError(
                "Just created the customer profile, should be able to fetch it. Something's broken.",
              ),
            )
            return
          }

          // Done
          emit(actions.authInitialized(createdCustomer))
          emit(actions.signInSuccess(createdCustomer))
        } catch (error) {
          if (error.name === 'NetworkError') {
            emit(actions.signInError('Verifique sua conexão'))
          } else {
            throw error
          }
        }
      },
      error => {
        emit(actions.signInError(error.message))
        emit(actions.showSnackbar('Falha na autenticação'))
      },
    )
  })
}

export function* authState(
  authService: AuthService,
  customerService: CustomerService,
) {
  // Watch and dispatch any auth state changes
  const authStateEventChannel = yield call(
    setupAuthStateEventChannel,
    authService,
    customerService,
  )
  yield takeLatest(authStateEventChannel, function* (action) {
    yield put(action)
  })

  // Watch intent to sign-in
  yield takeLatest(getType(actions.signInAttempt), function* (
    action: ActionType<typeof actions.signInAttempt>,
  ) {
    if (action.payload.provider === 'facebook') {
      yield call([authService, authService.signInWithFacebook])
    } else if (action.payload.provider === 'google') {
      yield call([authService, authService.signInWithGoogle])
    }
  })

  // Watch intent to sign-out
  yield takeLatest(getType(actions.signOutAttempt), function* () {
    yield call([authService, authService.signOut])
  })
}
