import { Banner as BannerType } from '@keffs/data/src/types'
import { Theme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery/unstable_useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import React, { memo, useContext, useRef } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

import { ServicesContext } from '../contexts'
import { history } from '../redux/history'

const useStyles = makeStyles({
  banner: {
    display: 'block',
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    objectFit: 'cover',
  },
})

export type BannerProps = {
  banner: BannerType
  imageDesktop: string
  imageMobile: string
  position: number
}

export const Banner = memo(
  ({ banner, imageDesktop, imageMobile, position }: BannerProps) => {
    const theme = useTheme<Theme>()
    const showWideBanners = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles()

    const { analyticsService } = useContext(ServicesContext)

    const alreadySentImpression = useRef(false)

    const handleVisibilityChange = (isVisible: boolean) => {
      if (!isVisible || alreadySentImpression.current === true) {
        return
      }

      alreadySentImpression.current = true

      // Track
      analyticsService.bannerViewed({
        bannerId: banner.id,
        bannerName: banner.name,
        position: `Home Top ${position}`,
      })
    }

    const handleClick = () => {
      history.push(banner.href)

      // Track
      analyticsService.bannerClicked({
        bannerId: banner.id,
        bannerName: banner.name,
        position: `Home Top ${position}`,
      })
    }

    return (
      <VisibilitySensor delayedCall onChange={handleVisibilityChange}>
        <img
          alt={banner.name}
          className={classes.banner}
          draggable={false}
          onClick={handleClick}
          src={showWideBanners ? imageDesktop : imageMobile}
        />
      </VisibilitySensor>
    )
  },
)
