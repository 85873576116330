import { Customer, Discount } from '../types'

type Props = {
  clock: Date
  customer: Customer
  discount: Discount
}

export const validateDiscount = ({
  clock,
  customer,
  discount,
}: Props): string | undefined => {
  // Inactive?
  if (!discount.active) {
    return 'Este desconto está desativado'
  }

  // Before duration start?
  if (clock < discount.start) {
    return 'Este desconto ainda não entrou em vigência'
  }

  // After duration end?
  if (clock > discount.end) {
    return 'Este desconto já expirou'
  }

  // Exceeded maxUsesTotal?
  if (discount.totalUses >= discount.maxUsesTotal) {
    return 'Este desconto já acabou'
  }

  // Exceeded maxUsesByCustomer?
  if (customer.usedDiscounts[discount.id] >= discount.maxUsesByCustomer) {
    return 'O seu limite de usos deste desconto já esgotou'
  }

  return undefined
}
