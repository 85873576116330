import { Discount } from '../types'

/**
 * Computes a legal text based on the discount properties.
 *
 * @param discount The discount.
 *
 * @returns The generated legal text.
 */
export const computeDiscountLegalText = (discount: Discount): string => {
  const parts: string[] = [
    `Válido por tempo limitado apenas para as seguintes modalidades: ${[
      discount.serviceTypes.includes('eatIn') ? '"Comer na loja"' : null,
      discount.serviceTypes.includes('takeOut') ? '"Buscar para viagem"' : null,
      discount.serviceTypes.includes('delivery') ? '"Delivery"' : null,
    ]
      .filter(Boolean)
      .join(', ')}.`,
  ]

  if (discount.type === 'percentage') {
    parts.push('Não se aplica a combos e taxa de embalagem/entrega.')
  }

  return parts.join(' ')
}
