import emojiWink from '@keffs/core/src/assets/emojis/wink.png'
import { Emoji } from '@keffs/core/src/components/emoji'
import { NonModalDialog } from '@keffs/core/src/components/non-modal-dialog'
import { greenThemeFactory } from '@keffs/core/src/styles/mui-theme'
import { Order } from '@keffs/data/src/types'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  MuiThemeProvider,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import firebase from 'firebase/app'
import React, { memo, useState } from 'react'

import { history } from '../redux/history'
import { ContactUs } from './contact-us'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '0 0 330px',
    overflow: 'visible',
  },
  orderCode: {
    display: 'inline',
    marginLeft: 8,
    fontSize: 22,
    color: theme.palette.text.hint,
  },
  notificationsDialog: {
    marginTop: 8,
    marginRight: -8,
    marginBottom: 8,
    marginLeft: -8,
  },
  stepper: {
    padding: 0,
  },
  contactUs: {
    '&:last-child': {
      paddingBottom: 16,
    },
  },
}))

export type ActiveOrderProps = {
  order: Order
  showNotificationsPrompt: boolean
}

export const ActiveOrder = memo(
  ({ order, showNotificationsPrompt }: ActiveOrderProps) => {
    const classes = useStyles()

    const [ignoreNotifications, setIgnoreNotifications] = useState(false)

    let activeStep = 0

    if (order.status === 'inProduction') {
      activeStep = 1
    } else if (order.status === 'ready') {
      activeStep = 2
    } else if (order.status === 'onTheWay') {
      activeStep = 3
    }

    const handleIgnoreClick = () => {
      setIgnoreNotifications(true)
    }

    const handleActivateClick = async () => {
      await firebase.messaging().requestPermission()
    }

    return (
      <Card className={classes.root}>
        <CardHeader
          title={
            <>
              Pedido
              <Typography
                className={classes.orderCode}
                color="textSecondary"
                component="span"
                variant="h6"
              >
                {order.code}
              </Typography>
            </>
          }
          titleTypographyProps={{ variant: 'title' }}
        />
        <NonModalDialog
          className={classes.notificationsDialog}
          show={showNotificationsPrompt && !ignoreNotifications}
          text="Quer receber notificação quando mudar de status?"
          actions={
            <>
              <Button color="secondary" onClick={handleIgnoreClick}>
                Ignorar
              </Button>
              <Button color="secondary" onClick={handleActivateClick}>
                Ativar notificação
              </Button>
            </>
          }
        />
        <CardContent>
          <MuiThemeProvider theme={greenThemeFactory}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              orientation="vertical"
            >
              <Step>
                <StepLabel>Pedido na fila</StepLabel>
              </Step>
              <Step>
                <StepLabel>Em produção</StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  {order.serviceType === 'delivery'
                    ? 'Aguardando entregador'
                    : 'Aguardando você buscar'}
                </StepLabel>
              </Step>
              {order.serviceType === 'delivery' && (
                <Step>
                  <StepLabel>Entregador está a caminho</StepLabel>
                </Step>
              )}
            </Stepper>
          </MuiThemeProvider>
        </CardContent>
        <CardActions disableActionSpacing>
          <Button onClick={() => history.push(`/pedidos/${order.id}`)}>
            Ver detalhes do pedido
          </Button>
        </CardActions>
        <Divider light />
        <CardContent className={classes.contactUs}>
          <Typography>
            Qualquer coisinha, fala com a gente, tá? <Emoji src={emojiWink} />
          </Typography>
          <ContactUs message={`Olá, fiz um pedido (código: ${order.code})`} />
        </CardContent>
      </Card>
    )
  },
)
