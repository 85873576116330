import { AuthService, CustomerService } from '@keffs/core/src/services'
import { fork } from 'redux-saga/effects'

import { authState } from './auth-state'
import { handleRedirectResult } from './handle-redirect-result'
import { linkFcmTokenToCustomer } from './link-fcm-token-to-customer'
import { screenRecorderUser } from './screen-recorder-user'
import { showSnackbarOnSignIn } from './show-snackbar-on-sign-in'

export function* authSaga(
  authService: AuthService,
  customerService: CustomerService,
) {
  yield fork(authState, authService, customerService)
  yield fork(handleRedirectResult, authService)
  yield fork(linkFcmTokenToCustomer, customerService)
  yield fork(screenRecorderUser)
  yield fork(showSnackbarOnSignIn)
}
