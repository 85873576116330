import { addDays, format } from 'date-fns'

import { Restaurant } from '../types'

/**
 * Computes when we will open again.
 *
 * @param now The current time.
 * @param openingHours The opening hours.
 *
 * @returns The Date object of the next opening or null in case
 * permanently closed.
 */
export const computeNextOpening = (
  now: Date,
  openingHours: Restaurant['openingHours'],
): Date | null => {
  // For each of the next seven days (including today)...
  for (
    let current = addDays(now, 0);
    current < addDays(now, 8);
    current = addDays(current, 1)
  ) {
    const day = openingHours[current.getDay()]

    // Skip this day if closed
    if (day.length === 0) {
      continue
    }

    // Skip this day if it's starting time is in the past
    const start = new Date(format(current, 'yyyy-MM-dd') + 'T' + day[0])
    if (start < now) {
      continue
    }

    // We didn't skip, so this is what we are looking for
    return start
  }

  // Nothing found in the previous loop
  return null
}
