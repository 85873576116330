import { createSelector } from 'reselect'

import { RootState } from '../types'
import { getOrdersMap } from './get-orders-map'

export const getOrders = createSelector(
  (state: RootState) => getOrdersMap(state),

  ordersMap => {
    return Object.keys(ordersMap)
      .map(id => ordersMap[id])
      .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
  },
)
