import { IconButton, Theme, Typography } from '@material-ui/core'
import {
  Add as MdAdd,
  AddCircle as MdAddCircle,
  Remove as MdRemove,
  RemoveCircle as MdRemoveCircle,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  valueArea: {
    minWidth: 24,
  },
  valueAreaLarge: {
    minWidth: 48,
  },
  value: {
    fontSize: 24,
  },
  valueZero: {
    color: theme.palette.divider,
  },
  valueLarge: {
    fontSize: 32,
    fontWeight: 'bold',
  },
}))

export type QuantityFieldProps = {
  max?: number
  min?: number
  onChange: (quantity: number) => void
  size?: 'default' | 'large'
  value: number
  variant?: 'normal' | 'circle'
}

export const QuantityField = ({
  max = Infinity,
  min = -Infinity,
  onChange,
  size = 'default' as const,
  value,
  variant = 'normal',
}: QuantityFieldProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <IconButton
        data-testid="decrease-quantity"
        color="primary"
        disabled={value === min}
        onClick={() => onChange(Math.max(value - 1, min))}
      >
        {variant === 'circle' ? (
          <MdRemoveCircle fontSize={size} />
        ) : (
          <MdRemove fontSize={size} />
        )}
      </IconButton>

      <div
        className={classNames({
          [classes.valueArea]: true,
          [classes.valueAreaLarge]: size === 'large',
        })}
      >
        <Typography
          align="center"
          className={classNames({
            [classes.value]: true,
            [classes.valueZero]: value === 0,
            [classes.valueLarge]: size === 'large',
          })}
          variant="subtitle1"
        >
          {value}
        </Typography>
      </div>

      <IconButton
        data-testid="increase-quantity"
        color="primary"
        disabled={value === max}
        onClick={() => onChange(Math.min(value + 1, max))}
      >
        {variant === 'circle' ? (
          <MdAddCircle fontSize={size} />
        ) : (
          <MdAdd fontSize={size} />
        )}
      </IconButton>
    </div>
  )
}
