import { validateChangeFor } from '@keffs/data/src/validators'
import { put, select, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'
import { getChangeFor, getTotalPrice } from '../../selectors'

/**
 * Unsets change for when it becomes invalid, e.g.: total price became
 * greater than it.
 */
export function* clearInvalidChangeFor() {
  // Take any action that may change totalPrice
  while (true) {
    yield take([
      getType(actions.setServiceType),
      getType(actions.addItem),
      getType(actions.updateItem),
    ])

    // Empty changeFor field? Abort
    const changeFor: ReturnType<typeof getChangeFor> = yield select(
      getChangeFor,
    )
    if (changeFor == null) {
      continue
    }

    // Did changeFor become invalid?
    const totalPrice: ReturnType<typeof getTotalPrice> = yield select(
      getTotalPrice,
    )
    const changeForErrorMessage = validateChangeFor(changeFor, totalPrice)
    if (changeForErrorMessage) {
      yield put(actions.clearPaymentMethod())
    }
  }
}
