import {
  computeDiscountLegalText,
  computeDiscountTitle,
} from '@keffs/data/src/computed'
import { Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import random from 'lodash/random'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { getAppliedDiscount, getIsDiscountStale } from '../redux/selectors'

const GIF_LIST = [
  'https://giphy.com/embed/DYH297XiCS2Ck',
  // 'https://giphy.com/embed/3NtY188QaxDdC',
  // 'https://giphy.com/embed/ns8FcEX37Ha5G',
  // 'https://giphy.com/embed/ZFTKZ8zwj38gE',
  // 'https://giphy.com/embed/b5LTssxCLpvVe',
  // 'https://giphy.com/embed/3o7abldj0b3rxrZUxW',
  // 'https://giphy.com/embed/11sBLVxNs7v6WA'
]

const gif = GIF_LIST[random(0, GIF_LIST.length - 1)]

const useStyles = makeStyles({
  root: {
    margin: 16,
  },
  discount: {
    marginRight: 'auto',
    marginLeft: 'auto',
    outline: '3px dotted #6abf40',
    padding: 16,
    width: 300,
    maxWidth: '80%',
    backgroundColor: '#6abf40',
    color: 'white',
  },
})

export const AppliedDiscount = memo(() => {
  const classes = useStyles()

  const isDiscountStale = useSelector(getIsDiscountStale)
  const discount = useSelector(getAppliedDiscount)

  if (isDiscountStale || discount == null) {
    return null
  }

  return (
    <div className={classes.root}>
      <iframe
        title="GIF do desconto"
        frameBorder="0"
        src={gif}
        height="124"
        style={{
          position: 'relative',
          display: 'block',
          margin: '0 auto',
          zIndex: -1,
        }}
        width="160"
      />
      <Card className={classes.discount} elevation={8} square>
        <Typography align="center" color="inherit" variant="body1">
          Desconto aplicado:
        </Typography>
        <Typography align="center" color="inherit" gutterBottom variant="h6">
          {computeDiscountTitle(discount)}
        </Typography>
        <Typography align="center" color="inherit" variant="caption">
          {computeDiscountLegalText(discount)}
        </Typography>
      </Card>
    </div>
  )
})
