import { call, delay, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import * as actions from '../../actions'

const scrollBy = (cartEl: Element) => {
  // Not all browsers support scrollBy
  if ('scrollBy' in cartEl) {
    cartEl.scrollBy({ top: 1000, behavior: 'smooth' })
  }
}

/**
 * Scrolls down for UX when service type is first choosen.
 */
export function* scrollDownOnServiceTypeChoosen() {
  yield take(getType(actions.setServiceType))

  // Delay a bit to wait re-render
  yield delay(100)

  const a = document.getElementsByClassName('ReactSwipeableBottomSheet--open')
  const b = document.getElementsByClassName('ReactSwipeableBottomSheet--closed')
  const cartEl = a[0] || b[0]

  yield call(scrollBy, cartEl)
}
