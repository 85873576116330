import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
  },
})

export const NotFoundScreen = memo(() => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="body1">Não encontrado</Typography>
    </div>
  )
})
