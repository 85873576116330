import firebase from 'firebase/app'

type ProviderId = 'facebook.com' | 'google.com'

export class AuthService {
  static getProviderByProviderId(providerId: ProviderId) {
    return {
      'facebook.com': firebase.auth.FacebookAuthProvider,
      'google.com': firebase.auth.GoogleAuthProvider,
    }[providerId]
  }

  private readonly auth: firebase.auth.Auth

  constructor() {
    this.auth = firebase.auth()
    this.auth.languageCode = 'pt'
  }

  onAuthStateChanged(
    handler: (user: firebase.User | null) => any,
    errorHandler?: (error: firebase.auth.Error) => any,
  ): firebase.Unsubscribe {
    return this.auth.onAuthStateChanged(handler, errorHandler)
  }

  getRedirectResult(): Promise<firebase.auth.UserCredential> {
    return this.auth.getRedirectResult()
  }

  async signInWithGoogle(): Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider()
    await this.auth.signInWithRedirect(provider)
  }

  async signInWithFacebook(): Promise<void> {
    const provider = new firebase.auth.FacebookAuthProvider()
    await this.auth.signInWithRedirect(provider)
  }

  /**
   * Given the email, fetches which sign-in methods a user has already
   * used and tries to authenticate through one of them.
   *
   * @param email The email address.
   */
  async signInWithExistentCredentialForEmail(email: string): Promise<void> {
    const methods = await this.auth.fetchSignInMethodsForEmail(email)
    const preferredMethod = methods[0] as ProviderId
    const Provider = AuthService.getProviderByProviderId(preferredMethod)
    await this.auth.signInWithRedirect(new Provider())
  }

  async signOut(): Promise<void> {
    await this.auth.signOut()
  }
}
