import {
  Channel,
  DiscountType,
  IdentityProvider,
  PaymentMethod,
  ServiceType,
} from '@keffs/data/src/types'
import { dedupe } from '@keffs/data/src/utils'

import { FacebookPixelService } from './facebook-pixel-service'
import {
  GA_STEP_CART_OPENED,
  GA_STEP_CHECKOUT_INITIATED,
  GoogleAnalyticsService,
} from './google-analytics-service'
import { normalizePathname } from './utils'

const throwIfInvalidProduct = (
  params:
    | { productCode: string; categoryName: string }
    | Array<{ productCode: string; categoryName: string }>,
): void => {
  const items = Array.isArray(params) ? params : [params]

  items.forEach(item => {
    if (!/^(P|C)\d+/g.test(item.productCode)) {
      throw new Error(
        'Expected `productCode` to be prefixed with either "P" for plain product or "C" for combo',
      )
    }

    if (item.productCode.startsWith('C') && item.categoryName !== 'Combo') {
      throw new Error(
        'Expected `categoryName` to be "Combo" as `productCode` is prefixed with "C"',
      )
    }
  })
}

/**
 * Service that unifies the interaction with all analytics platforms
 * through common methods.
 */
export class AnalyticsService {
  private facebookPixelService: FacebookPixelService
  private googleAnalyticsService: GoogleAnalyticsService

  constructor(options: { debug: boolean }) {
    this.facebookPixelService = new FacebookPixelService(options)
    this.googleAnalyticsService = new GoogleAnalyticsService(options)
  }

  /**
   * Tracks when the user first signs up.
   */
  signedUp(params: { identityProvider: IdentityProvider }): void {
    // Facebook Pixel
    this.facebookPixelService.completeRegistration()
  }

  /**
   * Tracks when the user signs in.
   */
  signedIn(params: {
    uid: string
    name: string | null
    phoneNumber: string | null
    photoUrl: string | null
    email: string | null
  }): void {
    // Empty.
  }

  /**
   * Tracks any page visit.
   */
  pageVisited(params: { pathname: string }): void {
    // Google Analytics
    this.googleAnalyticsService.pageView({
      normalizedPathname: normalizePathname(params.pathname),
    })
  }

  /**
   * Tracks when a banner appears on the screen (sometimes called impression).
   */
  bannerViewed(params: {
    bannerId: string
    bannerName: string
    position: string
  }): void {
    // Google Analytics
    this.googleAnalyticsService.promoImpression({
      id: params.bannerId,
      name: params.bannerName,
      position: params.position,
    })
  }

  /**
   * Tracks when a banner is clicked.
   */
  bannerClicked(params: {
    bannerId: string
    bannerName: string
    position: string
  }): void {
    // Google Analytics
    this.googleAnalyticsService.promoClick({
      id: params.bannerId,
      name: params.bannerName,
      position: params.position,
    })
  }

  /**
   * Tracks when a product appears on the screen (sometimes called impression).
   */
  productViewed(params: {
    productId: string
    productCode: string
    productName: string
    productPrice: number
    categoryName: string
    list: string
    position: number
  }): void {
    throwIfInvalidProduct(params)

    // Google Analytics
    this.googleAnalyticsService.productImpression({
      id: params.productId,
      name: params.productName,
      price: params.productPrice,
      category: params.categoryName,
      list: params.list,
      position: params.position,
    })
  }

  /**
   * Tracks when a product is clicked.
   */
  productClicked(params: {
    productId: string
    productCode: string
    productName: string
    productPrice: number
    categoryName: string
    position: number
  }): void {
    throwIfInvalidProduct(params)

    // Google Analytics
    this.googleAnalyticsService.productClick({
      id: params.productId,
      name: params.productName,
      price: params.productPrice,
      category: params.categoryName,
      position: params.position,
    })
  }

  /**
   * Tracks when a product page is visited.
   */
  productDetailsViewed(params: {
    productId: string
    productCode: string
    productName: string
    productPrice: number
    categoryName: string
  }): void {
    throwIfInvalidProduct(params)

    // Facebook Pixel
    this.facebookPixelService.viewContent({
      id: params.productId,
    })

    // Google Analytics
    this.googleAnalyticsService.productDetails({
      id: params.productId,
      name: params.productName,
      price: params.productPrice,
      category: params.categoryName,
    })
  }

  /**
   * Tracks when a discount is applied to the cart.
   */
  discountApplied(params: {
    discountType: DiscountType
    discountValue: number
  }): void {
    //
  }

  /**
   * Tracks when a product is added to the cart.
   */
  addedToCart(params: {
    changeInQuantity: number
    resultingQuantity: number
    productId: string
    productCode: string
    productName: string
    productPrice: number
    categoryName: string
    cart: Array<{
      quantity: number
      productId: string
      productCode: string
      productName: string
      productPrice: number
      categoryName: string
    }>
  }): void {
    throwIfInvalidProduct(params)
    throwIfInvalidProduct(params.cart)

    const dedupedCart = dedupe({
      items: params.cart,
      similarKey: 'productId',
      mergeKey: 'quantity',
    })

    // Facebook Pixel
    this.facebookPixelService.addToCart({
      cart: dedupedCart.map(item => ({
        quantity: item.quantity,
        id: item.productId,
        price: item.productPrice,
      })),
    })

    // Google Analytics
    this.googleAnalyticsService.addProduct({
      quantity: params.changeInQuantity,
      id: params.productId,
      name: params.productName,
      price: params.productPrice,
      category: params.categoryName,
    })
  }

  /**
   * Tracks when a product is removed from the cart.
   */
  removedFromCart(params: {
    changeInQuantity: number
    productId: string
    productCode: string
    productName: string
    productPrice: number
    categoryName: string
  }): void {
    throwIfInvalidProduct(params)

    // Google Analytics
    this.googleAnalyticsService.removeProduct({
      quantity: params.changeInQuantity,
      id: params.productId,
      name: params.productName,
      price: params.productPrice,
      category: params.categoryName,
    })
  }

  /**
   * Tracks when the user first opens the cart.
   */
  cartOpened(params: {
    cart: Array<{
      quantity: number
      productId: string
      productCode: string
      productName: string
      productPrice: number
      categoryName: string
    }>
  }): void {
    throwIfInvalidProduct(params.cart)

    const dedupedCart = dedupe({
      items: params.cart,
      similarKey: 'productId',
      mergeKey: 'quantity',
    })

    // Google Analytics
    this.googleAnalyticsService.checkoutStep({
      step: GA_STEP_CART_OPENED,
      cart: dedupedCart.map(item => ({
        quantity: item.quantity,
        id: item.productId,
        name: item.productName,
        price: item.productPrice,
        category: item.categoryName,
      })),
    })
  }

  /**
   * Tracks when the user starts to fill the checkout form.
   */
  checkoutInitiated(params: {
    cart: Array<{
      quantity: number
      productId: string
      productCode: string
      productName: string
      productPrice: number
      categoryName: string
    }>
  }): void {
    throwIfInvalidProduct(params.cart)

    const dedupedCart = dedupe({
      items: params.cart,
      similarKey: 'productId',
      mergeKey: 'quantity',
    })

    // Facebook Pixel
    this.facebookPixelService.initiateCheckout()

    // Google Analytics
    this.googleAnalyticsService.checkoutStep({
      step: GA_STEP_CHECKOUT_INITIATED,
      cart: dedupedCart.map(item => ({
        quantity: item.quantity,
        id: item.productId,
        name: item.productName,
        price: item.productPrice,
        category: item.categoryName,
      })),
    })
  }
  /**
   * Tracks when the user ends to fill the checkout form (ready to submit the order).
   */
  checkoutReady(): void {
    //
  }

  /**
   * Tracks when the order is submitted with success.
   */
  purchased(params: {
    restaurantCode: string
    restaurantCity: string
    orderId: string
    attendantCode?: string
    attendantName?: string
    serviceType: ServiceType
    channel: Channel
    discountId?: string
    discountType?: DiscountType
    discountValue?: number
    paymentMethod?: PaymentMethod
    cart: Array<{
      quantity: number
      productId: string
      productCode: string
      productName: string
      productPrice: number
      categoryName: string
    }>
    revenue: number
    discountGiven: number
  }): void {
    throwIfInvalidProduct(params.cart)

    const dedupedCart = dedupe({
      items: params.cart,
      similarKey: 'productId',
      mergeKey: 'quantity',
    })

    // Facebook Pixel
    this.facebookPixelService.purchase({
      cart: dedupedCart.map(item => ({
        quantity: item.quantity,
        id: item.productId,
        price: item.productPrice,
      })),
    })

    // Google Analytics
    this.googleAnalyticsService.purchase({
      orderId: params.orderId,
      revenue: params.revenue,
      coupon: params.discountId,
      cart: dedupedCart.map(item => ({
        quantity: item.quantity,
        id: item.productId,
        name: item.productName,
        price: item.productPrice,
        category: item.categoryName,
      })),
    })
  }

  webPushEnabled(): void {
    //
  }
}
