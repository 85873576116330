import { OrderService } from '@keffs/core/src/services'
import { Order } from '@keffs/data/src/types'
import { Pusher } from 'pusher-js'
import { eventChannel } from 'redux-saga'
import { call, cancel, fork, put, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actions'

const setupOrdersEventChannel = (
  orderService: OrderService,
  pusher: Pusher,
  customerId: string,
) => {
  return eventChannel(emit => {
    // Fetch initial data
    void orderService
      .fetchActiveByCustomer(customerId)
      .then((orders: Order[]) => {
        emit(actions.ordersLoaded(orders))
      })

    const makeHandler = (action: any) => async (orderId: string) => {
      const order = await orderService.fetchById(orderId)
      emit(action(order))
    }

    // Subscribe
    const channelName = customerId
      ? `private-orders-${customerId}`
      : 'private-orders'
    const channel = pusher.subscribe(channelName)
    channel.bind('created', makeHandler(actions.orderCreated))
    channel.bind('updated', makeHandler(actions.orderUpdated))

    // Return unsubscriber
    return () => pusher.unsubscribe(channelName)
  })
}

export function* watchOrderEvents(
  orderService: OrderService,
  pusher: Pusher,
  customerId: string,
) {
  // Watch and dispatch order events coming from Pusher
  const ordersEventChannel = yield call(
    setupOrdersEventChannel,
    orderService,
    pusher,
    customerId,
  )
  try {
    while (true) {
      const action = yield take(ordersEventChannel)

      yield put(action)
    }
  } finally {
    ordersEventChannel.close()
  }
}

export function* subscribeToCustomerOrders(
  orderService: OrderService,
  pusher: Pusher,
) {
  // Subscribe on sign-in
  while (true) {
    const action: ActionType<typeof actions.signInSuccess> = yield take(
      getType(actions.signInSuccess),
    )

    const customerId = action.payload.id
    const task = yield fork(watchOrderEvents, orderService, pusher, customerId)

    // Unsubscribe on sign-out
    yield take(getType(actions.signOutSuccess))
    yield cancel(task)
  }
}
