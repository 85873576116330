import {
  getCombosMap,
  getItems,
  getProductCategoriesMap,
  getProductsMap,
} from '../../../selectors'

/**
 * A helper that returns the `cart` in the format that AnalyticsService
 * wants.
 */
export const redactCart = (
  items: ReturnType<typeof getItems>,
  categoriesMap: ReturnType<typeof getProductCategoriesMap>,
  productsMap: ReturnType<typeof getProductsMap>,
  combosMap: ReturnType<typeof getCombosMap>,
): Array<{
  quantity: number
  productId: string
  productCode: string
  productName: string
  productPrice: number
  categoryName: string
}> => {
  return items.map(item => {
    if ('productId' in item) {
      // Product item
      const product = productsMap[item.productId]
      const category = categoriesMap[product.categoryId]
      return {
        quantity: item.quantity,
        productId: product.id,
        productCode: `P${product.code}`,
        productName: product.name,
        productPrice: product.unitPrice,
        categoryName: category.name,
      }
    } else {
      // Combo item
      const combo = combosMap[item.comboId]
      return {
        quantity: item.quantity,
        productId: combo.id,
        productCode: `C${combo.code}`,
        productName: combo.name,
        productPrice: combo.unitPrice,
        categoryName: 'Combo',
      }
    }
  })
}
