export const validateReference = (
  value: string | undefined,
): string | undefined => {
  if (!value) {
    return 'Necessário preencher um ponto de referência'
  }

  if (value.length < 5) {
    return 'Muito curto'
  }

  return undefined
}
