import { formatDecimal } from './format-decimal'

/**
 * Formats a number into a currency representation.
 *
 * @param value The currency to be formatted.
 *
 * @returns The formatted currency.
 */
export const formatCurrency = (
  value: number,
  {
    prefix = 'R$ ',
  }: {
    prefix: string
  } = {
    prefix: 'R$ ',
  },
): string => {
  const formattedDecimal = formatDecimal(value)
  if (value >= 0) {
    return `${prefix}${formattedDecimal}`
  } else {
    return `-${prefix}${formattedDecimal.slice(1)}`
  }
}
