import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'
import ReactElementResize from 'react-element-resize'
import { Transition } from 'react-transition-group'

const BASE_DURATION = 500

const useStyles = makeStyles({
  container: {
    transform: 'translate(0, -40px)',
    transition: `all ${BASE_DURATION}ms ease-out,
    height ${BASE_DURATION}ms ease-out ${BASE_DURATION / 2}ms`,
    visibility: 'hidden',
    opacity: 0,
    height: 0,
  },
  containerEnter: {
    transform: 'translate(0, 0)',
    transition: `all ${BASE_DURATION}ms ease-in-out ${BASE_DURATION / 2}ms,
        height ${BASE_DURATION}ms ease-in-out`,
    visibility: 'visible',
    opacity: 1,
    // height: ${props => props.height}px
  },
  containerExit: {
    transform: 'translate(-40px, 0)',
  },
})

export type ListItemTransitionProps = {
  children?: React.ReactNode
  in: boolean
}

export const ListItemTransition = ({
  children,
  in: transitionIn,
}: ListItemTransitionProps): React.ReactElement => {
  const classes = useStyles()

  const [height, setHeight] = useState(0)

  const handleResize = ({
    width,
    height,
  }: {
    width: number
    height: number
  }) => {
    setHeight(height)
  }

  return (
    <Transition in={transitionIn} timeout={BASE_DURATION}>
      {(state: string) => (
        <div
          className={classNames({
            [classes.container]: true,
            [classes.containerEnter]:
              state === 'entering' || state === 'entered',
            [classes.containerExit]: state === 'exiting',
          })}
          style={{
            height: state === 'entering' || state === 'entered' ? height : 0,
          }}
        >
          <ReactElementResize debounceTimeout={50} onResize={handleResize}>
            {() => children}
          </ReactElementResize>
        </div>
      )}
    </Transition>
  )
}
