import { VehiclePlateField } from '@keffs/core/src/components/vehicle-plate-field'
import { TextField, Typography } from '@material-ui/core'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import {
  getVehicleColor,
  getVehicleColorError,
  getVehicleColorTouched,
  getVehicleModel,
  getVehicleModelError,
  getVehicleModelTouched,
  getVehiclePlate,
  getVehiclePlateError,
  getVehiclePlateTouched,
} from '../redux/selectors'

export const CartVehicle = memo(() => {
  const dispatch = useDispatch()
  const vehicleModel = useSelector(getVehicleModel)
  const vehicleModelError = useSelector(getVehicleModelError)
  const vehicleModelTouched = useSelector(getVehicleModelTouched)
  const vehicleColor = useSelector(getVehicleColor)
  const vehicleColorError = useSelector(getVehicleColorError)
  const vehicleColorTouched = useSelector(getVehicleColorTouched)
  const vehiclePlate = useSelector(getVehiclePlate)
  const vehiclePlateError = useSelector(getVehiclePlateError)
  const vehiclePlateTouched = useSelector(getVehiclePlateTouched)

  const handleVehicleModelBlur = () => {
    if (!vehicleModelTouched) {
      dispatch(actions.touchVehicleModel())
    }
  }

  const handleVehicleColorBlur = () => {
    if (!vehicleColorTouched) {
      dispatch(actions.touchVehicleColor())
    }
  }

  const handleVehiclePlateBlur = () => {
    if (!vehiclePlateTouched) {
      dispatch(actions.touchVehiclePlate())
    }
  }

  const handleVehicleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setVehicleModel(e.target.value))
  }

  const handleVehicleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setVehicleColor(e.target.value))
  }

  const handleVehiclePlateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setVehiclePlate(e.target.value))
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        Veículo
      </Typography>

      <TextField
        autoComplete="nope"
        error={Boolean(vehicleModelTouched && vehicleModelError)}
        fullWidth
        helperText={vehicleModelTouched && vehicleModelError}
        id="cart-vehicle-model"
        margin="normal"
        onBlur={handleVehicleModelBlur}
        onChange={handleVehicleModelChange}
        placeholder="Você vai buscar com qual modelo de veículo?"
        value={vehicleModel}
      />
      <TextField
        autoComplete="nope"
        error={Boolean(vehicleColorTouched && vehicleColorError)}
        fullWidth
        helperText={vehicleColorTouched && vehicleColorError}
        id="cart-vehicle-color"
        margin="normal"
        onBlur={handleVehicleColorBlur}
        onChange={handleVehicleColorChange}
        placeholder="Qual cor do veículo?"
        value={vehicleColor}
      />
      <VehiclePlateField
        autoComplete="nope"
        error={Boolean(vehiclePlateTouched && vehiclePlateError)}
        fullWidth
        helperText={vehiclePlateTouched && vehiclePlateError}
        id="cart-vehicle-plate"
        margin="normal"
        onBlur={handleVehiclePlateBlur}
        onChange={handleVehiclePlateChange}
        placeholder="XXX-XXXX"
        value={vehiclePlate}
      />
    </>
  )
})
