import { Action as HistoryAction, Location } from '@keffs/history'
import { eventChannel } from 'redux-saga'
import { call, fork, put, take } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actions'
import { history } from '../history'
import { Route, RoutesMap } from '../types'
import { targetToUrl, urlToRoute } from '../utils'

const setupLocationEventChannel = () => {
  return eventChannel(emit => {
    return history.listen(update => {
      emit(update)
    })
  })
}

function* watchRouterActions(routesMap: RoutesMap) {
  while (true) {
    const action: ActionType<
      typeof actions.push | typeof actions.replace | typeof actions.goBack
    > = yield take([
      getType(actions.push),
      getType(actions.replace),
      getType(actions.goBack),
    ])

    const url =
      typeof action.payload === 'string'
        ? action.payload
        : targetToUrl(action.payload, routesMap)
    const state =
      typeof action.payload === 'string' ? undefined : action.payload.state

    // Perform push
    if (action.type === getType(actions.push)) {
      history.push(url, state)
    }

    // Perform replace
    if (action.type === getType(actions.replace)) {
      history.replace(url, state)
    }

    // Perform goBack
    if (action.type === getType(actions.goBack)) {
      history.goBack(url)
    }
  }
}

function* watchLocationChanges(routesMap: RoutesMap) {
  const locationEventChannel = yield call(setupLocationEventChannel)
  while (true) {
    const {
      location,
      action,
    }: { location: Location; action: HistoryAction } = yield take(
      locationEventChannel,
    )

    const url = location.pathname + location.search

    const newRoute: Route = {
      ...urlToRoute(url, routesMap),
      action,
      state: location.state as any,
    }

    yield put(actions.routeChanged(newRoute))
  }
}

export function* routerSaga(routesMap: RoutesMap) {
  // Initialize router
  yield put(actions.initRouter(routesMap))

  yield fork(watchRouterActions, routesMap)
  yield fork(watchLocationChanges, routesMap)
}
