import { addHours, startOfDay, subDays } from 'date-fns'

/**
 * Calculates the last time the hours in the clock pointed to `h`.
 *
 * @param now The current time.
 * @param h  The number that represents the hour.
 *
 * @returns The resulting Date object.
 *
 * @example
 * lastXOClock(new Date('2018-08-19T05:00:00'), 4) // => 2018-08-19T04:00:00
 * lastXOClock(new Date('2018-08-19T03:00:00'), 4) // => 2018-08-18T04:00:00
 */
export const lastXOClock = (now: Date, h: number): Date => {
  const todaysXOClock = addHours(startOfDay(now), h)

  if (now < todaysXOClock) {
    const yesterdaysXOClock = subDays(todaysXOClock, 1)
    return yesterdaysXOClock
  }

  return todaysXOClock
}
