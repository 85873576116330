import { keyBy } from 'lodash'
import { createSelector } from 'reselect'

import { RootState } from '../types'

export const getCombosMapBySlug = createSelector(
  (state: RootState) => state.combos.data,

  byId => {
    return keyBy(byId, 'slug')
  },
)
