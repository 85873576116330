export const isIe = (): boolean => {
  const ua = navigator.userAgent
  // MSIE used to detect old browsers and Trident used for newer ones.
  const isIe = /MSIE /i.test(ua) || /Trident\//i.test(ua)
  return isIe
}

export const isMobileDevice = (): boolean => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export const isSafari = (): boolean => {
  const ua = navigator.userAgent
  const iOS = Boolean(/iPhone|iPad|iPod/i.exec(ua))
  const hasSafariInUa = Boolean(/Safari/i.exec(ua))
  const noOtherBrowsersInUa = !/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i.exec(ua) // prettier-ignore

  // Detect Safari in iOS mobile browsers
  if (iOS) {
    const webkit = Boolean(/WebKit/i.exec(ua))
    return webkit && hasSafariInUa && noOtherBrowsersInUa
  }

  // Detect Safari in desktop browsers
  if ('safari' in window) {
    return true
  }

  // Detect Safari in other platforms
  return hasSafariInUa && noOtherBrowsersInUa
}
