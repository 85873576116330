/**
 * Computes the maximum `changeFor` value a customer can request given
 * the total price of the order.
 *
 * @param totalPrice The total price of the order.
 *
 * @returns The maximum value allowed as `changeFor`.
 */
export const computeMaxChangeFor = (totalPrice: number): number => {
  const nextHundred = Math.ceil(totalPrice / 100) * 100

  return nextHundred + 10
}
