import { formatCurrency } from '@keffs/formatters'
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { Edit as MdEdit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../redux/actions'
import { ROUTE_CART_ITEM } from '../redux/routes'
import { getCombosMap, getProductsMap } from '../redux/selectors'
import { CartOrderItem } from '../redux/types'

const useStyles = makeStyles({
  clickable: {
    cursor: 'pointer',
  },
  image: {
    flexShrink: 0,
    flexGrow: 0,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 64,
    height: 64,
    filter: 'brightness(105%)',
  },
  combo: {
    marginTop: 8,
    marginRight: 8,
  },
})

export type CartItemProps = {
  isLastInList: boolean
  item: CartOrderItem
  readOnly?: boolean
}

export const CartItem = memo(
  ({ isLastInList, item, readOnly = false }: CartItemProps) => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const productsMap = useSelector(getProductsMap)
    const combosMap = useSelector(getCombosMap)

    const image =
      'productId' in item
        ? productsMap[item.productId].image
        : combosMap[item.comboId].image

    const renderItemPrimary = (item: CartOrderItem) => {
      // Product item
      if ('productId' in item) {
        return <Typography>{productsMap[item.productId].name}</Typography>
      }

      // Combo item
      return (
        <div className={classes.combo}>
          {item.choosenItems.map((choosenItem, index) => (
            <Typography key={index}>
              {choosenItem.quantity.toLocaleString('pt-BR')} ×{' '}
              {productsMap[choosenItem.productId].name}
            </Typography>
          ))}
        </div>
      )
    }

    const renderItemSecondary = (item: CartOrderItem) => {
      return (
        <>
          <Typography color="textSecondary" variant="body1">
            {item.notes}
          </Typography>
          <Typography color="textSecondary">
            {!('choosenItems' in item) &&
              `${item.quantity.toLocaleString('pt-BR')} × `}
            {formatCurrency(item.unitPrice)}
          </Typography>
        </>
      )
    }

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      if (!readOnly) {
        dispatch(
          actions.push({ name: ROUTE_CART_ITEM, payload: { id: item.id } }),
        )
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href={readOnly ? undefined : `/carrinho/item/${item.id}`}
        onClick={handleClick}
      >
        <Paper data-testid="cart-item" elevation={readOnly ? 2 : 8}>
          <ListItem divider={!isLastInList}>
            <div
              className={classes.image}
              style={{
                backgroundImage: `url(${image})`,
              }}
            />

            <ListItemText
              disableTypography
              primary={renderItemPrimary(item)}
              secondary={renderItemSecondary(item)}
            />

            {!readOnly && (
              <ListItemSecondaryAction>
                <IconButton color="primary">
                  <MdEdit />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </Paper>
      </a>
    )
  },
)
