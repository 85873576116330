import { Product } from '@keffs/data/src/types'
import keyBy from 'lodash/keyBy'
import { getType } from 'typesafe-actions'

import { productsLoaded } from '../actions'
import { RootAction } from '../types'

export type State = {
  readonly loading: boolean
  readonly data: { [key: string]: Product }
}

export const initialState: State = {
  loading: true,
  data: {},
}

export const productsReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(productsLoaded): {
      return {
        ...state,
        loading: false,
        data: keyBy(action.payload, 'id'),
      }
    }

    default: {
      return state
    }
  }
}
