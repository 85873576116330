import { createSelector } from 'reselect'

import { RootState } from '../../types'
import { getItems } from './get-items'

export const getCartEmpty = createSelector(
  (state: RootState) => getItems(state),

  items => {
    return items.length === 0
  },
)
