import { safeDecimal } from '@keffs/data/src/utils'
import { createSelector } from 'reselect'

import { ProductWithDiscount, RootState } from '../types'
import { getAppliedDiscount } from './cart/get-applied-discount'

export const getProductsMap = createSelector(
  (state: RootState) => state.products.data,
  (state: RootState) => (state.cart ? getAppliedDiscount(state as any) : null),

  (productsMap, appliedDiscount) => {
    const products = Object.keys(productsMap).reduce((acc, id) => {
      const product = productsMap[id]

      if (
        appliedDiscount &&
        appliedDiscount.value &&
        appliedDiscount.type === 'percentage'
      ) {
        // Has discount
        const isProductEntitled =
          appliedDiscount.entitledProductIds.length > 0
            ? appliedDiscount.entitledProductIds.includes(product.id)
            : true

        const productWithDiscount: ProductWithDiscount = {
          ...product,
          discountPercentage: isProductEntitled ? appliedDiscount.value : 0,
          unitPriceAfterDiscount: isProductEntitled
            ? safeDecimal(product.unitPrice * (1 - appliedDiscount.value / 100))
            : product.unitPrice,
        }

        return { ...acc, [id]: productWithDiscount }
      } else {
        // No discount
        const productWithDiscount: ProductWithDiscount = {
          ...product,
          discountPercentage: 0,
          unitPriceAfterDiscount: product.unitPrice,
        }

        return { ...acc, [id]: productWithDiscount }
      }
    }, {} as { [key: string]: ProductWithDiscount })

    return products
  },
)
