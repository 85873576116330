import { Discount, ServiceType } from '@keffs/data/src/types'
import { createStandardAction as action } from 'typesafe-actions'

import { CartOrderItemWithoutPricing } from '../types'

// ----------
// Validation
// ----------
export const touchAll = action('cart/TOUCH_ALL')<void>()

// ------------
// Service type
// ------------
export const setServiceType = action('cart/SET_SERVICE_TYPE')<ServiceType>()
export const clearServiceType = action('cart/CLEAR_SERVICE_TYPE')<void>()
export const touchServiceType = action('cart/TOUCH_SERVICE_TYPE')<void>()

// --------
// Customer
// --------
export const setName = action('cart/SET_NAME')<string>()
export const touchName = action('cart/TOUCH_NAME')<void>()

export const setPhoneNumber = action('cart/SET_PHONE_NUMBER')<string>()
export const touchPhoneNumber = action('cart/TOUCH_PHONE_NUMBER')<void>()

export const setCpf = action('cart/SET_CPF')<string>()
export const touchCpf = action('cart/TOUCH_CPF')<void>()

// -----
// Items
// -----
export const addItem = action('cart/ADD_ITEM')<CartOrderItemWithoutPricing>()
export const updateItem = action('cart/UPDATE_ITEM')<
  CartOrderItemWithoutPricing
>()
export const removeItem = action('cart/REMOVE_ITEM')<string>()

// --------
// Discount
// --------
export const applyDiscount = action('cart/APPLY_DISCOUNT')<Discount>()
export const clearDiscount = action('cart/CLEAR_DISCOUNT')<void>()

// -------------
// Delivery time
// -------------
export const setDeliveryTime = action('cart/SET_DELIVERY_TIME')<Date | 'asap'>()
export const clearDeliveryTime = action('cart/CLEAR_DELIVERY_TIME')<void>()
export const touchDeliveryTime = action('cart/TOUCH_DELIVERY_TIME')<void>()

// --------
// Location
// --------
export const setSelectedLocation = action('cart/SET_SELECTED_LOCATION')<
  string
>()
export const touchSelectedLocation = action('cart/TOUCH_SELECTED_LOCATION')<
  void
>()

export const setAddress = action('cart/SET_ADDRESS')<string>()
export const touchAddress = action('cart/TOUCH_ADDRESS')<void>()

export const setDistrict = action('cart/SET_DISTRICT')<string>()
export const touchDistrict = action('cart/TOUCH_DISTRICT')<void>()

export const setReference = action('cart/SET_REFERENCE')<string>()
export const touchReference = action('cart/TOUCH_REFERENCE')<void>()

// -------
// Vehicle
// -------
export const setVehicleModel = action('cart/SET_VEHICLE_MODEL')<string>()
export const touchVehicleModel = action('cart/TOUCH_VEHICLE_MODEL')<void>()

export const setVehicleColor = action('cart/SET_VEHICLE_COLOR')<string>()
export const touchVehicleColor = action('cart/TOUCH_VEHICLE_COLOR')<void>()

export const setVehiclePlate = action('cart/SET_VEHICLE_PLATE')<string>()
export const touchVehiclePlate = action('cart/TOUCH_VEHICLE_PLATE')<void>()

// --------------
// Payment method
// --------------
export const setPaymentMethod = action('cart/SET_PAYMENT_METHOD')<
  | {
      method: 'card'
    }
  | {
      method: 'cash'
      changeFor: number | null
    }
>()
export const clearPaymentMethod = action('cart/CLEAR_PAYMENT_METHOD')<void>()
export const touchPaymentMethod = action('cart/TOUCH_PAYMENT_METHOD')<void>()

// ------
// Submit
// ------
export const submitOrderAttempt = action('cart/SUBMIT_ORDER_ATTEMPT')<void>()
export const submitOrderSuccess = action('cart/SUBMIT_ORDER_SUCCESS')<string>()
export const submitOrderError = action('cart/SUBMIT_ORDER_ERROR')<string>()
export const setSubmitOnReload = action('cart/SET_SUBMIT_ON_RELOAD')<
  Date | false
>()
