import { Customer, Discount, ServiceType } from '@keffs/data/src/types'
import { formatPhoneNumber } from '@keffs/formatters'
import { getType } from 'typesafe-actions'

import {
  addItem,
  applyDiscount,
  authInitialized,
  clearDeliveryTime,
  clearDiscount,
  clearPaymentMethod,
  clearServiceType,
  removeItem,
  setAddress,
  setCpf,
  setDeliveryTime,
  setDistrict,
  setName,
  setPaymentMethod,
  setPhoneNumber,
  setReference,
  setSelectedLocation,
  setServiceType,
  setSubmitOnReload,
  setVehicleColor,
  setVehicleModel,
  setVehiclePlate,
  signInSuccess,
  signOutSuccess,
  submitOrderAttempt,
  submitOrderError,
  submitOrderSuccess,
  touchAddress,
  touchAll,
  touchCpf,
  touchDeliveryTime,
  touchDistrict,
  touchName,
  touchPaymentMethod,
  touchPhoneNumber,
  touchReference,
  touchSelectedLocation,
  touchServiceType,
  touchVehicleColor,
  touchVehicleModel,
  touchVehiclePlate,
  updateItem,
} from '../actions'
import { CartOrderItemWithoutPricing, RootAction } from '../types'

export type State = {
  readonly submitOnReload: Date | false
  readonly submitting: boolean
  readonly error: string | null

  readonly serviceType: ServiceType | ''
  readonly serviceTypeTouched: boolean

  readonly items: CartOrderItemWithoutPricing[]

  readonly isDiscountStale: boolean
  readonly discount: Discount | null

  readonly customer: Customer | null
  readonly phoneNumber: string
  readonly phoneNumberTouched: boolean
  readonly name: string
  readonly nameTouched: boolean
  readonly cpf: string
  readonly cpfTouched: boolean

  readonly deliveryTime: Date | 'asap' | null
  readonly deliveryTimeTouched: boolean

  readonly selectedLocation: string
  readonly selectedLocationTouched: boolean
  readonly address: string
  readonly addressTouched: boolean
  readonly district: string
  readonly districtTouched: boolean
  readonly city: string
  readonly reference: string
  readonly referenceTouched: boolean
  readonly coordinates: [number, number] | null

  readonly vehicleModel: string
  readonly vehicleModelTouched: boolean
  readonly vehicleColor: string
  readonly vehicleColorTouched: boolean
  readonly vehiclePlate: string
  readonly vehiclePlateTouched: boolean

  readonly paymentMethod: 'cash' | 'card' | null
  readonly paymentMethodTouched: boolean
  readonly changeFor: number | null
}

const initialState: State = {
  submitOnReload: false,
  submitting: false,
  error: null,

  serviceType: '',
  serviceTypeTouched: false,

  items: [],

  isDiscountStale: true,
  discount: null,

  customer: null,
  phoneNumber: '',
  phoneNumberTouched: false,
  name: '',
  nameTouched: false,
  cpf: '',
  cpfTouched: false,

  deliveryTime: null,
  deliveryTimeTouched: false,

  selectedLocation: 'new',
  selectedLocationTouched: false,
  address: '',
  addressTouched: false,
  district: '',
  districtTouched: false,
  city: 'Caldas Novas - GO',
  reference: '',
  referenceTouched: false,
  coordinates: null,

  vehicleModel: '',
  vehicleModelTouched: false,
  vehicleColor: '',
  vehicleColorTouched: false,
  vehiclePlate: '',
  vehiclePlateTouched: false,

  paymentMethod: null,
  paymentMethodTouched: false,
  changeFor: null,
}

export const cartReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    // ----
    // Auth
    // ----
    case getType(authInitialized):
    case getType(signOutSuccess):
    case getType(signInSuccess): {
      // Either signed-out or initialized unauthenticated?
      if (action.type === getType(signOutSuccess) || action.payload == null) {
        return {
          ...state,
          customer: null,
          phoneNumber: '',
          name: '',
          cpf: '',
          selectedLocation: 'new',
          address: '',
          district: '',
          reference: '',
        }
      }

      // Otherwise signed-in or initialized authenticated
      const customer = action.payload
      let selectedLocation: string
      if (
        state.selectedLocation &&
        state.selectedLocation !== 'new' &&
        state.selectedLocation !== ''
      ) {
        // As there was already a location selected, keep it
        selectedLocation = state.selectedLocation
      } else if (state.address || state.district || state.reference) {
        // As the user had already filled the location fields, keep
        // showing them
        selectedLocation = 'new'
      } else if (customer.locations.length > 0) {
        // As the user didn't start to fill location fields and has
        // previous locations linked to his profile, show the dropdown
        selectedLocation = ''
      } else {
        // As the user can't choose any previous location, he must
        // fill out a new location
        selectedLocation = 'new'
      }

      return {
        ...state,
        customer,
        phoneNumber: customer.phoneNumber
          ? formatPhoneNumber(customer.phoneNumber.slice(3))
          : state.phoneNumber,
        name: customer.displayName ? customer.displayName : state.name,
        cpf: customer.cpf ? customer.cpf : state.cpf,
        selectedLocation,
      }
    }

    // ----------
    // Validation
    // ----------
    case getType(touchAll): {
      return {
        ...state,
        serviceTypeTouched: true,
        phoneNumberTouched: true,
        nameTouched: true,
        cpfTouched: true,
        vehicleModelTouched: true,
        vehicleColorTouched: true,
        vehiclePlateTouched: true,
        deliveryTimeTouched: true,
        selectedLocationTouched: true,
        addressTouched: true,
        districtTouched: true,
        referenceTouched: true,
        paymentMethodTouched: true,
      }
    }

    // ------------
    // Service type
    // ------------
    case getType(setServiceType): {
      if (action.payload === 'eatIn') {
        // Eat-in only allows ASAP, so we enforce it here.
        return {
          ...state,
          serviceType: action.payload,
          deliveryTime: 'asap',
        }
      } else {
        // For other services types, reset deliveryTime because the
        // current value may be invalid in the context of this new
        // serviceType.
        return {
          ...state,
          serviceType: action.payload,
          deliveryTime: null,
        }
      }
    }
    case getType(clearServiceType): {
      return { ...state, serviceType: '' }
    }
    case getType(touchServiceType): {
      return { ...state, serviceTypeTouched: true }
    }

    case getType(setName): {
      return { ...state, name: action.payload }
    }
    case getType(touchName): {
      return { ...state, nameTouched: true }
    }

    case getType(setPhoneNumber): {
      return { ...state, phoneNumber: action.payload }
    }
    case getType(touchPhoneNumber): {
      return { ...state, phoneNumberTouched: true }
    }

    case getType(setCpf): {
      return { ...state, cpf: action.payload }
    }
    case getType(touchCpf): {
      return { ...state, cpfTouched: true }
    }

    // -----
    // Items
    // -----
    case getType(addItem): {
      return {
        ...state,
        items: [...state.items, action.payload],
      }
    }
    case getType(updateItem): {
      const index = state.items.findIndex(i => i.id === action.payload.id)
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          action.payload,
          ...state.items.slice(index + 1),
        ],
      }
    }
    case getType(removeItem): {
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.payload),
      }
    }

    // --------
    // Discount
    // --------
    case getType(applyDiscount): {
      return { ...state, isDiscountStale: false, discount: action.payload }
    }
    case getType(clearDiscount): {
      return { ...state, discount: null }
    }

    // --------------
    // Payment method
    // --------------
    case getType(setPaymentMethod): {
      return {
        ...state,
        paymentMethod: action.payload.method,
        changeFor:
          'changeFor' in action.payload ? action.payload.changeFor : null,
      }
    }
    case getType(clearPaymentMethod): {
      return { ...state, paymentMethod: null, changeFor: null }
    }
    case getType(touchPaymentMethod): {
      return { ...state, paymentMethodTouched: true }
    }

    // -------------
    // Vehicle model
    // -------------
    case getType(setVehicleModel): {
      return { ...state, vehicleModel: action.payload }
    }
    case getType(touchVehicleModel): {
      return { ...state, vehicleModelTouched: true }
    }

    // -------------
    // Vehicle color
    // -------------
    case getType(setVehicleColor): {
      return { ...state, vehicleColor: action.payload }
    }
    case getType(touchVehicleColor): {
      return { ...state, vehicleColorTouched: true }
    }

    // -------------
    // Vehicle plate
    // -------------
    case getType(setVehiclePlate): {
      return { ...state, vehiclePlate: action.payload }
    }
    case getType(touchVehiclePlate): {
      return { ...state, vehiclePlateTouched: true }
    }

    // -------------
    // Delivery time
    // -------------
    case getType(setDeliveryTime): {
      return { ...state, deliveryTime: action.payload }
    }
    case getType(clearDeliveryTime): {
      return { ...state, deliveryTime: null }
    }
    case getType(touchDeliveryTime): {
      return { ...state, deliveryTimeTouched: true }
    }

    // --------
    // Location
    // --------
    case getType(setSelectedLocation): {
      const selectedLocation = action.payload

      if (selectedLocation === '' || selectedLocation === 'new') {
        return {
          ...state,
          selectedLocation,
          address: '',
          district: '',
          city: 'Caldas Novas - GO',
          reference: '',
          coordinates: null,
        }
      }

      const location = state.customer!.locations.find(
        l => l.id === selectedLocation,
      )!

      return {
        ...state,
        selectedLocation,
        address: location.address,
        district: location.district,
        city: location.city,
        reference: location.reference || '',
        coordinates: location.coordinates || null,
      }
    }

    case getType(touchSelectedLocation): {
      return { ...state, selectedLocationTouched: true }
    }

    case getType(setAddress): {
      return { ...state, address: action.payload }
    }
    case getType(touchAddress): {
      return { ...state, addressTouched: true }
    }

    case getType(setDistrict): {
      return { ...state, district: action.payload }
    }
    case getType(touchDistrict): {
      return { ...state, districtTouched: true }
    }

    case getType(setReference): {
      return { ...state, reference: action.payload }
    }
    case getType(touchReference): {
      return { ...state, referenceTouched: true }
    }

    // ------------
    // Submit order
    // ------------
    case getType(submitOrderAttempt): {
      return { ...state, submitting: true }
    }
    case getType(submitOrderSuccess): {
      const customer = state.customer!
      let selectedLocation: string
      if (customer.locations.length > 0) {
        selectedLocation = ''
      } else {
        selectedLocation = 'new'
      }

      return {
        ...initialState,
        customer,
        phoneNumber: customer.phoneNumber
          ? formatPhoneNumber(customer.phoneNumber.slice(3))
          : '',
        name: customer.displayName ? customer.displayName : '',
        cpf: customer.cpf ? customer.cpf : '',
        selectedLocation,
      }
    }
    case getType(submitOrderError): {
      return {
        ...state,
        submitOnReload: false,
        submitting: false,
        error: action.payload,
      }
    }

    case getType(setSubmitOnReload): {
      return { ...state, submitOnReload: action.payload }
    }

    default: {
      return { ...(initialState || {}), ...state }
    }
  }
}
