import { getType } from 'typesafe-actions'

import { closeSnackbar, processSnackbarQueue, showSnackbar } from '../actions'
import { Message, RootAction } from '../types'

export type State = {
  readonly open: boolean
  readonly current: Message | undefined
  readonly queue: Message[]
}

export const initialState: State = {
  open: false,
  current: undefined,
  queue: [],
}

export const snackbarReducer = (
  state: State = initialState,
  action: RootAction,
): State => {
  switch (action.type) {
    case getType(showSnackbar): {
      const message =
        typeof action.payload === 'string'
          ? { primary: action.payload }
          : action.payload

      // Should push to the queue?
      if (state.current != null) {
        return {
          ...state,
          queue: [...state.queue, message],
        }
      }

      return {
        ...state,
        open: true,
        current: message,
      }
    }

    case getType(processSnackbarQueue): {
      if (state.queue.length === 0) {
        return initialState
      }

      return {
        ...state,
        open: true,
        current: state.queue[0],
        queue: state.queue.slice(1),
      }
    }

    case getType(closeSnackbar): {
      return { ...state, open: false }
    }

    default: {
      return state
    }
  }
}
