import { keyBy } from 'lodash'
import { createSelector } from 'reselect'

import { RootState } from '../types'
import { getProductsMap } from './get-products-map'

export const getProductsMapBySlug = createSelector(
  (state: RootState) => getProductsMap(state),

  byId => {
    return keyBy(byId, 'slug')
  },
)
