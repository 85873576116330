import { formatCurrency } from '@keffs/formatters'

import { computeMaxChangeFor } from '../computed'

export const validateChangeFor = (
  value: number | undefined,
  totalPrice: number,
): string | undefined => {
  if (!value) {
    return 'Necessário preencher'
  }

  if (value < totalPrice) {
    return 'Deve ser maior que o valor total do pedido'
  }

  const maxChangeFor = computeMaxChangeFor(totalPrice)
  if (value > maxChangeFor) {
    return `Deve ser menor ou igual a ${formatCurrency(maxChangeFor)}`
  }

  return undefined
}
